/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

// eslint-disable-next-line import/prefer-default-export
export enum AppType {
    INITIALDEVICESTARTUP='INITIALDEVICESTARTUP',
    EDITDEVICE='EDITDEVICE',
    REVIEWDEVICE='REVIEWDEVICE',
    COMPAREDEVICE='COMPAREDEVICE',
    EXPERTCOMPAREDEVICE='EXPERTCOMPAREDEVICE',
    REPORTDEVICE='REPORTDEVICE',
    COCKPIT='COCKPIT',
    POPUPEDITOR='POPUPEDITOR',
    ONLINEPARAM='ONLINEPARAM',
    DIPSWITCH_WIZARD_CONFIGURATION='DIPSWITCH_WIZARD_CONFIGURATION',
    DIPSWITCH_WIZARD_DISPLAY='DIPSWITCH_WIZARD_DISPLAY',
    DIPSWITCH_POPUP_DISPLAY='DIPSWITCH_POPUP_DISPLAY',
    DEVICESPECIFIC='DEVICESPECIFIC',
}
