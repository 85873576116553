/* eslint-disable max-len */
import { IdentRef, DeviceModelStatus } from '@gpt/commons';
import { DatasetState } from '../../store/deviceInstances/store/deviceDataset/types';

export interface MenuItemData {
  identRef: IdentRef;
  label: DeviceModelStatus.LocString;
  help: DeviceModelStatus.LocString;
  width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  readonly?: boolean;
}

const createMenuItemData = (
  descriptor: DeviceModelStatus.DeviceModelDescriptor,
  width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  readonly?: boolean,
): MenuItemData => {
  if (descriptor?.type === DeviceModelStatus.StatusType.StructureDescriptor) {
    return {
      identRef: descriptor.identRef,
      help: descriptor?.help ?? '',
      label: descriptor?.label ?? '',
      width,
      readonly,
    };
  }

  return {
    identRef: descriptor?.identRef ?? 'unknown',
    help: '',
    label: '',
    width,
    readonly,
  };
};

const ctlSelectDescriptor = (descriptor?: DeviceModelStatus.DeviceModelDescriptor): DeviceModelStatus.UI.SelectControlType | undefined => {
  if (descriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  return descriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLSELECT
    ? undefined
    : descriptor.controlType;
};

const menuSelectControl = (
  state: DatasetState,
  menuItemElement: DeviceModelStatus.StructureDescriptorElement,
  controlType: DeviceModelStatus.UI.SelectControlType,
): MenuItemData[] => {
  // eslint-disable-next-line max-len
  const variableValue = state.values[controlType.variable];
  if (variableValue === undefined) {
    return [];
  }

  const menuItemRefs = controlType.CASE
    .find((item) => item.value === variableValue.value)?.ITEMS ?? controlType.DEFAULT;

  const menuItems = menuItemRefs.reduce((acc, item) => {
    const { descriptors } = state;
    const desc = descriptors[item.identRef];
    if (desc === undefined) {
      return acc;
    }

    const selectControl = ctlSelectDescriptor(desc);
    if (selectControl !== undefined) {
      const items = menuSelectControl(state, menuItemElement, selectControl);
      return [
        ...acc,
        ...items,
      ];
    }

    const tabData = createMenuItemData(desc, item.width, item.readonly);
    return [
      ...acc,
      tabData,
    ];
  }, [] as MenuItemData[]);
  return menuItems;
};

export const tabItemsSelector = (elements: IdentRef[], readonly?: boolean, state?: DatasetState): MenuItemData[] => {
  if (state === undefined) {
    return [];
  }

  return elements.reduce((acc, element) => {
    const desc = state.descriptors[element];
    const tabData = createMenuItemData(desc, undefined, readonly);
    return [
      ...acc,
      tabData,
    ];
  }, [] as MenuItemData[]);
};

export const menuItemsSelector = (state?: DatasetState, tabRootMenu?: IdentRef): MenuItemData[] => {
  if (tabRootMenu === undefined || state === undefined) {
    return [];
  }

  const descriptor = state?.descriptors[tabRootMenu];
  if (descriptor?.type !== DeviceModelStatus.StatusType.StructureDescriptor) {
    return [];
  }

  return descriptor.elements.reduce((acc, element) => {
    const desc = state.descriptors[element.identRef];
    const controlType = ctlSelectDescriptor(desc);
    if (controlType !== undefined) {
      const tabItems = menuSelectControl(state, element, controlType);
      return [
        ...acc,
        ...tabItems,
      ];
    }

    const tabData = createMenuItemData(desc, element.width, element.readonly);
    return [
      ...acc,
      tabData,
    ];
  }, [] as MenuItemData[]);
};

export const compareTabData = (prev: MenuItemData[], curr: MenuItemData[]): boolean => {
  if (prev.length !== curr.length) {
    return false;
  }
  const uneq = prev
    .find((pit, idx) => curr[idx].identRef !== pit.identRef
      || curr[idx].label !== pit.label
      || curr[idx].readonly !== pit.readonly
      || curr[idx].width !== pit.width);
  return uneq === undefined;
};
