/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useCallback } from 'react';
import i18n from './i18n-LoadingSplashScreen.json';

const i18nTranslator = (lang: string, key: string): string => i18n[lang]?.[key] || String(key);

const useAppTranslation = () => {
  const language = window.navigator.language.substring(0, 2);
  const lang = (language === 'en' || language === 'de') ? language : 'en';

  const i18nTranslate = useCallback((id: string) => i18nTranslator(lang, id), [lang]);
  return i18nTranslate;
};

export default useAppTranslation;
