/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Discovery } from '@gpt/cxe-nats-communication';
import iconIoLinkAdapter from '../images/Icon_IOLink.svg';
import iconUSIAdapter from '../images/Icon_USI_adapter.svg';
import iconNFCAdapter from '../images/Icon_NFC.svg';
import iconSPortAdapter from '../images/Icon_S_Port.svg';
import iconSPortBluetoothAdapter from '../images/Icon_S_Port_Bluetooth.svg';
import iconUnknownAdapter from '../../../assets/icons/icon-no-device-na.svg';
import DeviceCardAdapter from '../../DeviceCards/DeviceCardAdapter/DeviceCardAdapter';

export interface ListItemAdapterCardProps {
  adapterType: Discovery.CommunicationAdapterType;
  state: Discovery.AdapterState;
}

const mapToIcon = {
  [Discovery.CommunicationAdapterType.Unknown]: iconUnknownAdapter,
  [Discovery.CommunicationAdapterType.Phoenix_NFC_USB_Reader]: iconNFCAdapter,
  [Discovery.CommunicationAdapterType.Phoenix_TMG_USB_IOLink_Master]: iconIoLinkAdapter,
  [Discovery.CommunicationAdapterType.Phoenix_USB_Bluegiga_Prog_Adapter]: iconSPortBluetoothAdapter,
  [Discovery.CommunicationAdapterType.Phoenix_USB_Prog_Adapter]: iconSPortAdapter,
  [Discovery.CommunicationAdapterType.Phoenix_USB_USI_IOLink_Master]: iconUSIAdapter,
};

const mapToBusProtocol = {
  [Discovery.CommunicationAdapterType.Unknown]: 'unknown',
  [Discovery.CommunicationAdapterType.Phoenix_NFC_USB_Reader]: 'NFC',
  [Discovery.CommunicationAdapterType.Phoenix_TMG_USB_IOLink_Master]: 'Io-Link',
  [Discovery.CommunicationAdapterType.Phoenix_USB_Bluegiga_Prog_Adapter]: 'Bluetooth',
  [Discovery.CommunicationAdapterType.Phoenix_USB_Prog_Adapter]: 'S-Port',
  [Discovery.CommunicationAdapterType.Phoenix_USB_USI_IOLink_Master]: 'USI',
};

// eslint-disable-next-line max-len
export const ListItemAdapterCard: React.FC<ListItemAdapterCardProps> = (props: ListItemAdapterCardProps): React.ReactElement => {
  const { adapterType, state } = props;
  const { t } = useTranslation();

  const image = mapToIcon[adapterType] ?? '';
  const scanActive = state === Discovery.AdapterState.Discovery;

  const busProtocol = t(mapToBusProtocol[adapterType] ?? '???');
  const text = scanActive ? `${busProtocol}: ${t('DEVICE_CARD__ACTION_SCANNING')}` : busProtocol;

  return (
    <DeviceCardAdapter
      protocolTitle={t('DEVICE_CARD__NO_DEVICE__TITLE')}
      productImage={image}
      protocolText={text}
      scanActive={scanActive}
    />
  );
};
