/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Resource } from 'i18next';

import i18nEn from './translation_en.json';
import i18nDe from './translation_de.json';
import i18nEs from './translation_es.json';
import i18nFr from './translation_fr.json';
import i18nIt from './translation_it.json';
import i18nJa from './translation_ja.json';
import i18nPl from './translation_pl.json';
import i18nPt from './translation_pt.json';
import i18nRu from './translation_ru.json';
import i18nTr from './translation_tr.json';
import i18nZh from './translation_zh.json';
import i18nCs from './translation_cs.json';
import i18nDa from './translation_da.json';
import i18nFi from './translation_fi.json';
import i18nHu from './translation_hu.json';
import i18nKo from './translation_ko.json';
import i18nNb from './translation_nb.json';
import i18nNl from './translation_nl.json';
import i18nRo from './translation_ro.json';
import i18nSv from './translation_sv.json';
import i18nUk from './translation_uk.json';
import deviceDictionaries from './Devices';

const mergeDictionaries = (deviceResources: Resource[], bundledResources: Resource)
: Resource => deviceResources
  .reduce((xacc, deviceResource) => Object.keys(deviceResource).reduce((acc, lang) => {
    const dict = acc[lang] === undefined
      ? {
        ...acc,
        [lang]: {
          ...deviceResource[lang],
        },
      } : {
        ...acc,
        [lang]: {
          ...acc[lang],
          ...deviceResource[lang],
        },
      };
    return dict;
  }, xacc), bundledResources);

/**
 * Default translation for application
 */
const appResources: Resource = {
  en: {
    translation: i18nEn,
  },
  de: {
    translation: i18nDe,
  },
  es: {
    translation: i18nEs,
  },
  fr: {
    translation: i18nFr,
  },
  it: {
    translation: i18nIt,
  },
  ja: {
    translation: i18nJa,
  },
  pl: {
    translation: i18nPl,
  },
  pt: {
    translation: i18nPt,
  },
  ru: {
    translation: i18nRu,
  },
  tr: {
    translation: i18nTr,
  },
  zh: {
    translation: i18nZh,
  },
  cs: {
    translation: i18nCs,
  },
  da: {
    translation: i18nDa,
  },
  fi: {
    translation: i18nFi,
  },
  hu: {
    translation: i18nHu,
  },
  ko: {
    translation: i18nKo,
  },
  nb: {
    translation: i18nNb,
  },
  nl: {
    translation: i18nNl,
  },
  ro: {
    translation: i18nRo,
  },
  sv: {
    translation: i18nSv,
  },
  uk: {
    translation: i18nUk,
  },
};

const dictionaries = mergeDictionaries(
  deviceDictionaries,
  appResources,
);

export default dictionaries as any;
