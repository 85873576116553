import { IdentRef } from '../../services/common';

export enum ControlValueKind {
    constantInteger,
    constantString,
    variable,
}

export interface ControlValueConstIntegerType {
    type: typeof ControlValueKind.constantInteger
    value: number;
}

export interface ControlValueConstStringType {
    type: typeof ControlValueKind.constantString
    value: string;
}

export interface ControlValueVariableType {
    type: typeof ControlValueKind.variable
    variable: IdentRef;
}

export type ControlValueType = ControlValueConstIntegerType
 | ControlValueConstStringType
 | ControlValueVariableType;

export type typeColor = 'black' | 'gray' | 'green' | 'red' | 'yellow' | 'white' | 'orange';
