/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

import { DeviceInformation } from '../DeviceInformation';

export const CONNECTION_STATUS_EVENT = 'devicemodel.connection';

export enum ConnectionStatus {
    disconnected = 0,
    connected = 1,
    offline = 2,
}

export interface ConnectionStatusPayload {
    device: DeviceInformation;
    status: ConnectionStatus;
}
