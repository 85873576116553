/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import { DynVariableControlType } from './ctlDynVariableControl';
import { PlaceholderControlType } from './ctlPlaceholderControl';
import { TrendViewControlType } from './ctlTrendViewControl';
import { DynLedFloatControlType } from './ctlDynLedFloatControl';
import { PictureControlType } from './ctlPictureControl';
import { RangeControlType } from './ctlRangeControl';
import { RangeWithSpanControlType } from './ctlRangeWithSpanControl';
import { SliderControlType } from './ctlSliderControl';
import { CheckboxControlType } from './ctlCheckboxControl';
import { DatePickerControlType } from './ctlDatePickerControl';
import { DynLedDiscreteControlType } from './ctlDynLedDiscreteControl';
import { SelectControlType } from './ctlSelectControl';
import { DeviceStatusControlType } from './ctlDeviceStatusControl';
import { CurrentStatusControlType } from './ctlCurrentStatusControl';
import { DeviceStatusListControlType } from './ctlDeviceStatusListControl';
import { CurrentStatusListControlType } from './ctlCurrentStatusListControl';
import { PaperControlType } from './ctlPaperControl';
import { LinearizationControlType } from './ctlLinearization';
import { DeviceValuePickerControlType } from './ctlDeviceValuePicker';
import { DIPSwitchBlockOnlineType } from './ctlDIPSwitchBlockOnline';
import { DIPSwitchBlockOfflineType } from './ctlDIPSwitchBlockOffline';
import { StatusMessageControlType } from './ctlStatusMessage';
import { DIPSwitchBlockLiveType } from './ctlDIPSwitchBlockLive';
import { GridLayoutControlType } from './ctlGridLayoutControl';
import { ChannelStatusControlType } from './ctlChannelStatus';
import { ChannelSwitchBlockControlType } from './ctlChannelSwitchBlock';
import { LedLinkControlType } from './ctlLedLink';
import { IoLinkEventMonitorControlType } from './iolink/ctlEventListMonitor';
import { IoLinkEventMonitorStatusControlType } from './iolink/ctlEventListMonitorStatus';
import { Trio3EventMonitorControlType } from './iolink/ctlTrio3EventMonitor';
import { Trio3EventMonitorStatusControlType } from './iolink/ctlTrio3EventMonitorStatus';
import { Trio3ChannelStatusControlType } from './iolink/ctlTrio3ChannelStatus';
import { IoLinkSystemCommandControlType } from './iolink/ctlSystemCommand';
import { IoLinkCommandControlType } from './iolink/ctlIoLinkCommand';
import { IoLinkCaparocChannelCurrentEditorControlType } from './caparoc/ctlDeviceChannelEditor';
import { PasswordControlType } from './ctlPassword';
import { CockpitCaparocControlType } from './caparoc/ctlCockpitCaparocControl';
import { OnlineModuleCaparocControlType } from './caparoc/ctlOnlineModuleCaparocControl';
import { AnalogGaugeControlType } from './ctlAnalogGaugeControl';
import { Quint4AnalogSignalControlType } from './quint4/ctlQuint4AnalogSignal';
import { DeviceStatusMonitorType } from './ctlDeviceStatusMonitor';
import { DeviceStatusVariableIconControlType } from './ctlDeviceStatusVariableIcon';
import { DeviceStatusVariableValueControlType } from './ctlDeviceStatusVariableValue';
import { OnlineCaparocControlType } from './caparoc/ctlOnlineCaparocControl';
import { DeviceSpecificControlType } from './ctlDeviceSpecific';
import { ThumbWheelControlType } from './ctlThumbWheel';
import { CustomControlControlType } from './ctlCustomControl';

import * as Contactron from './contactron';
import * as SPort from './sport';

export * from './common';
export * from './controlTypes';
export * from './ctlAnalogGaugeControl';
export * from './ctlChannelStatus';
export * from './ctlChannelSwitchBlock';
export * from './ctlCheckboxControl';
export * from './ctlCurrentStatusControl';
export * from './ctlCurrentStatusListControl';
export * from './ctlDIPSwitchBlockLive';
export * from './ctlDIPSwitchBlockOffline';
export * from './ctlDIPSwitchBlockOnline';
export * from './ctlDatePickerControl';
export * from './ctlDeviceSpecific';
export * from './ctlThumbWheel';
export * from './ctlDeviceStatusControl';
export * from './ctlDeviceStatusListControl';
export * from './ctlDeviceStatusMonitor';
export * from './ctlDeviceStatusVariableIcon';
export * from './ctlDeviceStatusVariableValue';
export * from './ctlDeviceValuePicker';
export * from './ctlDynLedDiscreteControl';
export * from './ctlDynLedFloatControl';
export * from './ctlDynVariableControl';
export * from './ctlGridLayoutControl';
export * from './ctlLedLink';
export * from './ctlLinearization';
export * from './ctlPaperControl';
export * from './ctlPassword';
export * from './ctlPictureControl';
export * from './ctlPlaceholderControl';
export * from './ctlRangeControl';
export * from './ctlRangeWithSpanControl';
export * from './ctlSelectControl';
export * from './ctlSliderControl';
export * from './ctlStatusMessage';
export * from './ctlTrendViewControl';
export * from './iolink';
export * from './quint4';
export * from './caparoc';
export * from './contactron';
export * from './sport';
export * from './ctlCustomControl';

export type ControlDescriptorType = DynVariableControlType
 | PlaceholderControlType
 | TrendViewControlType
 | DynLedFloatControlType
 | PictureControlType
 | RangeControlType
 | RangeWithSpanControlType
 | SliderControlType
 | CheckboxControlType
 | DatePickerControlType
 | DynLedDiscreteControlType
 | SelectControlType
 | DeviceStatusControlType
 | CurrentStatusControlType
 | DeviceStatusListControlType
 | CurrentStatusListControlType
 | PaperControlType
 | LinearizationControlType
 | DeviceValuePickerControlType
 | DIPSwitchBlockOnlineType
 | DIPSwitchBlockOfflineType
 | StatusMessageControlType
 | DIPSwitchBlockLiveType
 | GridLayoutControlType
 | ChannelStatusControlType
 | ChannelSwitchBlockControlType
 | LedLinkControlType
 | IoLinkEventMonitorControlType
 | IoLinkEventMonitorStatusControlType
 | IoLinkSystemCommandControlType
 | IoLinkCommandControlType
 | Trio3EventMonitorControlType
 | Trio3EventMonitorStatusControlType
 | Trio3ChannelStatusControlType
 | PasswordControlType
 | CockpitCaparocControlType
 | IoLinkCaparocChannelCurrentEditorControlType
 | OnlineModuleCaparocControlType
 | AnalogGaugeControlType
 | Quint4AnalogSignalControlType
 | Contactron.ContactronControlType
 | SPort.SPortControlType
 | DeviceStatusMonitorType
 | DeviceStatusVariableIconControlType
 | DeviceStatusVariableValueControlType
 | OnlineCaparocControlType
 | DeviceSpecificControlType
 | SPort.SPortSpecificControlType
 | ThumbWheelControlType
 | CustomControlControlType;

export {
  Contactron,
  SPort,
};
