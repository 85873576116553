/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import InputBoxControl from '../../../Basic/InputBoxControl/InputBoxControl';
import './TableElement.css';

export interface TableElementProps {
    index: number;
    id?: string;
    item: TableElementItem;
    // eslint-disable-next-line no-unused-vars
    onXValueChange: (pointIndex: number, pointValue: string) => void;
    // eslint-disable-next-line no-unused-vars
    onYValueChange: (pointIndex: number, pointValue: string) => void;
}

export interface TableElementItem {
  xValue: number;
  yValue: number;
  isXValid: boolean;
  isXRangeValid: boolean;
  isYValid: boolean;
  isYRangeValid: boolean;
}

export const TableElement:React.FC<TableElementProps> = (props: TableElementProps): React.ReactElement => {
  const {
    index, id, item, onXValueChange, onYValueChange,
  } = props;

  return (
    <div className="d-flex flex-row align-items-center" data-testid="table-element">
      <div className="tableElement-index">{`${index + 1}.`}</div>
      <div className="tableElement-splitter1" />
      <div className="tableElement-label">
        <InputBoxControl
          type="number"
          displayFormat="%.2f"
          value={item.xValue}
          step={0.01}
          onChange={(value) => onXValueChange(index, value)}
          id={id}
          warning={item.isXValid && item.isXRangeValid ? undefined : ''}
        />
      </div>
      <div className="tableElement-splitter2" />
      <div className="tableElement-label">
        <InputBoxControl
          type="number"
          displayFormat="%.2f"
          value={item.yValue}
          step={0.01}
          onChange={(value) => onYValueChange(index, value)}
          warning={item.isYValid && item.isYRangeValid ? undefined : ''}
        />
      </div>
    </div>
  );
};
