/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTableRowHeader } from '../../../../components/DataTableControl';
import { DataTableRowHeaderItem } from '../../../../components/DataTableControl/DataTableRowHeaderItem/DataTableRowHeaderItem';
import { useContextDatasetDescriptorLabel } from '../../../../hooks/useContextDataset';
import {
  HDC_ERROR_MEMORY__HDR_ERROR_ENTRY__WIDTH, HDC_ERROR_MEMORY__HDR_WORKING_HOURS__WIDTH,
  HDC_ERROR_MEMORY__HDR_CURRENT__WIDTH, HDC_ERROR_MEMORY__HDR_VOLTAGE__WIDTH,
  HDC_ERROR_MEMORY__HDR_TEMPERATURE__WIDTH,
  HDC_ERROR_MEMORY__VAR__ENTRY,
  HDC_ERROR_MEMORY__VAR__WORK_HOURS,
  HDC_ERROR_MEMORY__VAR__CURRENT,
  HDC_ERROR_MEMORY__VAR__VOLTAGE,
  HDC_ERROR_MEMORY__VAR__TEMPERATURE,
} from '../../HDCConstants';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';

export interface HDCErrorMemoryTableHeaderProps {
  deviceInstanceId: string;
  targetDataset?: DatasetType;
}

const PARA_ERROR_MEMORY_ENTRY01_ENTRY = HDC_ERROR_MEMORY__VAR__ENTRY(1);
const PARA_ERROR_MEMORY_ENTRY01_WORK_HOURS = HDC_ERROR_MEMORY__VAR__WORK_HOURS(1);
const PARA_ERROR_MEMORY_ENTRY01_CURRENT = HDC_ERROR_MEMORY__VAR__CURRENT(1);
const PARA_ERROR_MEMORY_ENTRY01_VOLTAGE = HDC_ERROR_MEMORY__VAR__VOLTAGE(1);
const PARA_ERROR_MEMORY_ENTRY01_TEMPERATURE = HDC_ERROR_MEMORY__VAR__TEMPERATURE(1);

export const HDCErrorMemoryTableHeader
: React.FC<HDCErrorMemoryTableHeaderProps> = (props: HDCErrorMemoryTableHeaderProps):
  React.ReactElement => {
  const { t } = useTranslation();
  const { deviceInstanceId, targetDataset } = props;

  const errorEntryLabel = useContextDatasetDescriptorLabel(deviceInstanceId, PARA_ERROR_MEMORY_ENTRY01_ENTRY, targetDataset) ?? '';
  const workingHoursLabel = useContextDatasetDescriptorLabel(deviceInstanceId, PARA_ERROR_MEMORY_ENTRY01_WORK_HOURS, targetDataset) ?? '';
  const currentLabel = useContextDatasetDescriptorLabel(deviceInstanceId, PARA_ERROR_MEMORY_ENTRY01_CURRENT, targetDataset) ?? '';
  const voltageLabel = useContextDatasetDescriptorLabel(deviceInstanceId, PARA_ERROR_MEMORY_ENTRY01_VOLTAGE, targetDataset) ?? '';
  const temperatureLabel = useContextDatasetDescriptorLabel(deviceInstanceId, PARA_ERROR_MEMORY_ENTRY01_TEMPERATURE, targetDataset) ?? '';

  return (
    <DataTableRowHeader>
      <DataTableRowHeaderItem width={HDC_ERROR_MEMORY__HDR_ERROR_ENTRY__WIDTH}>
        {t(errorEntryLabel)}
      </DataTableRowHeaderItem>
      <DataTableRowHeaderItem width={HDC_ERROR_MEMORY__HDR_WORKING_HOURS__WIDTH}>
        {t(workingHoursLabel)}
      </DataTableRowHeaderItem>
      <DataTableRowHeaderItem width={HDC_ERROR_MEMORY__HDR_CURRENT__WIDTH}>
        {t(currentLabel)}
      </DataTableRowHeaderItem>
      <DataTableRowHeaderItem width={HDC_ERROR_MEMORY__HDR_VOLTAGE__WIDTH}>
        {t(voltageLabel)}
      </DataTableRowHeaderItem>
      <DataTableRowHeaderItem width={HDC_ERROR_MEMORY__HDR_TEMPERATURE__WIDTH}>
        {t(temperatureLabel)}
      </DataTableRowHeaderItem>
    </DataTableRowHeader>
  );
};
