/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

/**
 * List of languages to be used to report document creation.
 * Lanugages are defined according to ISO 3166 ALPHA-2.
 * https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
 * https://de.wikipedia.org/wiki/ISO-3166-1-Kodierliste
 */
export enum DocumentLanguage {
  EN = 'en', // english    // ENGLISH   //
  DE = 'de', // german     // DEUTSCH   //
  ES = 'es', // spanish    // ESPAÑOL   //
  FR = 'fr', // french     // FRANÇAIS  //
  IT = 'it', // italian    // ITALIANO  //
  JA = 'ja', // japanese   // 日本語    //
  PL = 'pl', // polish     // POLSKI    //
  PT = 'pt', // portuguese // PORTUGUÊS //
  RU = 'ru', // russian    // РУССКИЙ   //
  TR = 'tr', // turkish    // TÜRKÇE    //
  ZH = 'zh', // chinese
  CS = 'cs', // czech
  DA = 'da', // danish
  FI = 'fi', // finnish
  HU = 'hu', // hungarian
  KO = 'ko', // korean
  NB = 'nb', // norwegian
  NL = 'nl', // nederlands
  RO = 'ro', // romanian
  SV = 'sv', // swedish
  UK = 'uk', // ukrainan
}

/**
 * List of format types to be used to report document creation.
 */
export enum DocumentType {
  /**
   * DOCX — Office Open XML (ISO 29500)
   * mime-type: application/vnd.openxmlformats-officedocument.wordprocessingml.document
   */
  DOCX = 0,
  /**
   * PDF — Portable Document Format (ISO 32000)
   * mime-type: application/pdf
   */
  PDF = 1,
}

/**
 * List of document format types (for report creation) with their according mime type.
 */
export enum DocumentMimeType {
  /**
   * DOCX — Office Open XML (ISO 29500)
   * mime-type: application/vnd.openxmlformats-officedocument.wordprocessingml.document
   */
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  /**
   * PDF — Portable Document Format (ISO 32000)
   * mime-type: application/pdf
   */
  PDF = 'application/pdf',
}

export enum ReportContentType {
  Undefined = 0,
  DeviceReplacement = 1,
  DeviceRecovery = 2,
  DeviceStartup = 3,
  FileTransfer = 4,
  TransferToDevice = 5,
  ManualDeviceSettings = 6,
  EditParameters = 7,
  CompareParameters = 8,
}

export interface DeviceReplacement {
  type: typeof ReportContentType.DeviceReplacement;
  originalDevice: DeviceInfo;
  changeDevice: DeviceInfo;
  backupFileName?: string;
}

export interface DeviceRecovery {
  type: typeof ReportContentType.DeviceRecovery;
  device: DeviceInfo;
  paramFileName: string;
  backupFileName?: string;
}

export interface DeviceStartup {
  type: typeof ReportContentType.DeviceStartup;
  device: DeviceInfo;
}

export interface FileTransfer {
  type: typeof ReportContentType.FileTransfer;
  device: DeviceInfo;
  paramFileName: string;
  backupFileName?: string;
}

export interface TransferToDevice {
  type: typeof ReportContentType.TransferToDevice;
  device: DeviceInfo;
}

export interface ManualDeviceSettings {
  type: typeof ReportContentType.ManualDeviceSettings;
  device: DeviceInfo;
  userName: string;
}

export interface EditParameters {
  type: typeof ReportContentType.EditParameters;
  device: DeviceInfo;
}

export interface CompareParameters {
  type: typeof ReportContentType.CompareParameters;
  device: DeviceInfo;
}

export interface DeviceInfo {
  deviceDesignation: string;
  itemNumber: string;
  serialNumber: string;
  hardwareRevision: string;
  firmwareRevision: string;
  deviceTag: string;
}

export interface ReportHeader {
  dateTime: Date;
  systemName: string;
  userName: string;
  userNotes: string;
}

export declare type ReportContent =
  | DeviceReplacement
  | DeviceRecovery
  | DeviceStartup
  | FileTransfer
  | TransferToDevice
  | ManualDeviceSettings
  | EditParameters
  | CompareParameters;

export interface Report {
  header: ReportHeader;
  content: ReportContent;
}
