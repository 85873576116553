/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren } from 'react';
import { Row } from 'react-bootstrap';

export type MenuItemRowControlProps = PropsWithChildren<unknown>;

export const MenuItemRowControl:
React.FC<MenuItemRowControlProps> = (props: MenuItemRowControlProps): React.ReactElement => {
  const {
    children,
  } = props;

  return (
    <Row className="flex-nowrap align-items-center pt-1 m-0 px-0 w-100">
      {children}
    </Row>
  );
};
