import { createDecorator } from '../../instantiation/instantiation';
import { IService } from '../../services/Service';

export const IWebWorkerEventProviderDec = createDecorator<IWebWorkerEventProvider>('IWebWorkerEventProvider');

// eslint-disable-next-line no-redeclare
export interface IWebWorkerEventProvider extends IService {
  ServiceName: () => string;
  emitEvent: (ev: unknown) => void;
}
