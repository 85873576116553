/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import { LocString } from '../../deviceModelCommons';
import { ControlType } from '../controlTypes';

export enum IoLinkEventType {
    Warning = 'warning',
    Error = 'error',
    Message = 'message',
}

export interface IoLinkEventListItem {
    code: number;
    label: LocString;
    eventType: IoLinkEventType;
}

export interface IoLinkEventList {
    [key: string]: IoLinkEventListItem;
}

export interface IoLinkEventMonitorControlType {
    type: typeof ControlType.CTLIOLINKEVENTMONITOR;
    caption?: LocString;
    eventList: IoLinkEventList;
}

export const createIoLinkEventKey = (code: number): string => `ev${code}`;
