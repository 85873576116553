/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

import { IdentRef } from '../services/common';

export type LocString = string;

export enum StatusType {
    StructureDescriptor = 0,
    StatusDescriptor = 1,
    StatusValue = 2,
    ControlDescriptor = 3,
    ApplicationDescriptor = 4,
    ModificatorDescriptor = 5,
    MethodDescriptor = 6,
    ActionDescriptor = 7,
    WizardDescriptor = 8,
    SimulationDescriptor = 9,
    ValueValidationDescriptor = 10,
    PropertyDescriptor = 11,
}

export interface DescriptorReference {
    identRef: IdentRef;
    type: StatusType,
}

export enum DescriptorParameterType {
    CParam = 0,
    VParam = 1,
}

export interface DescriptorCParam {
    type: typeof DescriptorParameterType.CParam;
    name: string;
    value: any;
}

export interface DescriptorVParam {
    type: typeof DescriptorParameterType.VParam;
    variable: IdentRef;
}

export type DescriptorParameter = DescriptorCParam | DescriptorVParam;

export interface FunctionArgValue {
    value: any;
    unitid?: number;
}

export interface FunctionArgs {
    [key: string]: FunctionArgValue;
}

export enum DeviceModelFunctionType {
    modificator = 'modificator',
    method = 'method',
    action = 'action',
    valuevalidation = 'valuevalidation',
}
