/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

import { IService } from '../Service';
import { createDecorator } from '../../instantiation/instantiation';
import { StationItemInformation } from '../DeviceInformation';

export const IDeviceModelStationServiceDec = createDecorator<IDeviceModelStationService>('DeviceModelStationService');

// eslint-disable-next-line no-redeclare
export interface IDeviceModelStationService extends IService {
  ServiceName: () => string;

  emitAddDiscoveryItemsEvent: (deviceInstanceId: string, data: StationItemInformation[]) => Promise<void>;

  emitRemoveDiscoveryItemsEvent: (deviceInstanceId: string, data: string[]) => Promise<void>;

  emitUpdateStationEvent: (deviceInstanceId: string, title: string, subtitle: string) => Promise<void>;
}
