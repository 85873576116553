/* eslint-disable max-len */
import { Services } from '@gpt/commons';
import {
  ConnectionOptions, NatsConnection, connect, Events,
  DebugEvents, NatsError, JSONCodec, Msg, Payload,
  Subscription,
} from 'nats.ws';
import { Discovery } from '@gpt/cxe-nats-communication';
import { v4 as uuid } from 'uuid';
import EventEmitter from 'events';
import { NatsServerConnectionState } from './webworker.types';
import { createDiscoveryUpdateEvent } from '../events';
import { IoLinkMasterEvent } from '../events/iolinkmasterevent';

const logConsole = console;
const sc = JSONCodec();

export interface INatsClientConnection {
  // eslint-disable-next-line no-unused-vars
  connect: (clientId: string, host: string) => Promise<void>;
  disconnect: () => Promise<void>;
  isClosed: () => boolean;
  connection: () => NatsConnection | undefined;
  // eslint-disable-next-line no-unused-vars
  request: (subject: string, request: Services.DeviceModel.ICommunicationRequest)
    => Promise<Services.DeviceModel.ICommunicationResponse>;
}

export const createCommunicationErrorResponse = (exception: unknown): Services.DeviceModel.ICommunicationError => {
  if ((exception as NatsError)?.code === '503') {
    return {
      errorType: Services.DeviceModel.CommunicationErrorType.ConnectionLost,
      errorMessage: (exception as NatsError).message,
    };
  }
  return {
    errorType: Services.DeviceModel.CommunicationErrorType.UnknownError,
    errorMessage: (exception as Error)?.message ?? '??',
  };
};

export class NatsClientConnection extends EventEmitter implements INatsClientConnection {
  private natsConnection?: NatsConnection;

  private server?: ConnectionOptions;

  private clientId: string;

  private subDiscovery?: Subscription;

  private subHeartbeat?: Subscription;

  private subIoLinkMasterEvents?: Subscription;

  private inRecovery = false;

  // eslint-disable-next-line no-unused-vars
  public constructor() {
    super();
    this.natsConnection = undefined;
    this.server = undefined;
    this.clientId = '';
    this.subDiscovery = undefined;
    this.subHeartbeat = undefined;
    this.subIoLinkMasterEvents = undefined;
  }

  public connection = (): NatsConnection | undefined => this.natsConnection;

  public connect = async (clientId: string, host: string): Promise<void> => {
    this.inRecovery = true;
    await this.natsConnect({
      debug: false,
      verbose: false,
      name: clientId,
      servers: host,
    }, clientId);
  };

  public disconnect = async (): Promise<void> => {
    await this.natsDisconnect();
  };

  public isClosed = (): boolean => this.natsConnection?.isClosed() ?? true;

  private subscribe = (conn: NatsConnection) => {
    this.subDiscovery = conn.subscribe('view.discovery', {
    // this.subDiscovery = conn.subscribe('discovery.*', {
      callback: (err: NatsError | null, msg: Msg) => {
        if (err) {
          logConsole.log(`SUB: discovery error ${err.code} ${err.name} ${err.message}`, err);
          return;
        }

        const adapters = sc.decode(msg.data) as Discovery.CommunicationAdapter[];
        const ev = createDiscoveryUpdateEvent({
          subject: msg.subject,
          adapter: adapters,
        });
        this.emit('on-discovery', ev);
      },
    });
    this.subHeartbeat = conn.subscribe('view.heartbeat', {
      // eslint-disable-next-line arrow-body-style, no-unused-vars, @typescript-eslint/no-unused-vars
      callback: (err: NatsError | null, msg: Msg) => {
        if (err) {
          logConsole.log(`SUB: view.heartbeat error ${err.code} ${err.name} ${err.message}`, err);
          return;
        }
        const payload: Payload = sc.encode({ pong: new Date().toISOString() });
        msg.respond(payload);
      },
    });
    this.subIoLinkMasterEvents = conn.subscribe('io-link.events.master.*', {
      // eslint-disable-next-line arrow-body-style, no-unused-vars, @typescript-eslint/no-unused-vars
      callback: (err: NatsError | null, msg: Msg) => {
        if (err) {
          logConsole.log(`SUB: io-link.events.master error ${err.code} ${err.name} ${err.message}`, err);
          return;
        }
        const masterEvent = sc.decode(msg.data) as IoLinkMasterEvent;
        this.emit('on-iolink-master-event', masterEvent);
      },
    });
  };

  private ubsubscribe = () => {
    this.subDiscovery?.unsubscribe();
    this.subDiscovery = undefined;
    this.subHeartbeat?.unsubscribe();
    this.subHeartbeat = undefined;
    this.subIoLinkMasterEvents?.unsubscribe();
    this.subIoLinkMasterEvents = undefined;
  };

  private natsConnect = async (server: ConnectionOptions, clientId: string): Promise<void> => {
    logConsole.debug('connect');
    this.server = server;
    this.clientId = clientId;
    this.emitConnectionEvent(NatsServerConnectionState.Reconnecting, clientId);
    this.inRecovery = true;
    if (this.natsConnection !== undefined && !this.natsConnection.isClosed()) {
      logConsole.debug('connect: close existing connection');
      this.ubsubscribe();
      await this.natsConnection.close();
      this.natsConnection = undefined;
    }
    logConsole.debug('connect: open new connection');
    this.natsConnection = await this.createConnection(server, clientId);
  };

  public reconnect = async (): Promise<NatsConnection | undefined> => {
    logConsole.debug('reconnect');
    this.emitConnectionEvent(NatsServerConnectionState.Reconnecting, this.clientId);
    if (this.natsConnection !== undefined && !this.natsConnection.isClosed()) {
      logConsole.debug('reconnect: close existing connection');
      this.ubsubscribe();
      await this.natsConnection.close();
      this.natsConnection = undefined;
    }
    if (this.server !== undefined) {
      logConsole.debug('reconnect: open new connection');
      this.natsConnection = await this.createConnection(this.server, this.clientId);
    }
    return this.natsConnection;
  };

  public natsDisconnect = async (): Promise<void> => {
    logConsole.debug('disconnect');
    this.inRecovery = false;
    this.ubsubscribe();
    if (this.natsConnection) {
      await this.natsConnection.close();
    }
    this.natsConnection = undefined;
  };

  public request = async (subject: string, request: Services.DeviceModel.ICommunicationRequest)
  : Promise<Services.DeviceModel.ICommunicationResponse> => {
    logConsole.debug('request', request);
    const { requestId, actionId } = request;

    let response: Services.DeviceModel.ICommunicationResponse = {
      requestId,
      actionId,
      payload: {
        errorType: Services.DeviceModel.CommunicationErrorType.ConnectionLost,
        errorMessage: 'NATS Connection closed',
      },
    };

    if (this.natsConnection !== undefined && !this.natsConnection.isClosed()) {
      let res: Msg | undefined;
      const reqdata = sc.encode(request);
      try {
        if (this.natsConnection?.isClosed() === false) {
          res = await this.natsConnection.request(subject, reqdata, { timeout: 120000 });
          response = res.json<Services.DeviceModel.ICommunicationResponse>();
        } else {
          logConsole.log('Connection closed');
        }
      } catch (ex: unknown) {
        logConsole.log('exception: request ->', request.requestId, (ex as any)?.message);
        const payload = createCommunicationErrorResponse(ex);
        response = {
          requestId,
          actionId,
          payload,
        };
      }
    } else {
      logConsole.log('connection not init: ->', request.requestId);
    }
    return response;
  };

  private emitConnectionEvent = (state: NatsServerConnectionState, clientId: string): void => {
    this.emit('on-connection-state', {
      id: uuid(), type: 'NATS_SERVER__EVENT__CONNECTION_STATE', state, clientId,
    });
  };

  private retryConnect = async (server: ConnectionOptions, clientId: string): Promise<NatsConnection | undefined> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reconnect = async (resolve: any, reject: any, retryCount: number) => {
      logConsole.log(`Reconnecting ... ${retryCount < 0 ? 'endless' : retryCount}`);
      this.emitConnectionEvent(NatsServerConnectionState.Reconnecting, clientId);

      let connection: NatsConnection | undefined;
      try {
        connection = await connect(server);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (ex: unknown) {
        connection = undefined;
        if (ex instanceof Error) {
          logConsole.log('Cannot connect to server', ex.message);
        } else {
          logConsole.log('Cannot connect to server');
        }
      }

      if (connection === undefined && retryCount === 0) {
        reject();
      } else if (connection === undefined && retryCount !== 0) {
        setTimeout(() => {
          reconnect(resolve, reject, retryCount > 0 ? retryCount - 1 : retryCount);
        }, 3000);
      } else {
        resolve(connection);
      }
    };

    return new Promise<NatsConnection | undefined>((resolve, reject) => { reconnect(resolve, reject, -10); });
  };

  // eslint-disable-next-line max-len
  private createConnection = async (server: ConnectionOptions, clientId: string)
  : Promise<NatsConnection | undefined> => {
    let connection: NatsConnection | undefined;

    try {
      connection = await this.retryConnect(server, clientId);
    } catch {
      connection = undefined;
    }

    if (connection === undefined) {
      this.emitConnectionEvent(NatsServerConnectionState.Disconnected, clientId);
      return undefined;
    }

    connection.closed().then((err) => {
      if (err) {
        if (this.inRecovery) {
          this.reconnect();
        }
      } else {
        logConsole.log('the connection closed.');
      }
      this.emitConnectionEvent(NatsServerConnectionState.Closed, clientId);
    });

    (async () => {
      // eslint-disable-next-line no-restricted-syntax
      for await (const s of connection.status()) {
        switch (s.type) {
          case Events.Disconnect:
            this.emitConnectionEvent(NatsServerConnectionState.Disconnected, clientId);
            break;
          case Events.LDM:
            break;
          case Events.Update:
            break;
          case Events.Reconnect:
            this.emitConnectionEvent(NatsServerConnectionState.Connected, clientId);
            break;
          case Events.Error:
            this.emitConnectionEvent(NatsServerConnectionState.Disconnected, clientId);
            break;
          case DebugEvents.Reconnecting:
            this.emitConnectionEvent(NatsServerConnectionState.Reconnecting, clientId);
            break;
          case DebugEvents.StaleConnection:
            break;
          case DebugEvents.PingTimer:
            break;
          default:
        }
      }
    })().then();

    if (!connection.isClosed()) {
      logConsole.log('subscribe');
      this.subscribe(connection);
      logConsole.log('emit -> subscribe');
      this.emitConnectionEvent(NatsServerConnectionState.Connected, clientId);
    } else {
      this.emitConnectionEvent(NatsServerConnectionState.Closed, clientId);
    }

    logConsole.log('done');
    return connection;
  };
}
