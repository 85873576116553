import { AppType } from './appTypes';
import { CockpitApplicationType } from './cockpitApplication';
import { CompareDeviceApplicationType } from './compareDeviceApplication';
import { DeviceSpecificApplicationType } from './deviceSpecificApplication';
import { InitialDeviceStartupApplicationType } from './InitialDeviceStartupApplication';
import { EditDeviceApplicationType } from './editDeviceApplication';
import { ExpertCompareDeviceApplicationType } from './expertCompareDeviceApplication';
import { PopupEditorApplicationType } from './popupEditorApplication';
import { ReportDeviceApplicationType } from './reportDeviceApplication';
import { ReviewDeviceApplicationType } from './reviewDeviceApplication';
import { DIPSwitchWizardConfigurationApplicationType } from './dipSwitchWizardConfigurationApplication';
import { DIPSwitchWizardDisplayApplicationType } from './dipSwitchWizardDisplayApplication';
import { DIPSwitchPopupDisplayApplicationType } from './dipSwitchPopupDisplayApplication';
import { OnlineParamApplicationType } from './onlineParamApplication';

export type ApplicationDescriptorType = CockpitApplicationType
    | CompareDeviceApplicationType
    | DeviceSpecificApplicationType
    | InitialDeviceStartupApplicationType
    | EditDeviceApplicationType
    | ExpertCompareDeviceApplicationType
    | PopupEditorApplicationType
    | ReportDeviceApplicationType
    | DIPSwitchWizardConfigurationApplicationType
    | DIPSwitchWizardDisplayApplicationType
    | DIPSwitchPopupDisplayApplicationType
    | ReviewDeviceApplicationType
    | OnlineParamApplicationType;

export {
  AppType,
};

export type {
  CockpitApplicationType,
  CompareDeviceApplicationType,
  DeviceSpecificApplicationType,
  InitialDeviceStartupApplicationType,
  EditDeviceApplicationType,
  ExpertCompareDeviceApplicationType,
  PopupEditorApplicationType,
  ReportDeviceApplicationType,
  DIPSwitchWizardConfigurationApplicationType,
  DIPSwitchWizardDisplayApplicationType,
  DIPSwitchPopupDisplayApplicationType,
  ReviewDeviceApplicationType,
}