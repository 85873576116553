import { WebDevice } from '@gpt/commons';
import { DiscoveryServiceDeviceCatalogCollection } from '../discoveryServiceState';

/**
   * Matches the list of available device drivers against device type identifier of devices
   * which were detected by continuous device topology scan (CommunicationServerScanService).
   */
const matchDeviceDriver = (
  deviceTypeIdentifier: string,
  deviceCatalog: DiscoveryServiceDeviceCatalogCollection,
): WebDevice.DeviceCatalogInformation | undefined => {
  // macht list of available device drivers with DeviceTypeIdentifier
  const drivers = Object
    .keys(deviceCatalog)
    .filter((catalogId) => (
      deviceCatalog[catalogId].deviceDriverId.match(new RegExp(`^${deviceTypeIdentifier}_`, 'i')) !== null));
  if (drivers.length === 0) {
    console.log('no match found:', deviceTypeIdentifier, Object.values(deviceCatalog).map((cat) => ({
      deviceCatalogTitle: cat.deviceCatalogTitle,
      deviceCatalogIdent: cat.deviceCatalogIdent,
      deviceDriverId: cat.deviceDriverId,
    })));
    return undefined;
  }
  if (drivers.length > 1) {
    // eslint-disable-next-line no-console
    console.log('multiple device drivers matching device type:', deviceTypeIdentifier);
  }
  return deviceCatalog[drivers[0]];
};

export default matchDeviceDriver;
