/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus } from '@gpt/commons';
import { useContextDatasetStatusDescriptor } from '../../../../hooks/useContextDataset';
import useStatusValueStateUpdate from '../../hooks/useStatusValueStateUpdate';
import { Quint4CharacteristicSelectorProps } from '../CharacteristicSelector/Quint4CharacteristicSelector';
import { Quint4ImageSelectorItem } from './Quint4ImageSelectorItem/Quint4ImageSelectorItem';
import UiAdvancedImage from './assets/image_Quint4_Mode_Ui_Advanced.svg';
import smartHiccupImage from './assets/image_Quint4_Mode_Hiccup.svg';
import fuseModeImage from './assets/image_Quint4_Mode_Fuse.svg';
import { MenuItemRowControl, MenuItemRowItem } from '../../../../components/MenuItemRowControl';
import { setContextParameterHelp } from '../../../../store/contexthelp';

const modeImageMap = {
  1: UiAdvancedImage,
  2: fuseModeImage,
  4: smartHiccupImage,
};

const imageJustify = {
  0: 'justify-content-end',
  1: 'justify-content-center',
  2: 'justify-content-start',
};

const Quint4CharacteristicLineImageSelector
  :React.FC<Quint4CharacteristicSelectorProps> = (props: Quint4CharacteristicSelectorProps):
    React.ReactElement => {
    const {
      deviceInstanceId,
      statusValue,
      onStateChanged,
      onValueChange,
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const statusDescriptor = useContextDatasetStatusDescriptor(deviceInstanceId, statusValue?.identRef ?? '-');
    const visibility = useStatusValueStateUpdate(statusValue?.identRef ?? '-', deviceInstanceId, undefined, onStateChanged);

    if (statusValue === undefined) {
      return <></>;
    }

    if (visibility === false) {
      return <></>;
    }

    if (statusDescriptor?.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR) {
      return <></>;
    }

    const { valueType } = statusDescriptor;
    const statusValueHelp = statusDescriptor.help === undefined ? '' : t(statusDescriptor.help);
    const menuItemRow = valueType.enumerator.map((enumItem, index) => {
      const image = modeImageMap[enumItem.value] ?? UiAdvancedImage;
      const justifyContent = imageJustify[index] ?? 'justify-content-center';
      return (
        <MenuItemRowItem width={4} key={`quint4-image-item-${enumItem.value}--${enumItem.label}`} customClassName={`d-flex align-items-center ${justifyContent}`}>
          <Quint4ImageSelectorItem
            value={statusValue.value === enumItem.value}
            title={t(enumItem.label)}
            image={image}
            onChange={() => onValueChange(enumItem.value)}
            customClassName="d-flex flex-column align-items-center justify-content-start mb-4"
            onFocus={() => {
              dispatch(setContextParameterHelp({
                title: enumItem.label === undefined ? '' : t(enumItem.label),
                text: statusValueHelp,
              }));
            }}
            onBlur={() => {
              dispatch(setContextParameterHelp({
                title: '',
                text: '',
              }));
            }}
          />
        </MenuItemRowItem>
      );
    });

    return (
      <MenuItemRowControl>
        {menuItemRow}
      </MenuItemRowControl>
    );
  };

export default Quint4CharacteristicLineImageSelector;
