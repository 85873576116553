import { RequestBase, ResponseBase } from '../../common';
import { DatasetFilter } from './DatasetProviderService';
import { ExternalDataset } from './externalDataset';

export const DPS_GET_DATASETLIST_REQUEST = 'DPS_GET_DATASETLIST_REQUEST';
export const DPS_GET_DATASETLIST_RESPONSE = 'DPS_GET_DATASETLIST_RESPONSE';
export const DPS_GET_DATASET_REQUEST = 'DPS_GET_DATASET_REQUEST';
export const DPS_GET_DATASET_RESPONSE = 'DPS_GET_DATASET_RESPONSE';

export interface GetDatasetListRequest extends RequestBase {
  type: typeof DPS_GET_DATASETLIST_REQUEST
  filter: DatasetFilter;
}

export interface GetDatasetListResponse extends ResponseBase {
  type: typeof DPS_GET_DATASETLIST_RESPONSE,
  datasetList: ExternalDataset[];
}

export interface GetDatasetRequest extends RequestBase {
  type: typeof DPS_GET_DATASET_REQUEST
  datasetId: string;
}

export interface GetDatasetResponse extends ResponseBase {
  type: typeof DPS_GET_DATASET_RESPONSE,
  dataset?: ExternalDataset;
}

export type DatasetProviderRequestType = GetDatasetListRequest
    | GetDatasetListResponse
    | GetDatasetRequest
    | GetDatasetResponse;
