/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TextEditorControl from '../../../ParameterEditor/TextEditorControl/TextEditorControl';
import IconCheckboxChecked from '../../../../assets/icons/checkbox-checked.svg';
import IconCheckboxUnchecked from '../../../../assets/icons/checkbox-unchecked.svg';
import './BackstagePageFeedback.scss';

// interface FileItem {
//   file: File;
//   id: string;
// }

const BackstagePageFeedback: React.FC = (): React.ReactElement => {
  const [userName, setUserName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [userMailAddress, setUserMailAddress] = useState('');
  const [description, setDescription] = useState('');
  // const [files, setFiles] = useState<FileItem[]>([]);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  // const [tempMessage, setTempMessage] = useState<string | null>(null);
  const { t } = useTranslation();

  // useEffect(() => {
  //   if (tempMessage) {
  //     const timer = setTimeout(() => {
  //       setTempMessage(null);
  //     }, 10000);
  //     return () => clearTimeout(timer);
  //   }
  //   return undefined;
  // }, [tempMessage]);

  // const getTotalSize = (fileList: FileItem[]) => fileList.reduce((total, item) => total + item.file.size, 0) / (1024 * 1024);

  // const addFiles = (newFiles: FileItem[]) => {
  //   const newTotalSize = getTotalSize([...files, ...newFiles]);

  //   if (newTotalSize > 25) {
  //     setTempMessage('maximal 25 MB');
  //     return;
  //   }

  //   setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  // };

  // const removeFile = (id: string) => {
  //   setFiles((prevFiles) => prevFiles.filter((fileItem) => fileItem.id !== id));
  // };

  const checkboxClicked = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  const sendFeedback = () => {
    const recipient = 'support_cxe_dp@phoenixcontact.com';
    const cc = encodeURIComponent(userMailAddress);
    const subject = encodeURIComponent('Unterstützungsanfrage oder Fehlermeldung zu clipx ENGINEER device parameterization');
    const body = encodeURIComponent(`Benutzername: ${userName}\nFirma: ${companyName}\nFirmenadresse: ${companyAddress}\nE-Mail-Adresse: ${userMailAddress}\n\nBeschreibung: ${description}`);

    window.location.href = `mailto:${recipient}?cc=${cc}&subject=${subject}&body=${body}`;
  };

  return (
    <div>
      <h1>{t('BACKSTAGE_PAGE_FEEDBACK__TITLE')}</h1>
      <div>{t('BACKSTAGE_PAGE_FEEDBACK__DESCRIPTION1')}</div>
      <div>{t('BACKSTAGE_PAGE_FEEDBACK__DESCRIPTION2')}</div>
      <div>{t('BACKSTAGE_PAGE_FEEDBACK__DESCRIPTION3')}</div>
      <div className="backstage-feedback_inputs">
        <p>{t('BACKSTAGE_PAGE_FEEDBACK__USER_NAME')}</p>
        <TextEditorControl
          displayFormat=""
          value={userName}
          onValueChange={(value) => setUserName(value)}
        />
        <p>{t('BACKSTAGE_PAGE_FEEDBACK__COMPANY_NAME')}</p>
        <TextEditorControl
          displayFormat=""
          value={companyName}
          onValueChange={(value) => setCompanyName(value)}
        />
        <p>{t('BACKSTAGE_PAGE_FEEDBACK__COMPANY_ADDRESS')}</p>
        <TextEditorControl
          displayFormat=""
          value={companyAddress}
          onValueChange={(value) => setCompanyAddress(value)}
        />
        <p>{t('BACKSTAGE_PAGE_FEEDBACK__USER_MAIL_ADDRESS')}</p>
        <TextEditorControl
          displayFormat=""
          value={userMailAddress}
          onValueChange={(value) => setUserMailAddress(value)}
        />
      </div>
      <h2>{t('BACKSTAGE_PAGE_FEEDBACK__TITLE')}</h2>
      <textarea
        className="backstage-feedback_description"
        placeholder={t<string>('BACKSTAGE_PAGE_FEEDBACK__DESCRIPTION')}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <h2>{t('BACKSTAGE_PAGE_FEEDBACK__ATTACH_FILES')}</h2>
      <div className="backstage-feedback_choose-files-hint">{t('BACKSTAGE_PAGE_FEEDBACK__ATTACH_FILES_HINT')}</div>
      {/* <div className="backstage-feedback_choose-files">
        <div className="backstage-feedback_choose-files-button"><ChooseFileButton addFiles={addFiles} /></div>
        <span className="backstage-feedback_choose-files-text">{t('BACKSTAGE_PAGE_FEEDBACK__OR')}</span>
        <div><DragDropField addFiles={addFiles} /></div>
        {tempMessage && <div className="backstage-feedback_temp-message">{tempMessage}</div>}
      </div>
      <div className="backstage-feedback_files">
        {files.map(({ file, id }) => (
          <FileButton key={id} fileName={file.name} onRemove={() => removeFile(id)} />
        ))}
      </div> */}
      <div className="backstage-feedback_user-agreement">
        <div
          className="backstage-feedback_user-agreement-checkbox"
          role="button"
          tabIndex={0}
          onClick={() => checkboxClicked()}
          onKeyUp={() => 0}
        >
          <img src={checkboxChecked ? IconCheckboxChecked : IconCheckboxUnchecked} alt="checkbox" />
        </div>
        <div className="backstage-feedback_user-agreement-text">
          <div>{t('BACKSTAGE_PAGE_FEEDBACK__USER_AGREEMENT')}</div>
          <div className="backstage-feedback_user-agreement-text-info">{t('BACKSTAGE_PAGE_FEEDBACK__NECESSARY_INFO')}</div>
        </div>
      </div>
      <Button
        className="backstage-feedback_submit-button"
        variant="primary"
        onClick={sendFeedback}
        disabled={!userName.trim() || !userMailAddress.trim() || !description.trim() || !checkboxChecked}
      >
        <span>{t('BACKSTAGE_PAGE_FEEDBACK__SUBMIT_BUTTON')}</span>
      </Button>
    </div>
  );
};

export default BackstagePageFeedback;
