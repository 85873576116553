/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { useTranslation } from 'react-i18next';
import GearButton from './GearButton/GearButton';
import './StartupViewHeader.scss';

const StartupViewHeader: React.FC = () : React.ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <div className="startup-view__header startup-view-header">
        <div className="startup-view-header__text">
          <div className="startup-view-header__text__title startup-view__text__title">
            {t<string>('STARTUP_VIEW__DEVICES__TITLE')}
          </div>
          <div className="startup-view-header__text__subtitle startup-view__text__subtitle">
            {t<string>('STARTUP_VIEW__DEVICES__TEXT')}
          </div>
        </div>
        <div className="startup-view-header__tools">
          <GearButton />
        </div>
      </div>
    </>
  );
};

export default StartupViewHeader;
