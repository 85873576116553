/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import {
  WebDeviceInitRequest, WebDeviceInitResponse,
  WebDeviceDisposeRequest, WebDeviceDisposeResponse,
} from './init';
import {
  WriteVariableDeviceValuesRequest, WriteVariableDeviceValuesResponse,
  WriteVariableValuesRequest, WriteVariableValuesResponse,
  LoadExternalDatasetRequest, LoadExternalDatasetResponse,
  WriteUploadValuesRequest, WriteUploadValuesResponse,
  DeviceModelResourceRequest, DeviceModelResourceResponse,
  DeviceModelReinitDatasetRequest, DeviceModelReinitDatasetResponse,
  UpdateDatasetRequest, UpdateDatasetResponse,
  WriteDatasetRequest, WriteDatasetResponse,
  DeviceModelTranslationRequest, DeviceModelTranslationResponse,
  CreateExternalDeviceModelStatusRequest, CreateExternalDeviceModelStatusResponse,
} from './devicemodel';
import {
  WebDeviceUpdateDataEvent,
  WebDeviceConnectionStatusEvent,
  WebDeviceUpdateCustomObjectEvent,
  WebDeviceActionProgressEvent,
} from './events';
import {
  WebDeviceExecuteMethodRequest,
  WebDeviceExecuteMethodResponse,
  WebDeviceNotificationEvent,
} from './methods';
import {
  WebDeviceErrorResponse,
} from './error';
import {
  WebDeviceTrendDataEvent,
  WebDeviceTrendDataRequest,
  WebDeviceTrendDataResponse,
} from './trenddata';
import {
  WebDeviceLogDataEvent,
} from './logger';
import {
  WebDeviceUpdateConnectionRequest,
  WebDeviceUpdateConnectionResponse,
} from './connection';
import {
  WebDeviceCreateDeviceEvent,
  WebDeviceDeleteDeviceEvent,
  WebDeviceSelectDeviceEvent,
} from './instanceManager';
import {
  WebDeviceStationEvent,
} from './station';

export type WebDeviceRequestTypes =
| WebDeviceInitRequest
| LoadExternalDatasetRequest
| WebDeviceDisposeRequest
| WriteVariableDeviceValuesRequest
| WriteUploadValuesRequest
| WriteVariableValuesRequest
| DeviceModelResourceRequest
| DeviceModelReinitDatasetRequest
| WebDeviceExecuteMethodRequest
| WebDeviceTrendDataRequest
| UpdateDatasetRequest
| WriteDatasetRequest
| DeviceModelTranslationRequest
| WebDeviceUpdateConnectionRequest
| CreateExternalDeviceModelStatusRequest;

export type WebDeviceResponseTypes =
| WebDeviceInitResponse
| WebDeviceDisposeResponse
| WriteVariableDeviceValuesResponse
| WriteVariableValuesResponse
| LoadExternalDatasetResponse
| WriteUploadValuesResponse
| DeviceModelResourceResponse
| WebDeviceErrorResponse
| DeviceModelReinitDatasetResponse
| WebDeviceExecuteMethodResponse
| WebDeviceTrendDataResponse
| UpdateDatasetResponse
| WriteDatasetResponse
| DeviceModelTranslationResponse
| WebDeviceUpdateConnectionResponse
| CreateExternalDeviceModelStatusResponse;

export type WebDeviceEventsTypes =
| WebDeviceUpdateDataEvent
| WebDeviceNotificationEvent
| WebDeviceConnectionStatusEvent
| WebDeviceTrendDataEvent
| WebDeviceLogDataEvent
| WebDeviceUpdateCustomObjectEvent
| WebDeviceCreateDeviceEvent
| WebDeviceDeleteDeviceEvent
| WebDeviceSelectDeviceEvent
| WebDeviceStationEvent
| WebDeviceActionProgressEvent;

export * from './catalog';
export * from './connection';
export * from './devicemodel';
export * from './error';
export * from './events';
export * from './init';
export * from './instanceManager';
export * from './logger';
export * from './methods';
export * from './service';
export * from './station';
export * from './trenddata';
export * from './types';
