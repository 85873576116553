/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

import * as ConnectionService from './ConnectionService';
import * as DatasetProviderService from './DatasetProviderService';
import {
  ConfigValue,
  GetConfigurationRequest, GetConfigurationResponse,
  SetConfigurationRequest, SetConfigurationResponse,
} from './ConfigurationService';
import {
  DeviceDriverInstantiationRequest, DeviceDriverInstantiationResponse,
  DeviceDriverDisposalRequest, DeviceDriverDisposalResponse,
  ServiceDisposeRequest, ServiceDisposeResponse,
  ServiceInstantiationRequest, ServiceInstantiationResponse,
} from './DeviceDriverInstantiationService';
import {
  DeviceModelServerConfiguration,
} from './DeviceModelServerConfiguration';
import {
  GetStatusVectorRequest, GetStatusVectorResponse,
  ChangeDeviceRequest, ChangeDeviceResponse,
  GetDeviceModelStatusRequest, GetDeviceModelStatusResponse,
  DeviceModelUpdateEventData,
  ReadDataServiceRequest, ReadDataServiceResponse,
  WriteDataServiceRequest, WriteDataServiceResponse,
  DeviceModelApplication,
  DeviceModelDisposeRequest, DeviceModelDisposeResponse,
  DeviceModelInitRequest, DeviceModelInitResponse,
} from './DeviceModelService';
import {
  DeviceScoutServiceUpdate,
} from './DeviceScoutService';
import {
  DeviceTimelineService, DeviceTimelineData,
  DeviceTimelineServiceRequest, DeviceTimelineServiceResponse,
} from './DeviceTimelineService';
import {
  i18nGetNamespacesRequest, i18nGetNamespacesResponse,
  i18nGetLanguagesRequest, i18nGetLanguagesResponse,
  i18nLoadTranslationsRequest, i18nLoadTranslationsResponse,
  i18nMissingKeyRequest, i18nMissingKeyResponse,
} from './i18nService';
import {
  GetResourceRequest, GetSpecificResourceRequest, GetResourceResponse,
} from './ResourceService';
import {
  SystemInformationRequest, SystemInformationResponse,
  GetTextDocumentRequest, GetTextDocumentResponse,
  GetCommunicationConfigRequest, GetCommunicationConfigResponse,
  SetCommunicationConfigRequest, SetCommunicationConfigResponse,
} from './SystemInformationService';
import {
  UserInformationRequest, UserInformationResponse,
} from './UserInformationService';
import {
  ReportServiceRequest,
  ReportServiceResponse,
} from './ReportService';
import { ApplicationId } from './ApplicationIdent';

export {
  DatasetProviderService,
  ConnectionService,
};

export type {
  ApplicationId,
  // ConfigurationService
  ConfigValue,
  GetConfigurationRequest, GetConfigurationResponse,
  SetConfigurationRequest, SetConfigurationResponse,
  // DeviceDriverInstantiationService
  DeviceDriverInstantiationRequest, DeviceDriverInstantiationResponse,
  DeviceDriverDisposalRequest, DeviceDriverDisposalResponse,
  ServiceDisposeRequest, ServiceDisposeResponse,
  ServiceInstantiationRequest, ServiceInstantiationResponse,
  // DeviceModelServerConfiguration
  DeviceModelServerConfiguration,
  // DeviceModelService
  GetStatusVectorRequest, GetStatusVectorResponse,
  ChangeDeviceRequest, ChangeDeviceResponse,
  GetDeviceModelStatusRequest, GetDeviceModelStatusResponse,
  DeviceModelUpdateEventData,
  ReadDataServiceRequest, ReadDataServiceResponse,
  WriteDataServiceRequest, WriteDataServiceResponse,
  DeviceModelApplication,
  DeviceModelDisposeRequest, DeviceModelDisposeResponse,
  DeviceModelInitRequest, DeviceModelInitResponse,
  // DeviceScoutService
  DeviceScoutServiceUpdate,
  // DeviceTimelineService
  DeviceTimelineService, DeviceTimelineData,
  DeviceTimelineServiceRequest, DeviceTimelineServiceResponse,
  // i18nService
  i18nGetNamespacesRequest, i18nGetNamespacesResponse,
  i18nGetLanguagesRequest, i18nGetLanguagesResponse,
  i18nLoadTranslationsRequest, i18nLoadTranslationsResponse,
  i18nMissingKeyRequest, i18nMissingKeyResponse,
  // ResourceService
  GetResourceRequest, GetSpecificResourceRequest, GetResourceResponse,
  // SystemInformationService
  SystemInformationRequest, SystemInformationResponse,
  GetTextDocumentRequest, GetTextDocumentResponse,
  GetCommunicationConfigRequest, GetCommunicationConfigResponse,
  SetCommunicationConfigRequest, SetCommunicationConfigResponse,
  // UserInformationService
  UserInformationRequest, UserInformationResponse,
  // ReportService
  ReportServiceRequest,
  ReportServiceResponse,
};
