/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { CTAButton } from '../../../components/CTAButton/CTAButton';
import useAppDownloadLink from '../../hook/useAppDownloadLink';
import { ENV_UPDATE_CHANNEL } from '../../../enviroment';
import { LocalBackendVersionStatus } from '../../hook/useBackendVersion';
import useAppTranslation from '../hooks/useAppTranslation';
import './DownloadLinkButton.css';

export interface DownloadLinkButtonProps {
  status: LocalBackendVersionStatus;
}

// eslint-disable-next-line import/prefer-default-export
const DownloadLinkButton: React.FC<DownloadLinkButtonProps> = (props: DownloadLinkButtonProps): React.ReactElement => {
  const { status } = props;
  const updateChannel = ENV_UPDATE_CHANNEL;
  const availableSetup = useAppDownloadLink(updateChannel);
  const appTranslation = useAppTranslation();

  const header = status === LocalBackendVersionStatus.NotAvailable
    ? appTranslation('hdr_download_setup')
    : appTranslation('hdr_local_service_not_compatible');

  const downloadLinkButton = (availableSetup === undefined)
    ? <div className="loading-splash-screen__text p-2">Setup link not available</div>
    : (
      <>
        <div className="loading-splash-screen__download_setup__text">
          {appTranslation('txt_download_link')}
        </div>
        <div className="p-2">
          <a className="p-2" href={availableSetup}>
            <CTAButton
              caption={appTranslation('hdr_download_button')}
              variant="secondary"
              onClick={() => 0}
            />
          </a>
        </div>
        <div className="loading-splash-screen__download_setup__text">
          {appTranslation('txt_installation_rights')}
        </div>
      </>
    );

  return (
    <>
      <div className="loading-splash-screen__download_setup__header" data-testid="download-link-button">
        {header}
      </div>
      {downloadLinkButton}
    </>
  );
};

export default DownloadLinkButton;
