/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { DeviceModelStatus, IdentRef } from '@gpt/commons';

const updateDatasetDescriptors = (
  identRef: IdentRef | undefined,
  datasetDescriptors: DeviceModelStatus.DeviceModelDescriptorCollection,
  descriptors: DeviceModelStatus.DeviceModelDescriptor[],
): DeviceModelStatus.DeviceModelDescriptor[] => {
  if (identRef === undefined) {
    return descriptors;
  }
  const descriptor = datasetDescriptors[identRef];
  return descriptor === undefined
    ? descriptors
    : [
      ...descriptors,
      descriptor,
    ];
};

const parseUserLinTableData = (
  valueTypeTable: DeviceModelStatus.StatusDescriptorValueTypeTable,
  datasetDescriptors: DeviceModelStatus.DeviceModelDescriptorCollection,
): DeviceModelStatus.DeviceModelDescriptor[] => {
  const data = valueTypeTable.records.reduce((acc, item) => {
    const recordDesc = datasetDescriptors[item];
    if (recordDesc.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return [
        ...acc,
        recordDesc,
      ];
    }
    const { valueType } = recordDesc;
    if (valueType.type !== DeviceModelStatus.StatusDescriptorValueType.TABLERECORD) {
      return [
        ...acc,
        recordDesc,
      ];
    }

    const record = recordDesc.valueType as DeviceModelStatus.StatusDescriptorValueTypeTableRecord;
    return [
      ...acc,
      ...record.members.map((mem) => datasetDescriptors[mem]),
      recordDesc,
    ];
  }, [] as DeviceModelStatus.DeviceModelDescriptor[]);
  return data;
};

const parseCtlLinearization = (
  controlType: DeviceModelStatus.UI.LinearizationControlType,
  datasetDescriptors: DeviceModelStatus.DeviceModelDescriptorCollection,
): DeviceModelStatus.DeviceModelDescriptor[] => {
  let descriptors: DeviceModelStatus.DeviceModelDescriptor[] = [];
  descriptors = updateDatasetDescriptors(controlType.coldJunctionComp0, datasetDescriptors, descriptors);
  descriptors = updateDatasetDescriptors(controlType.coldJunctionComp80, datasetDescriptors, descriptors);
  descriptors = updateDatasetDescriptors(controlType.coldLineEquationB, datasetDescriptors, descriptors);
  descriptors = updateDatasetDescriptors(controlType.coldLineEquationM, datasetDescriptors, descriptors);
  descriptors = updateDatasetDescriptors(controlType.minDeviation, datasetDescriptors, descriptors);
  descriptors = updateDatasetDescriptors(controlType.numberOfPoints, datasetDescriptors, descriptors);
  descriptors = updateDatasetDescriptors(controlType.splineData, datasetDescriptors, descriptors);
  descriptors = updateDatasetDescriptors(controlType.userCharMaxX, datasetDescriptors, descriptors);
  descriptors = updateDatasetDescriptors(controlType.userCharMinX, datasetDescriptors, descriptors);
  descriptors = updateDatasetDescriptors(controlType.userLinData, datasetDescriptors, descriptors);

  const userLinData = datasetDescriptors[controlType.userLinData];
  const linData = (userLinData?.type === DeviceModelStatus.StatusType.StatusDescriptor
        && userLinData.valueType.type === DeviceModelStatus.StatusDescriptorValueType.TABLE
        && userLinData.visibility)
    ? parseUserLinTableData(userLinData.valueType, datasetDescriptors)
    : [];

  return [
    ...descriptors,
    ...linData,
  ];
};

const parseCtlDipSwitchOnline = (
  controlType: DeviceModelStatus.UI.DIPSwitchBlockOnlineType,
  datasetDescriptors: DeviceModelStatus.DeviceModelDescriptorCollection,
): DeviceModelStatus.DeviceModelDescriptor[] => [
  datasetDescriptors[controlType.variable],
];

const parseCtlDipSwitchOffline = (
  controlType: DeviceModelStatus.UI.DIPSwitchBlockOfflineType,
  datasetDescriptors: DeviceModelStatus.DeviceModelDescriptorCollection,
): DeviceModelStatus.DeviceModelDescriptor[] => [
  datasetDescriptors[controlType.variable],
];

const parseCtlRangeWithSpan = (
  controlType: DeviceModelStatus.UI.RangeWithSpanControlType,
  datasetDescriptors: DeviceModelStatus.DeviceModelDescriptorCollection,
): DeviceModelStatus.DeviceModelDescriptor[] => [
  datasetDescriptors[controlType.rangeStart],
  datasetDescriptors[controlType.rangeEnd],
  datasetDescriptors[controlType.spanVar],
];

const parseSelectControl = (
  controlType: DeviceModelStatus.UI.SelectControlType,
  datasetDescriptors: DeviceModelStatus.DeviceModelDescriptorCollection,
  datasetValues: DeviceModelStatus.StatusValueCollection,
): DeviceModelStatus.DeviceModelDescriptor[] => {
  const selectValue = datasetValues[controlType.variable]?.value;
  const xcase = controlType.CASE.find((cs) => cs.value === selectValue);
  const sitems = parseMenuElements(xcase?.ITEMS ?? controlType.DEFAULT, datasetDescriptors, datasetValues);
  return sitems;
};

const parseCheckboxControl = (
  controlType: DeviceModelStatus.UI.CheckboxControlType,
  datasetDescriptors: DeviceModelStatus.DeviceModelDescriptorCollection,
): DeviceModelStatus.DeviceModelDescriptor[] => [
  datasetDescriptors[controlType.variable],
];

const parseControlMenuItem = (
  descriptor: DeviceModelStatus.ControlDescriptor,
  datasetDescriptors: DeviceModelStatus.DeviceModelDescriptorCollection,
  datasetValues: DeviceModelStatus.StatusValueCollection,
): DeviceModelStatus.DeviceModelDescriptor[] => {
  const { controlType } = descriptor;
  if (descriptor.visibility === false) {
    return [];
  }

  if (controlType.type === DeviceModelStatus.UI.ControlType.CTLSELECT) {
    const items = parseSelectControl(controlType, datasetDescriptors, datasetValues);
    return items;
  }
  if (controlType.type === DeviceModelStatus.UI.ControlType.CTLRANGEWITHSPAN) {
    const items = parseCtlRangeWithSpan(controlType, datasetDescriptors);
    return items;
  }
  if (controlType.type === DeviceModelStatus.UI.ControlType.CTLDIPSWITCHBLOCKOFFLINE) {
    const items = parseCtlDipSwitchOffline(controlType, datasetDescriptors);
    return items;
  }
  if (controlType.type === DeviceModelStatus.UI.ControlType.CTLDIPSWITCHBLOCKONLINE) {
    const items = parseCtlDipSwitchOnline(controlType, datasetDescriptors);
    return items;
  }
  if (controlType.type === DeviceModelStatus.UI.ControlType.CTLLINEARIZATION) {
    const items = parseCtlLinearization(controlType, datasetDescriptors);
    return items;
  }
  if (controlType.type === DeviceModelStatus.UI.ControlType.CTLCHECKBOX) {
    const items = parseCheckboxControl(controlType, datasetDescriptors);
    return items;
  }
  return [];
};

const parseMenuElements = (
  elements: DeviceModelStatus.StructureDescriptorElement[],
  datasetDescriptors: DeviceModelStatus.DeviceModelDescriptorCollection,
  datasetValues: DeviceModelStatus.StatusValueCollection,
): DeviceModelStatus.DeviceModelDescriptor[] => {
  const items = elements.reduce((acc, element) => {
    const itemdesc = datasetDescriptors[element.identRef];
    if (itemdesc.type === DeviceModelStatus.StatusType.StatusDescriptor) {
      return [
        ...acc,
        itemdesc,
      ];
    }
    if (itemdesc.type === DeviceModelStatus.StatusType.StructureDescriptor) {
      const mitems = parseMenuElements(itemdesc.elements, datasetDescriptors, datasetValues);
      return [
        ...acc,
        itemdesc,
        ...mitems,
      ];
    }
    if (itemdesc.type === DeviceModelStatus.StatusType.ControlDescriptor) {
      const mitems = parseControlMenuItem(itemdesc, datasetDescriptors, datasetValues);
      return [
        ...acc,
        itemdesc,
        ...mitems,
      ];
    }
    return acc;
  }, [] as DeviceModelStatus.DeviceModelDescriptor[]);
  return items;
};

// eslint-disable-next-line import/prefer-default-export
export const createReportDataset = (
  reportMenu: IdentRef,
  datasetDescriptors: DeviceModelStatus.DeviceModelDescriptorCollection,
  datasetValues: DeviceModelStatus.StatusValueCollection,
): DeviceModelStatus.DeviceModelDescriptorCollection => {
  const descriptor = datasetDescriptors[reportMenu];
  if (descriptor?.type !== DeviceModelStatus.StatusType.StructureDescriptor) {
    return {};
  }

  const items = parseMenuElements(descriptor.elements, datasetDescriptors, datasetValues);
  const descriptors = items
    .reduce((acc, item) => ({
      ...acc,
      [item.identRef]: item,
    }), {} as DeviceModelStatus.DeviceModelDescriptorCollection);

  const data = {
    [descriptor.identRef]: descriptor,
    ...descriptors,
  };
  console.log('createReportDataset', data);
  return data;
};
