/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
/* eslint-disable max-len */
import { IService } from '../Service';
import { createDecorator } from '../../instantiation/instantiation';

export interface ICommunicationRequest {
  requestId: string;
  actionId: string;
  payload: unknown;
}

export enum CommunicationErrorType {
  UnknownError = 0,
  ConnectionLost = 1,
  Abort = 2,
  Busy = 3,
  Timeout = 4,
  DeviceSpecific = 5,
  NotSupported = 6,
}

export interface ICommunicationError {
  errorType: CommunicationErrorType;
  errorMessage: string;
  payload?: unknown;
}

export interface ICommunicationResponse {
  requestId: string;
  actionId: string;
  payload: unknown;
}

export interface ICommunicationProgressEvent {
  eventId: string;
  actionId: string;
  progress: number;
}

export interface ICommunicationEvent {
  eventId: string;
  actionId: string;
  payload: unknown;
}

export const ICommunicationServiceDec = createDecorator<ICommunicationService>('CommunicationService');

// eslint-disable-next-line no-redeclare
export interface ICommunicationService extends IService {
  connect: (uniqueDeviceId: string) => Promise<boolean>;
  execute: (request: ICommunicationRequest, subject?: string) => Promise<ICommunicationResponse>;
  publish: (request: ICommunicationRequest, subject?: string) => Promise<ICommunicationResponse>;
  disconnect: () => Promise<void>;
}

export const encodeCommunicationActionId = (deviceInstanceId: string, actionId: string)
  : string => `${deviceInstanceId}|${actionId}`;

export const decodeCommunicationActionId = (actionId: string)
  : [string, string] => {
  const split = actionId.split('|');
  return [split?.[0] ?? '---', split?.[1] ?? '---'];
};
