/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import { WebDeviceEventBase } from './types';

export const WEBDEVICE__LOG_DATA_EVENT = 'WEBDEVICE__LOG_DATA_EVENT';

export interface WebDeviceLogDataEvent extends WebDeviceEventBase {
    kind: typeof WEBDEVICE__LOG_DATA_EVENT;
    message: string;
}
