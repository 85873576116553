/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

import { createDecorator } from '../../instantiation/instantiation';
import { IdentRef, RequestBase, ResponseBase } from '../common';
import { IService } from '../Service';
import { DeviceResponseValueRef, StatusValueRef } from './DeviceModelCoreService';

export const METHOD_EXECUTE__INIT = 'WEBDEVICE__METHOD_EXECUTE__INIT';

export const METHOD_EXECUTE__PASSWORD_REQUEST = 'WEBDEVICE__METHOD_EXECUTE__PASSWORD_REQUEST';
export const METHOD_EXECUTE__PASSWORD = 'WEBDEVICE__METHOD_EXECUTE__PASSWORD';

export const METHOD_EXECUTE__ACCEPT_DATA_REQUEST = 'WEBDEVICE__METHOD_EXECUTE__ACCEPT_DATA_REQUEST';
export const METHOD_EXECUTE__ACCEPT_DATA = 'WEBDEVICE__METHOD_EXECUTE__ACCEPT_DATA';

export const METHOD_EXECUTE__ABORT = 'WEBDEVICE__METHOD_EXECUTE__ABORT';

export const METHOD_EXECUTE__DONE__SUCCESS = 'WEBDEVICE__METHOD_EXECUTE__DONE__SUCCESS';
export const METHOD_EXECUTE__DONE__FAILED = 'WEBDEVICE__METHOD_EXECUTE__DONE__FAILED';
export const METHOD_EXECUTE__DONE__ABORTED = 'WEBDEVICE__METHOD_EXECUTE__DONE__ABORTED';

export const IDeviceModelExecutionService = createDecorator<IDeviceModelExecutionService>('DeviceModelExecutionService');

export const DeviceExecutionServiceDec = createDecorator<IDeviceModelExecutionService>('Method.DeviceModelExecutionService.Device');

export const SimulationExecutionServiceDec = createDecorator<IDeviceModelExecutionService>('Method.DeviceModelExecutionService.Simulation');

/**
 * Initial step in method sequence
 */
export interface MethodInit {
    type: typeof METHOD_EXECUTE__INIT;
    data?: StatusValueRef[];
}

export interface MethodDoneSuccess {
    type: typeof METHOD_EXECUTE__DONE__SUCCESS;
    data?: DeviceResponseValueRef[];
    // true: When method successfull, dataset
    suppressReinitDataset?: boolean;
}

export interface MethodDoneFailed {
    type: typeof METHOD_EXECUTE__DONE__FAILED;
    message: string;
    stack?: string;
}

export interface MethodDoneAborted {
    type: typeof METHOD_EXECUTE__DONE__ABORTED;
}

export interface MethodPassword {
    type: typeof METHOD_EXECUTE__PASSWORD;
    password?: string;
}

export interface MethodPasswordRequest {
    type: typeof METHOD_EXECUTE__PASSWORD_REQUEST;
    message?: string;
}

export interface MethodAcceptData {
    type: typeof METHOD_EXECUTE__ACCEPT_DATA;
    accept: boolean;
}

export interface MethodAcceptDatasetValue {
    identRef: IdentRef;
    label: string;
    value: string;
    unit?: string;
}

export interface MethodAcceptDataRequest {
    type: typeof METHOD_EXECUTE__ACCEPT_DATA_REQUEST;
    deviceInstance: string;
    data: MethodAcceptDatasetValue[];
}

export interface MethodAbort {
    type: typeof METHOD_EXECUTE__ABORT;
    deviceInstance: string;
}

export type MethodRequestPayload = MethodInit | MethodPassword | MethodAcceptData | MethodAbort;

export type MethodResponsePayload = MethodPasswordRequest
 | MethodAcceptDataRequest
 | MethodDoneSuccess
 | MethodDoneAborted
 | MethodDoneFailed;

export interface MethodHeader {
  [key: string]: string;
}

export interface MethodExecutionRequest extends RequestBase {
    methodIdent: IdentRef;
    actionId?: string;
    header?: MethodHeader;
    request: MethodRequestPayload;
}

export interface MethodExecutionResponse extends ResponseBase {
    methodIdent: IdentRef;
    actionId?: string;
    header?: MethodHeader;
    response: MethodResponsePayload;
}

export type typeMethodExecutionFunction = (method: MethodExecutionRequest)
    => Promise<MethodExecutionResponse>;

// eslint-disable-next-line no-redeclare
export interface IDeviceModelExecutionService extends IService {
    ExecuteMethod: typeMethodExecutionFunction;
}
