/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

import { IService } from '../Service';
import { DeviceModelDescriptorCollection, StatusValueCollection } from '../../devicemodel';
import { createDecorator } from '../../instantiation/instantiation';
import { DeviceInformation } from '../DeviceInformation';
import { DeviceI18nResource } from './DeviceModelResourcesService';

export const IDeviceInstanceManagerDec = createDecorator<DeviceInstanceManager>('DeviceInstanceManager');

export interface DeviceInstanceDataset {
  values: StatusValueCollection;
  descriptors: DeviceModelDescriptorCollection;
}

export interface DeviceInstanceMetadata {
  [key: string]: string;
}

export interface DeviceInstanceData {
  device: DeviceInformation;
  /**
   * WebWorker identifier (digfferent datasets can use on webworker for communication)
   */
  webworkerInstanceId: string;
  /**
   * This dataset must be updated when syncDatasetId is updated
   */
  syncWithInstanceId?: string;
  dataset: DeviceInstanceDataset;
  i18n: DeviceI18nResource;
  meta?: DeviceInstanceMetadata;
}

// eslint-disable-next-line no-redeclare
export interface DeviceInstanceManager extends IService {
  ServiceName: () => string;

  emitCreateInstanceEvent: (
    deviceInstanceId: string,
    data: DeviceInstanceData,
  ) => Promise<void>;

  emitRemoveInstanceEvent: (deviceInstanceId: string) => Promise<void>;

  emitSelectInstanceEvent: (deviceInstanceId?: string) => Promise<void>;
}
