/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

// eslint-disable-next-line import/prefer-default-export
export enum ControlType {
    CTLDYNVARIABLE = 'CTLDYNVARIABLE',
    CTLPLACEHOLDER = 'CTLPLACEHOLDER',
    CTLDYNTREND = 'CTLDYNTREND',
    CTLDYNLEDFLOAT = 'CTLDYNLEDFLOAT',
    CTLDYNLEDDISCRETE = 'CTLDYNLEDDISCRETE',
    CTLPICTURE = 'CTLPICTURE',
    CTLRANGE = 'CTLRANGE',
    CTLRANGEWITHSPAN = 'CTLRANGEWITHSPAN',
    CTLSLIDER = 'CTLSLIDER',
    CTLCHECKBOX = 'CTLCHECKBOX',
    CTLDATEPICKER = 'CTLDATEPICKER',
    CTLSELECT = 'CTLSELECT',
    CTLDEVICESTATUSLIST = 'CTLDEVICESTATUSLIST',
    CTLCURRENTSTATUSLIST = 'CTLCURRENTSTATUSLIST',
    CTLPAPER = 'CTLPAPER',
    CTLCURRENTSTATUS = 'CTLCURRENTSTATUS',
    CTLDEVICESTATUS = 'CTLDEVICESTATUS',
    CTLLINEARIZATION = 'CTLLINEARIZATION',
    CTLDEVICEVALUEPICKER = 'CTLDEVICEVALUEPICKER',
    CTLDIPSWITCHBLOCKONLINE = 'CTLDIPSWITCHBLOCKONLINE',
    CTLDIPSWITCHBLOCKOFFLINE = 'CTLDIPSWITCHBLOCKOFFLINE',
    CTLDIPSWITCHBLOCKLIVE = 'CTLDIPSWITCHBLOCKLIVE',
    CTLSTATUSMESSAGE = 'CTLSTATUSMESSAGE',
    CTLGRIDLAYOUT = 'CTLGRIDLAYOUT',
    CTLCHANNELSTATUS = 'CTLCHANNELSTATUS',
    CTLCHANNELSWITCHBLOCK ='CTLCHANNELSWITCHBLOCK',
    CTLLEDLINK = 'CTLLEDLINK',
    CTLIOLINKEVENTMONITOR = 'CTLIOLINKEVENTMONITOR',
    CTLIOLINKEVENTMONITORSTATUS = 'CTLIOLINKEVENTMONITORSTATUS',
    CTLIOLINKSYSTEMCOMMAND = 'CTLIOLINKSYSTEMCOMMAND',
    CTLIOLINKCOMMAND = 'CTLIOLINKCOMMAND',
    CTLTRIO3EVENTMONITOR = 'CTLTRIO3EVENTMONITOR',
    CTLTRIO3EVENTMONITORSTATUS = 'CTLTRIO3EVENTMONITORSTATUS',
    CTLTRIO3CHANNELSTATUS = 'CTLTRIO3CHANNELSTATUS',
    CTLCAPAROCCHANNELCURRENTEDITOR = 'CTLCAPAROCCHANNELCURRENTEDITOR',
    CTLPASSWORD = 'CTLPASSWORD',
    CTLCOCKPITCAPAROC = 'CTLCOCKPITCAPAROC',
    CTLONLINEMODULEEDITORCAPAROC = 'CTLONLINEMODULEEDITORCAPAROC',
    CTLANALOGGAUGE = 'CTLANALOGGAUGE',
    CTLQUINT4ANALOGSIGNAL = 'CTLQUINT4ANALOGSIGNAL',
    CTLCONTACTRON = 'CTLCONTACTRON',
    CTLSPORTWRITECOMMAND = 'CTLSPORTWRITECOMMAND',
    CTLDEVICESTATUSMONITOR = 'CTLDEVICESTATUSMONITOR',
    CTLDEVICESTATUSVARIABLEICON = 'CTLDEVICESTATUSVARIABLEICON',
    CTLDEVICESTATUSVARIABLEVALUE = 'CTLDEVICESTATUSVARIABLEVALUE',
    CTLONLINECAPAROC = 'CTLONLINECAPAROC',
    CTLDEVICESPECIFIC = 'CTLDEVICESPECIFIC',
    CTLSPORTSPECIFIC = 'CTLSPORTSPECIFIC',
    CTLTHUMBWHEEL = 'CTLTHUMBWHEEL',
    CTLCUSTOMCONTROL = 'CTLCUSTOMCONTROL',
}
