/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

import { createDecorator } from '../../instantiation/instantiation';
import { IService } from '../Service';

export const IDeviceModelLoggerService = createDecorator<IDeviceModelLoggerService>('IDeviceModelLoggerService');

export enum LogLevel {
  off = 0,
  fatal = 1,
  error = 2,
  warn = 3,
  info = 4,
  debug = 5,
  trace = 6,
}

// eslint-disable-next-line no-redeclare
export interface IDeviceModelLoggerService extends IService {
  fatal: (...args: any[]) => void;
  error: (...args: any[]) => void;
  warn: (...args: any[]) => void;
  info: (...args: any[]) => void;
  debug: (...args: any[]) => void;
  trace: (...args: any[]) => void;
  setLogLevel: (level: LogLevel) => void;
}
