/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER device parametrization tool
 * Component: DeviceModelServer
 *
 **************************************************************************** */
import { DeviceModelStatus } from '../../devicemodel';
import { createDecorator } from '../../instantiation/instantiation';
import { IService } from '../Service';
import { StatusValueRef, DeviceResponseValueRef } from './DeviceModelCoreService';

export const IDeviceModelDatasetService = createDecorator<IDeviceModelDatasetService>('DeviceModelDatasetService');

// eslint-disable-next-line no-redeclare
export interface IDeviceModelDatasetService extends IService {
    ServiceName: () => string;
    WriteValues: (statusValue: StatusValueRef[], blackListActions: string[]) => Promise<void>;

    WriteUploadValues: (inValues: DeviceResponseValueRef[]) => Promise<void>;
    /** Load values from external source (file) and write it in existing dataset */
    WriteDataset: (inValues: DeviceResponseValueRef[]) => Promise<void>;

    WriteDeviceValues: (statusValue: DeviceResponseValueRef[]) => Promise<void>;

    GetStatus: () => Promise<DeviceModelStatus>;

    GetDeviceModelDownloadStatus: () => Promise<DeviceModelStatus>;

    ReinitializeDataset: () => Promise<void>;
}
