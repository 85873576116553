/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 *************************************************************************** */
import { MethodIdent } from '../../../devicemodel';

export const PROGRESS_EVENT = 'progress.event';

export interface DeviceModelMethodProgressEvent {
  type: typeof PROGRESS_EVENT;
  eventId: string;
  actionId: string;
  deviceInstanceId: string;
  progress: number;
}

// eslint-disable-next-line max-len
export const createMethodProgressEvent = (eventId: string, deviceInstanceId: string, actionId: MethodIdent, progress: number)
: DeviceModelMethodProgressEvent => ({
  type: PROGRESS_EVENT,
  eventId,
  actionId,
  deviceInstanceId,
  progress,
});
