import { ChangeDeviceType } from './changeDevice';
import { ContactronStationCommissioningType } from './contactron/CommissioningWizard';
import { CreateParameterFileType } from './createParameterFile';
import { CxeEditParameterFileType } from './cxeEditParameterFile';
import { CxeFirmwareParameterType } from './cxeFirmwareParameter';
import { CxeHardwareSwitchType } from './cxeHardwareSwitch';
import { DeviceSettingsType } from './deviceSettings';
import { InitialDeviceStartupType } from './initialDeviceStartup';
import { RestoreDeviceType } from './restoreDevice';
import { TransferParameterFileType } from './transferParameterFile';
import { WizardType } from './wizardTypes';

export * as ContactronStation from './contactron/CommissioningWizard';

export type WizardDescriptorType = InitialDeviceStartupType
  | ChangeDeviceType
  | CreateParameterFileType
  | RestoreDeviceType
  | TransferParameterFileType
  | CxeEditParameterFileType
  | DeviceSettingsType
  | CxeFirmwareParameterType
  | CxeHardwareSwitchType
  | ContactronStationCommissioningType;

export {
  WizardType,
};

export type {
  InitialDeviceStartupType,
  ChangeDeviceType,
  CreateParameterFileType,
  RestoreDeviceType,
  TransferParameterFileType,
  CxeEditParameterFileType,
  DeviceSettingsType,
  CxeFirmwareParameterType,
  CxeHardwareSwitchType,
  ContactronStationCommissioningType,
};
