import i18nQUINT4PS from './QUINT4_PS';
import i18nTRIO3PS from './TRIO3_PS';
import i18nCONTACTRON from './CONTACTRON';
import i18nCAPAROC from './CAPAROC';
import i18nMINIMCR from './MINI_MCR';
import i18nPSRUNI from './PSR_UNI';
import i18nECMUSI from './ECM_UC';
import i18nMACXMCRAP from './MACX_MCR_AP';

const deviceDictionaries = [
  i18nQUINT4PS,
  i18nTRIO3PS,
  i18nCONTACTRON,
  i18nMINIMCR,
  i18nCAPAROC,
  i18nPSRUNI,
  i18nECMUSI,
  i18nMACXMCRAP,
];

export default deviceDictionaries;
