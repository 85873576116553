/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import { IdentRef } from '../services/common';
import { DeviceTimelineData } from '../services/DeviceModelServer';
import { WebDeviceEventBase, WebDeviceRequestBase, WebDeviceResponseBase } from './types';

export const WEBDEVICE__TRENDDATA_REQUEST = 'WEBDEVICE__TRENDDATA_REQUEST';
export const WEBDEVICE__TRENDDATA_RESPONSE = 'WEBDEVICE__TRENDDATA_RESPONSE';
export const WEBDEVICE__TRENDDATA_EVENT = 'WEBDEVICE__TRENDDATA_EVENT';

export interface WebDeviceTrendValues {
  [key: string]: DeviceTimelineData;
}

export interface WebDeviceTrendDataRequest extends WebDeviceRequestBase {
  kind: typeof WEBDEVICE__TRENDDATA_REQUEST;
  variables: IdentRef[];
}

export interface WebDeviceTrendDataResponse extends WebDeviceResponseBase {
  kind: typeof WEBDEVICE__TRENDDATA_RESPONSE;
  trendValues: WebDeviceTrendValues;
}

export interface WebDeviceTrendDataEvent extends WebDeviceEventBase {
  kind: typeof WEBDEVICE__TRENDDATA_EVENT;
  trendValues: WebDeviceTrendValues;
}
