/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import { CustomObjectValue, DeviceModelChanges } from '../devicemodel';
import { DeviceInformation } from '../services/DeviceInformation';
import { ConnectionStatus } from '../services/DeviceModelServer/ConnectionService';
import { WebDeviceEventBase } from './types';

export const WEBDEVICE__UPDATE_DATA_EVENT = 'WEBDEVICE__UPDATE_DATA_EVENT';
export const WEBDEVICE__UPDATE_CUSTOM_OBJECT_EVENT = 'WEBDEVICE__UPDATE_CUSTOM_OBJECT_EVENT';
export const WEBDEVICE__CONNECTION_STATUS_EVENT = 'WEBDEVICE__CONNECTION_STATUS_EVENT';
export const WEBDEVICE__ACTION_PROGRESS_EVENT = 'WEBDEVICE__ACTION_PROGRESS_EVENT';
export const WEBDEVICE__ACTION_EVENT = 'WEBDEVICE__ACTION_EVENT';

export interface WebDeviceUpdateDataEvent extends WebDeviceEventBase {
    kind: typeof WEBDEVICE__UPDATE_DATA_EVENT;
    targetDataset: string;
    data: DeviceModelChanges[];
}

export interface WebDeviceConnectionStatusEvent extends WebDeviceEventBase {
    kind: typeof WEBDEVICE__CONNECTION_STATUS_EVENT;
    device: DeviceInformation;
    status: ConnectionStatus;
}

export interface WebDeviceUpdateCustomObjectEvent extends WebDeviceEventBase {
    kind: typeof WEBDEVICE__UPDATE_CUSTOM_OBJECT_EVENT;
    object: CustomObjectValue;
}

export interface WebDeviceActionProgressEvent extends WebDeviceEventBase {
    kind: typeof WEBDEVICE__ACTION_PROGRESS_EVENT;
    deviceInstanceId: string;
    actionId: string;
    progress: number;
    text?: string;
}

export interface WebDeviceActionEvent extends WebDeviceEventBase {
    kind: typeof WEBDEVICE__ACTION_EVENT;
    deviceInstanceId: string;
    actionId: string;
    payload: unknown;
}
