/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { useContextDatasetStatusDescriptor, useContextDatasetStatusValue } from '../../../../hooks/useContextDataset';
import CheckboxEditorControl from '../../../../components/Basic/CheckboxEditorControl/CheckboxEditorControl';
import { UnsupportedControl } from '../../../../components/UnsupportedControl';
import { writeActiveDeviceVariableValues } from '../../../../store';
import { DatasetContext } from '../../../../views/DatasetContext';
import { ReduxControlProps } from '../../../ReduxControlProps';
import useStatusValueStateUpdate from '../../hooks/useStatusValueStateUpdate';

export interface Quint4EventCheckboxProps extends ReduxControlProps {
  onShowContentHelp?: () => void;
  onHideContentHelp?: () => void;
}

const Quint4EventCheckbox: React.FC<Quint4EventCheckboxProps> = (props: Quint4EventCheckboxProps):
  React.ReactElement => {
  const {
    identRef, onShowContentHelp, onHideContentHelp, readonly, onStateChanged,
    deviceInstanceId,
  } = props;

  const dispatch = useDispatch();
  const { targetDataset } = useContext(DatasetContext);

  const statusDescriptor = useContextDatasetStatusDescriptor(deviceInstanceId, identRef, targetDataset);
  const statusValue = useContextDatasetStatusValue(deviceInstanceId, identRef, targetDataset);
  const visibility = useStatusValueStateUpdate(identRef, deviceInstanceId, targetDataset, onStateChanged);

  if (!visibility) {
    return <></>;
  }

  if (statusDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text={`Quint4EventCheckbox: Unsupported type ${statusDescriptor?.type ?? 'undefined'}`} />;
  }

  const { valueType } = statusDescriptor;
  if (valueType.type !== DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR) {
    return <UnsupportedControl text={`Quint4EventCheckbox: Unsupported valueType ${valueType.type ?? 'undefined'}`} />;
  }

  const { label, help, handling } = statusDescriptor;

  return (
    <CheckboxEditorControl
      id={identRef}
      value={Boolean(statusValue?.value ?? false)}
      label={t<string>(label)}
      help={t<string>(help)}
      edited={statusValue?.modified}
      showLabel
      onShowContentHelp={onShowContentHelp}
      onHideContentHelp={onHideContentHelp}
      customClassName="col"
      onChange={(originalValue: boolean) => {
        if (statusValue === undefined) {
          return;
        }

        let newValue = 0;
        if (originalValue) {
          const enumItem = valueType.enumerator.find((entry) => entry.value !== 0);
          newValue = enumItem ? enumItem.value : 0;
        }

        dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
          value: newValue,
          backupValue: statusValue.backupValue,
          identRef,
        }]));
      }}
      readonly={readonly ?? (statusValue === undefined || handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY)}
    />
  );
};

export default Quint4EventCheckbox;
