/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

import { IdentRef } from '../services/common';
import { StatusType } from './deviceModelCommons';

export interface StatusVector {
    statusValue: StatusValueCollection;
    changeCounter: number;
}

export interface StatusValueCollection {
    [key: string]: StatusValue;
}

export enum StatusValueValidity {
  valid = 0,
  outOfRange = 1,
  invalidDataFormat = 2,
}

export interface StatusValue {
    identRef: IdentRef;
    type: typeof StatusType.StatusValue;
    value: any;
    valueValidity: StatusValueValidity;
    valueValidityDescription?: string;
    backupValue?: any;
    errorCode: ErrorCodeType;
    modified: boolean;
    timestamp: string;
    changeCounter: number;
}

export type ErrorCodeType = number;
