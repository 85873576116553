/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

/* eslint-disable no-redeclare */
/* eslint-disable max-len */
import { IService } from '../Service';
import { createDecorator } from '../../instantiation/instantiation';
import {
  DeviceModelDescriptorCollection,
  DeviceModelFunctionCollection, StatusValueCollection,
} from '../../devicemodel';

// eslint-disable-next-line no-redeclare
export const IDeviceModelDataProviderService = createDecorator<IDeviceModelDataProviderService>('DeviceModelDataProviderService');

export interface IDeviceModelDataProviderService extends IService {
  ServiceName: () => string;

  GetStatusValues: () => Promise<StatusValueCollection>;

  GetDescriptors: () => Promise<DeviceModelDescriptorCollection>;

  GetModificators: () => Promise<DeviceModelFunctionCollection>;
}
