/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { DeviceInformation, WebDevice } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../store/reduxStore';
import { WizardViewContext } from '../../WizardViewContext';
import { DeviceListItem } from '../../../components/WizardDeviceList/WizardDeviceList';
import { ExecutionState } from '../../../store/common';
import { ButtonState, ButtonRole } from '../../../store/deviceWizard/types';
import { PageSelectDeviceComponent } from '../../../PageComponents';
import { WizardViewPageProps } from '../../WizardView/WizardViewPage';
import { deviceWizardUpdateButtons, instantiateDevice, setActiveDeviceExecutionStatus } from '../../../store';
import { deviceInstanceActiveDeviceState } from '../../../store/deviceInstances/store/activeDevice/selectors';
import discoveryListSelector from '../../../views/StartupViewControl/selectors/discoveryListSelector';
import { DeviceCardListItemDeviceKnown } from '../../../components/DeviceCardList/types';
import './PageSelectActiveDevice.scss';

export interface ActiveDeviceListFilter {
  deviceDriverId: string;
}

export interface PageSelectActiveDeviceProps extends WizardViewPageProps {
  /** Hint text 1st line */
  hintTextLine1?: string;
  /** Hint text 2nd line */
  hintTextLine2?: string;
  /** Device list title */
  deviceListTitle?: string;
  // eslint-disable-next-line no-unused-vars
  filter?: (device: DeviceInformation) => boolean;
  /** Show if beck button must be disabled */
  first?: boolean;
  /** Show device not supported, when serialNo is 0 */
  notSupport0Serial?: boolean;
  /** Supported wizard */
  supportedWizard: string;
  /** Device instance to be instantiated */
  deviceInstanceId: string;
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const defautFilter = () => (device: DeviceInformation) => true;

const PageSelectActiveDevice: React.FC<PageSelectActiveDeviceProps> = (props: PageSelectActiveDeviceProps): React.ReactElement => {
  const {
    deviceListTitle, filter, first, hintTextLine1, hintTextLine2,
    active, notSupport0Serial, supportedWizard,
    deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const { changeWizardPage } = useContext(WizardViewContext);
  const dispatch = useDispatch();

  const [selectedDeviceBusy, setSelectedDeviceBusy] = useState<boolean>(false);
  const [selectedDeviceSerialNum, setSelectedDeviceSerialNum] = useState<string>('');

  const activeDeviceList = useTypedSelector((state) => discoveryListSelector(state.discoveryService, state.deviceSimulation)
    .reduce((acc, item) => (item.type === 'DEVICE_CARD_LIST_ITEM__DEVICE__KNOWN'
        && item.device.catalog.properties.communication === WebDevice.CommunicationType.enabled
        && item.device.catalog.properties.supportedWizard.includes(supportedWizard) === true ? [
        ...acc,
        item,
      ] : acc), [] as DeviceCardListItemDeviceKnown[]));
  const activeDeviceInstantiationState = useTypedSelector((state) => deviceInstanceActiveDeviceState(state.deviceInstances, deviceInstanceId));

  const filterFn = (filter === undefined) ? defautFilter : filter;

  const wizardDeviceList: DeviceListItem[] = activeDeviceList
    .filter((item) => filterFn(item.device))
    .map((item) => {
      let busy = false;
      if (selectedDeviceSerialNum !== '') {
        // unselected DeviceCards are brighter; selected DeviceCard is displayed "normally"
        busy = selectedDeviceSerialNum === item.device.instance?.serialNumber ? selectedDeviceBusy : false;
      }
      const supported = notSupport0Serial ? item.device.instance?.serialNumber.trim() !== '0' : true;
      return {
        device: item.device,
        selectable: supported,
        busy,
        supported,
      };
    });

  useEffect(() => {
    dispatch(setActiveDeviceExecutionStatus(deviceInstanceId, ExecutionState.init));
  }, []);

  useEffect(() => {
    if (active !== true) {
      return;
    }
    if (activeDeviceInstantiationState === ExecutionState.success) {
      setSelectedDeviceBusy(false);
    }
  }, [activeDeviceInstantiationState, active]);

  useEffect(() => {
    if (active !== true) {
      return;
    }

    const instantiationSucceeded = (selectedDeviceSerialNum !== '')
      && (activeDeviceInstantiationState === ExecutionState.success);

    dispatch(deviceWizardUpdateButtons({
      source: 'PageSelectActiveDevice',
      abort: ButtonState.enabled,
      back: first === true ? ButtonState.disabled : ButtonState.enabled,
      next: instantiationSucceeded ? ButtonState.enabled : ButtonState.disabled,
      complete: ButtonState.hidden,
      pcta: instantiationSucceeded ? ButtonRole.next : ButtonRole.complete,
      focusPcta: instantiationSucceeded ? ButtonRole.next : ButtonRole.complete,
    }));

    if (instantiationSucceeded) {
      // automatically continue to next wizard page
      changeWizardPage(ButtonRole.next);
      setSelectedDeviceBusy(false);
      setSelectedDeviceSerialNum('');
    }
  }, [activeDeviceInstantiationState, selectedDeviceSerialNum, active]);

  return (
    <Container fluid className="wiz-page-select-device w-100 h-100 p-0 pt-4">
      <Row className="wiz-page-select-device__text_container w-100 m-0">
        <Col className="d-flex flex-column justify-content-start align-items-start w-100 p-0">
          <div className="wiz-page-select-device__text_container__line">{hintTextLine1 ?? t<string>('WIZARD_PAGE__SELECT_ACTIVE_DEVICE__HINT_LINE1')}</div>
          <div className="wiz-page-select-device__text_container__line">{hintTextLine2 ?? t<string>('WIZARD_PAGE__SELECT_ACTIVE_DEVICE__HINT_LINE2')}</div>
        </Col>
      </Row>
      <Row className="wiz-page-select-device__select_device w-100 p-0 m-0">
        <Col className="d-flex flex-column justify-content-start align-items-start w-100 p-0">
          <PageSelectDeviceComponent
            caption={deviceListTitle ?? ''}
            deviceList={wizardDeviceList}
            onSelectDevice={async (device): Promise<void> => {
              // Prevent click on Card when device instantiating
              if (activeDeviceInstantiationState === ExecutionState.pending) {
                return;
              }
              if (device.instance !== undefined) {
                setSelectedDeviceBusy(true);
                dispatch(deviceWizardUpdateButtons({
                  source: 'PageSelectActiveDevice(2)',
                  abort: ButtonState.disabled,
                  back: first === true ? ButtonState.disabled : ButtonState.enabled,
                  next: ButtonState.disabled,
                  complete: ButtonState.hidden,
                  pcta: ButtonRole.complete,
                  focusPcta: undefined,
                }));
                setSelectedDeviceSerialNum(device.instance.serialNumber);
                dispatch(instantiateDevice({
                  deviceInfo: device,
                  targetInstance: deviceInstanceId,
                  wizardMode: true,
                }));
              }
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PageSelectActiveDevice;
