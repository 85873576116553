/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren } from 'react';
import {
  ParameterValueColumns,
} from '../../ParameterEditor/ParameterControlColumnCount';
import './MenuItemRowItem.scss';

export interface MenuItemRowItemProps extends PropsWithChildren<unknown> {
  width?: number;
  customClassName?: string;
}

export const MenuItemRowItem:
React.FC<MenuItemRowItemProps> = (props: MenuItemRowItemProps): React.ReactElement => {
  const {
    children,
    width,
    customClassName,
  } = props;

  const collwidth = width === undefined ? ParameterValueColumns : width;
  const className = customClassName === undefined ? `col-${collwidth} h-100 menu-item-row-item m-0 p-0 px-1` : `col-${collwidth} ${customClassName}`;
  return (
    <div className={className}>
      {children}
    </div>
  );
};
