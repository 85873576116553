/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-param-reassign, no-return-assign */

import React, { useEffect } from 'react';
import AppSystemService from './services/AppService/AppSystemService';
import { openBackstage } from './components/Backstage';
import IconPxcSymbol from './assets/icons/icon-titlebar-pxc-p-symbol.svg';
import IconDropdownArrow from './assets/icons/icon-titlebar-dropdown-arrow.svg';
import IconWindowMinimize from './assets/icons/icon-titlebar-win-minimize.svg';
import IconWindowMaximize from './assets/icons/icon-titlebar-win-maximize.svg';
import IconWindowRestore from './assets/icons/icon-titlebar-win-restore.svg';
import IconWindowClose from './assets/icons/icon-titlebar-win-close.svg';
import { compensateZooming } from './helpers/functions';
import { getAppUiLanguageCode, setAppUiLanguageCode } from './i18n-config';
import useAppTitleVersion from './hooks/useAppTitleVersion';
import ZoomControl from './components/ZoomControl/ZoomControl';
import './App.css';

const AppTitlebar: React.FC = (): React.ReactElement => {
  let zoomDisplayTimeout: number | undefined;
  const appTitleVersion = useAppTitleVersion('clipx ENGINEER device parameterization');

  useEffect(() => {
    const onWindowMaximized = (isMaximized: boolean) => {
      const element = document.querySelector('.AppTitlebar');
      if (isMaximized) element?.classList.add('maximized');
      else element?.classList.remove('maximized');
    };

    // subscribe to custom Electron events for application window related activities
    const unsubscribeOnMaximized = AppSystemService.onMainWindowMaximized(onWindowMaximized);

    return () => { unsubscribeOnMaximized(); };
  }, []);

  useEffect(() => {
    const onWindowFocused = (hasFocus: boolean) => {
      const element = document.querySelector('.AppTitlebar');
      if (hasFocus) element?.classList.add('focused');
      else element?.classList.remove('focused');
    };

    const unsubscribeOnFocused = AppSystemService.onMainWindowFocused(onWindowFocused);

    return () => { unsubscribeOnFocused(); };
  }, []);

  useEffect(() => {
    /**
     * Displays an indicator for the current zoom level, expressed in percent,
     * and starts a timer to hide the indicator after a short amount of time.
     */
    const showZoomDisplay = (zoomFactor: number) => {
      const zoomDisp = document.querySelector('.AppZoomDisplay') as HTMLDivElement;
      zoomDisp.innerText = `Zoom: ${Math.round(zoomFactor * 100)}%`;
      zoomDisp.style.display = 'block';
      window.clearTimeout(zoomDisplayTimeout);
      zoomDisplayTimeout = window.setTimeout(() => {
        zoomDisplayTimeout = undefined;
        zoomDisp.style.display = 'none';
      }, 1000);
    };

    /**
     * Handles zooming of the app when mouse wheel is used while [Ctrl] key is pressed.
     */
    const handleMouseZooming = async (event: WheelEvent) => {
      if (event.ctrlKey) {
        event.preventDefault(); // prevent standard zooming behavior of the browser
        const dir = event.deltaY > 0 ? 'out' : 'in';
        const step = 0.1;
        const zoomFactor = await AppSystemService.MainViewZoom(dir, step);
        compensateZooming(zoomFactor);
        showZoomDisplay(zoomFactor);
      }
    };

    /**
     * Handles zooming of the app with [+], [-] and [0] keys while [Ctrl] key is pressed.
     */
    const handleKeyboardZooming = async (event: KeyboardEvent) => {
      if (event.ctrlKey) {
        let zoomFactor: number | undefined;
        switch (event.key) {
          case '0': zoomFactor = await AppSystemService.MainViewZoom('set'); break;
          case '+': zoomFactor = await AppSystemService.MainViewZoom('in'); break;
          case '-': zoomFactor = await AppSystemService.MainViewZoom('out'); break;
          default:
        }
        if (zoomFactor !== undefined) {
          compensateZooming(zoomFactor);
          showZoomDisplay(zoomFactor);
        }
      }
    };

    /**
     * Enables or disables displaying a border around the application window.
     */
    const toggleWindowBorder = () => {
      const appRoot = document.querySelector('.AppRoot') as HTMLDivElement;
      if (appRoot) {
        appRoot.classList.toggle('AppRootBorder');

        if (!appRoot.classList.contains('AppRootBorder')) {
          // ensure that no border is displayed also when app is zoomed
          // by removing explicit "border-width" from "AppRoot" element
          appRoot.style.borderWidth = '';
        }

        // trigger "compensateZooming" function to adjust border width
        // and vertical spacing of application — dependent from current
        // zoom factor and AppRootBorder (visibility of border) state
        AppSystemService.MainViewZoom('in', 0).then((zoomFactor) => {
          if (zoomFactor !== undefined) {
            compensateZooming(zoomFactor);
          }
        });
      }
    };

    /**
     * Changes the language which is used to display the application user interface
     * by cycling through supported languages and reloading currently displayed view.
     */
    const cycleThroughLanguages = () => {
      const languages = ['cimode', 'en', 'de'];
      // get current language code and change to next one
      const prevLanguage = getAppUiLanguageCode();
      let languageIdx = languages.indexOf(prevLanguage);
      languageIdx = (languageIdx + 1) % languages.length;
      const nextLanguage = languages[languageIdx];
      setAppUiLanguageCode(nextLanguage);
    };

    /**
     * Changes the currently displayed view (by according React route)
     * to the "home" location which is "StartupView" (routed via '/').
     */
    const displayHomeStartupView = () => {
      const { hash } = window.location;
      // eslint-disable-next-line no-alert
      alert(`Switching to home view:\n${hash} → ${'/'}`);

      // Change currently displayed page fragment by updating "hash" of location.
      // Note: This won't trigger reloading page, but triggers React HashRouter.
      window.location.hash = '/';
    };

    /**
     * Handles special app commands triggered by keyboard shortcuts with [Ctrl] + [Alt] keys.
     */
    const handleKeyboardAppCommand = async (event: KeyboardEvent) => {
      if (event.ctrlKey && event.altKey) {
        switch (event.code) {
          case 'KeyB': toggleWindowBorder(); break;
          case 'KeyL': // note: on some laptops [Ctrl+Alt+L] locks the PC
          case 'BracketLeft': // "[" on US keyboard, "ü" on DE keyboard
            cycleThroughLanguages(); break;
          case 'KeyH': displayHomeStartupView(); break;
          case 'KeyF': await AppSystemService.TriggerAppCommand('fullscreen'); break;
          case 'KeyR': await AppSystemService.TriggerAppCommand('reload'); break;
          case 'KeyX': await AppSystemService.TriggerAppCommand('exit'); break;
          case 'Quote': // "'" on US keyboard, "ä" on DE keyboard
          case 'KeyD': await AppSystemService.TriggerAppCommand('devtools'); break;
          default:
        }
      }
    };

    /**
     * Performs a dummy zoom factor adjustment without changing its value.
     * → Zoom factor is saved by Electron, but not restored consistently.
     * → After app restart, zoom is only applied when window is maximized.
     */
    const triggerZooming = async () => {
      // Trigger zooming (to set Electron.BrowserWindow.webContents.zoomFactor)
      // and use the saved zoom factor for calling "compensateZooming" function
      // to display correctly sized application titlebar, vertical spacing and
      // window border (if enabled).
      let zoomFactor = await AppSystemService.MainViewZoom('in');
      zoomFactor = await AppSystemService.MainViewZoom('out');
      if (zoomFactor !== undefined) {
        compensateZooming(zoomFactor);
      }
    };

    triggerZooming();

    const onWheel = async (event: WheelEvent) => {
      await handleMouseZooming(event);
    };

    const onKeydown = async (event: KeyboardEvent) => {
      await handleKeyboardZooming(event);
      await handleKeyboardAppCommand(event);
    };

    // subscribe to Web API events for mouse wheel and keyboard input
    document.addEventListener('wheel', onWheel);
    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('wheel', onWheel);
      document.removeEventListener('keydown', onKeydown);
    };
  }, []);

  return (
    <>
      <div className="AppTitlebar focused" style={{ backgroundImage: appTitleVersion }}>
        <div
          id="AppTitlebarLogo"
          className="AppTitlebarLogo d-flex align-items-center"
          role="button"
          onClick={() => { openBackstage(); }}
          onKeyDown={() => 0}
          tabIndex={0}
        >
          <img
            src={IconPxcSymbol}
            alt="Phoenix Contact"
            width={20}
            height={20}
          />
          <div style={{ marginLeft: '6px' }}>
            <img src={IconDropdownArrow} alt="" height={5.5} width={10} />
          </div>
        </div>
        <div className="AppTitlebarZoom">
          <ZoomControl />
        </div>
        <div className="AppTitlebarButtons">
          <button
            type="button"
            onClick={() => { AppSystemService.TriggerAppCommand('minimize'); }}
          >
            <img
              src={IconWindowMinimize}
              alt="minimize window"
              width={10}
              height={2}
            />
          </button>
          <button
            type="button"
            onClick={() => { AppSystemService.TriggerAppCommand('maximize'); }}
          >
            <img
              id="AppTitlebarIconWindowMaximize"
              src={IconWindowMaximize}
              alt="maximize window"
              width={10}
              height={10}
            />
            <img
              id="AppTitlebarIconWindowRestore"
              src={IconWindowRestore}
              alt="restore window"
              width={10}
              height={10}
            />
          </button>
          <button
            type="button"
            onClick={() => { AppSystemService.TriggerAppCommand('exit'); }}
          >
            <img
              src={IconWindowClose}
              alt="close window"
              width={10}
              height={10}
            />
          </button>
        </div>
      </div>
      <div className="AppTitlebarTop" />
      <div className="AppZoomDisplayContainer">
        <div className="AppZoomDisplay" />
      </div>
    </>
  );
};

export default AppTitlebar;
