/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import { DeviceModelDescriptorCollection } from '../devicemodel/deviceModelDescriptorCollection';
import { StatusValueCollection } from '../devicemodel/deviceModelStatusVector';
import { DeviceInformation } from '../services/DeviceInformation';
import { DeviceI18nResource } from '../services/DeviceModel/DeviceModelResourcesService';
import { WebDeviceRequestBase, WebDeviceResponseBase } from './types';

export const WEBDEVICE__INIT_REQUEST = 'WEBDEVICE__INIT_REQUEST';
export const WEBDEVICE__INIT_RESPONSE = 'WEBDEVICE__INIT_RESPONSE';

export const WEBDEVICE__DISPOSE_REQUEST = 'WEBDEVICE__DISPOSE_REQUEST';
export const WEBDEVICE__DISPOSE_RESPONSE = 'WEBDEVICE__DISPOSE_RESPONSE';

export interface WorkerDeviceDataset {
  values: StatusValueCollection;
  descriptors: DeviceModelDescriptorCollection;
}

export enum WorkerDeviceCommunicationMode {
  /** Connected with real device */
  Device,
  /** Connected with simulated device */
  Simulation,
  /** No connection with device - offline */
  NoDevice,
}

export interface WebDeviceInitRequest extends WebDeviceRequestBase {
    kind: typeof WEBDEVICE__INIT_REQUEST;
    /** Instantiate device with communication service (true) or without (false) */
    communicationMode: WorkerDeviceCommunicationMode,
    /** Target dataset identification for events */
    datasetId: string;
    /** Device information to be instantiated */
    deviceUniqueId: string;
    /** Device information to be instantiated */
    device: DeviceInformation;
    /** Host for communication */
    host: string;
    /** Port for communication */
    port: number;
    /** Contains connnection information for child communcation */
    connectionString: string,
    /** Show wizard used (ex: no dynamic data) */
    wizardMode: boolean;
  }

export interface WebDeviceInitResponse extends WebDeviceResponseBase {
  kind: typeof WEBDEVICE__INIT_RESPONSE;
  device: DeviceInformation;
  dataset: WorkerDeviceDataset;
  i18n: DeviceI18nResource;
}

export interface WebDeviceDisposeRequest extends WebDeviceRequestBase {
  kind: typeof WEBDEVICE__DISPOSE_REQUEST;
}

export interface WebDeviceDisposeResponse extends WebDeviceResponseBase {
  kind: typeof WEBDEVICE__DISPOSE_RESPONSE;
}
