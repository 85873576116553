/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import { IdentRef } from '../services/common';
import { StatusType } from './deviceModelCommons';

export interface SimulationVariableRange {
    CMIN: number;
    CMAX: number;
}

export enum SimulationVariableType {
    LINEAR = 'LINEAR',
    CONSTANT = 'CONSTANT',
}

export interface SimulationLinearVariable {
    type: typeof SimulationVariableType.LINEAR;
    range: SimulationVariableRange;
    step: number;
    interval: number;
}

export interface SimulationConstantVariable {
    type: typeof SimulationVariableType.CONSTANT;
    value: unknown;
}

export type SimulationVariable = SimulationLinearVariable | SimulationConstantVariable;

export interface SimulationVariableCollection {
    [key: string]: SimulationVariable;
}

export interface SimulationDescriptor {
    type: typeof StatusType.SimulationDescriptor;
    identRef: IdentRef;
    variables: SimulationVariableCollection;
}
