/* eslint-disable max-len */

import { DeviceDriverId } from '../services/common';

/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
export type DeviceCatalogIdent = string;

export interface DeviceCatalogFilename {
  version: string;
  buildDate: string;
  name: string;
}

export enum CommunicationType {
  enabled = 'enabled',
  disabled = 'disabled',
}

export enum Species {
  device = 'device',
  station = 'station',
}

export enum SimulationType {
  enabled = 'enabled',
  disabled = 'disabled',
}

export interface DeviceProperties {
  simulation: SimulationType,
  communication: CommunicationType,
  supportedWizard: string[],
}

export interface Devicei18nProperties {
  family: string;
}

/**
 * Type containing information about a connected device,
 * as detected by scanning communication interface topology
 * and optionally extended with information of device driver
 * (when matching is available, i.e. product URL and image).
 */
export interface DeviceCatalogInformation {
    /**
     * Device catalog title
     * For S-Port, NFC: deviceTypeName (productOrderNumber) - Cfg. Vers. ####
     * MINI MCR-2-RTD-UI-PT-C (2902051) - Cfg. Vers. 0020
     */
     deviceCatalogTitle: string;

     /**
     * Unique catalog device identifier.
     * {protocol}-{vendorID:X2}-{groupID:X2}-{familyID:X2}-{deviceID:X2}-{cfgVersion:X4}-{productOrder}
     */
    deviceCatalogIdent: DeviceCatalogIdent;

    /**
     * Unique device driver identifier, derived from device type identification information.
     * {vendorID:X2}-{groupID:X2}-{familyID:X2}-{deviceID:X2}-{cfgVersion:X4}-{orderCode}
     */
    deviceDriverId: DeviceDriverId;

    /**
     * Human readable protocol information.
     */
    protocol: string;

    /**
     * Product name of device, i.e. "MINI MCR-2-RTD-UI-PT-C".
     */
    deviceTypeName: string;

    /**
     * Profile name, i.e. "MINI MCR-2-RTD-UI".
     */
    profileName: string;

    /**
     * Device family description, i.e. "Widerstandsthermometer-Messumformer".
     */
    deviceFamily: string;

    /**
     * Name of device vendor, i.e. "Phoenix Contact".
     */
    deviceVendor: string;

    /**
     * Product URL of device, i.e. "https://www.phoenixcontact.com/qr/2902051".
     */
    productURL: string;

    /**
     * Product image file name, i.e. "MINI_MCR-2-RTD-UI-PT-C_2902051.png".
     */
    productImage: string;

    /**
     * Product card image (on startup view) file name, i.e. "MINI_MCR-2-RTD-UI-PT-C_2902051.png".
     */
    productCardImage?: string;

    /**
     * PxC product order number, i.e. "2902051" (7 digits).
     */
    productOrderNumber: string;

    /**
     * Human readable PxC product firmware version.
     */
    firmwareVersion: string;

    /**
     * Filename
     */
    file: DeviceCatalogFilename;

    /**
     * Properties
     */
    properties: DeviceProperties;

    /**
     * Translation Dictionaries
     */
    i18n: Devicei18nProperties;

    /**
     * Species: device or station
     */
    species: Species;
  }

export interface DeviceCatalogSimulationEntry {
    file: string;
  }

export interface DeviceCatalogEntry {
    version: string;
    model: DeviceCatalogInformation;
    simulation: DeviceCatalogSimulationEntry;
  }

export interface DeviceCatalog {
    [key: string]: DeviceCatalogEntry;
  }
