/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

/* eslint-disable max-len */

export class _util {
  public static serviceIds = new Map<string, ServiceIdentifier<any>>();
}

/**
 * Identifies a service of type T
 */
export interface ServiceIdentifier<T> {
  id: string;
  type: (obj: any) => T;
}

/**
 * The *only* valid way to create a {{ServiceIdentifier}}.
 */
export function createDecorator<T>(serviceId: string): ServiceIdentifier<T> {
  if (!_util.serviceIds.has(serviceId)) {
    _util.serviceIds.set(serviceId, { id: serviceId, type: (obj: any) => obj as T });
  }
  return _util.serviceIds.get(serviceId);
}
