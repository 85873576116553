/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../store';
import { deviceTargetDatasetSelector } from '../../../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import displayStatusValueSelector from '../../../selectors/displayStatusValueSelector';
import { OpDeviceStatusControl } from './OpDeviceStatusControl/OpDeviceStatusControl';

export interface OpStatusControlProps {
  deviceInstanceId: string;
  identRef: string;
}

const OpStatusControl: React.FC<OpStatusControlProps> = (props: OpStatusControlProps):
  React.ReactElement => {
  const {
    identRef,
    deviceInstanceId,
  } = props;

  const { t } = useTranslation();

  const label = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, DatasetType.device);
    const descriptor = dataset?.descriptors[identRef];
    return descriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor ? descriptor.label : '';
  });

  const unit = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, DatasetType.device);
    const descriptor = dataset?.descriptors[identRef];

    if (descriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return '';
    }
    return descriptor.unit ?? '';
  });

  const textValue = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, DatasetType.device);
    return dataset === undefined ? '' : displayStatusValueSelector(dataset, identRef);
  });

  return (
    <OpDeviceStatusControl
      key={`op-${identRef}--${label}`}
      label={t<string>(label)}
      value={textValue}
      unit={t<string>(unit)}
    />
  );
};

export default OpStatusControl;
