/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../store';
import { GetApplicationMenu } from '../../store/selectors';
import ReduxControl from '../../controls/ReduxControl/ReduxControl';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import { DatasetContext } from '../../views/DatasetContext';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import './ModalDIPSwitchSummary.css';

export interface DIPSwitchSummaryProps {
  onClickOk: () => void;
  buttonsDisabled?: boolean;
  deviceInstanceId: string;
}

export const DIPSwitchSummary: React.FC<DIPSwitchSummaryProps> = (props: DIPSwitchSummaryProps): React.ReactElement => {
  const {
    onClickOk, buttonsDisabled, deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const { targetDataset } = useContext(DatasetContext);

  const summary = useTypedSelector((state) => GetApplicationMenu('APP_DIPSWITCH_POPUP_DISPLAY', deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset)));
  if (summary === undefined) {
    return <UnsupportedControl text="DIPSwitchSummary is not available" />;
  }

  return (
    <div className="dip-switch-summary-dialog">
      <div className="dip-switch-summary-dialog__container">
        <div className="dip-switch-summary-dialog__container--title">{t<string>('MODAL_DIP_SWITCH_SUMMARY_TITLE')}</div>
        <div className="dip-switch-summary-dialog__container--body">
          <ReduxControl deviceInstanceId={deviceInstanceId} identRef={summary} readonly onStateChanged={() => 0} />
        </div>
        <Button
          className="dip-switch-summary-dialog__container--buttonOk button-text"
          variant="primary"
          onClick={() => onClickOk()}
          disabled={buttonsDisabled}
        >
          {t<string>('WIZARD_BUTTON__NEXT')}
        </Button>
      </div>
    </div>
  );
};

export const ModalDIPSwitchSummary: React.FC<DIPSwitchSummaryProps> = (props:DIPSwitchSummaryProps) => {
  const {
    onClickOk, buttonsDisabled, deviceInstanceId,
  } = props;
  const modalRoot = document.getElementById('modal-root');

  if (modalRoot === null) {
    return <div />;
  }

  return (
    <>
      {ReactDOM.createPortal(<DIPSwitchSummary deviceInstanceId={deviceInstanceId} onClickOk={onClickOk} buttonsDisabled={buttonsDisabled} />, modalRoot)}
    </>
  );
};
