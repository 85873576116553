const toFixedValue = (value: unknown, point: number): string => {
  if (typeof value === 'undefined') {
    return '';
  }
  if (typeof value === 'number') {
    return value.toFixed(point);
  }
  if (typeof value === 'string') {
    const ivalue = Number.parseFloat(value);
    return Number.isNaN(ivalue) ? '' : ivalue.toFixed(point);
  }
  return `${value}`;
};

export default toFixedValue;
