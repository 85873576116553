/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { Services } from '@gpt/commons';
import React, {
  Children, PropsWithChildren, useCallback, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import ButtonListWizard from '../../components/ButtonList/ButtonList.Wizard';
import { WizardStepper, WizardStepProps } from '../../components/wizards';
import ModalApplicationMessageBox from '../../modal/ModalApplicationMessageBox/ModalApplicationMessageBox';
import { ButtonRole } from '../../store/deviceWizard/types';
import { WizardViewContext } from '../WizardViewContext';
import { WizardViewPageProps } from './WizardViewPage';
import { deviceWizardResetFocus } from '../../store/deviceWizard/actions';
import { RoutePaths } from '../../RoutePaths';
import useWizardLoadParameterFile from './hooks/useWizardLoadParameterFile';
import './WizardView.scss';

interface WizardViewProps extends PropsWithChildren<WizardViewPageProps> {
  title: string;
  // eslint-disable-next-line no-unused-vars
  onWizardExit: (mode: ButtonRole) => void;
  startPage?: number;
  deviceInstanceId?: string;
}

const WizardView: React.FC<WizardViewProps> = (props: WizardViewProps): React.ReactElement => {
  const {
    title, onWizardExit, children, startPage, deviceInstanceId,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPageIndex, setCurrentPageIndex] = useState(startPage ?? 0);
  const [downloadValues, setDownloadValues] = useState<Services.DeviceModel.StatusValueRef[]>([]);

  const useWizardLoadParam = useWizardLoadParameterFile(deviceInstanceId);

  // wizard button "onClick" callback for all button variants
  const buttonCallback = useCallback(async (
    pageCount: number,
    button: ButtonRole,
    onChangeToNextPage?: () => void,
    onChangeToPreviousPage?: () => void,
  ) => {
    switch (button) {
      case ButtonRole.back:
        onChangeToPreviousPage?.();
        setCurrentPageIndex((page) => (page > 0 ? page - 1 : page));
        break;
      case ButtonRole.next:
        onChangeToNextPage?.();
        setCurrentPageIndex((page) => (page < (pageCount - 1) ? page + 1 : page));
        break;
      case ButtonRole.abort:
      case ButtonRole.complete:
        dispatch(deviceWizardResetFocus());
        navigate(RoutePaths.StartupView);
        onWizardExit?.(button);
        break;
      case ButtonRole.load:
        await useWizardLoadParam();
        break;
      /* istanbul ignore next */
      default:
        throw new Error(`invalid ButtonRole: ${button}`);
    }
  }, [navigate, onWizardExit, currentPageIndex]);

  // create list of page titles for wizard stepper control
  const arrayChildren = Children.toArray(children);
  const stepperPages: WizardStepProps[] = Children.map(arrayChildren, (child) => {
    /* istanbul ignore next */
    if (child === undefined || child === null) {
      return { title: 'undef' };
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const item: React.ReactElement<WizardViewPageProps> = child as any;
    return {
      title: item.props.title,
      state: item.props.state,
    };
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const currentPage: React.ReactElement<WizardViewPageProps> = arrayChildren[currentPageIndex] as any;
  // create wizard stepper control, if not hidden on current page
  const hideStepper = currentPage.props.hideStepper || arrayChildren.length === 1;
  const stepperControl = hideStepper ? undefined : (
    <div className="wizard-view__stepper w-100 h-100">
      <WizardStepper activeStep={currentPageIndex} steps={stepperPages} />
    </div>
  );

  // wizard pages w/o stepper control have page content spacing adjusted via CSS classname
  const wizardContainerClass = `wizard-view-container${hideStepper ? '--no-stepper' : ''}`;

  const wizardPages = Children
    .map(arrayChildren, (child, index) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const item: React.ReactElement<WizardViewPageProps> = child as any;
      const newPageItem = React.cloneElement<WizardViewPageProps>(item, {
        ...item.props,
        active: (index === currentPageIndex),
      });
      return (
        <div className={`${currentPageIndex === index ? 'wizard-view__page' : 'wizard-view__page--hidden'}`}>{newPageItem}</div>
      );
    });

  return (
    <div className={`${wizardContainerClass} w-100 h-100`}>
      <div className="wizard-view__page-title d-flex justify-content-start align-items-center h-100 w-100">
        <p>{title}</p>
      </div>
      {stepperControl}
      <WizardViewContext.Provider value={{
        downloadValues,
        setDownloadValues,
        changeWizardPage: (button) => buttonCallback(
          Children.count(arrayChildren),
          button,
          currentPage?.props.onChangeToNextPage,
          currentPage?.props.onChangeToPreviousPage,
        ),
      }}
      >
        <div className="wizard-view__content h-100 pt-4">
          {wizardPages}
          <ModalApplicationMessageBox />
        </div>
      </WizardViewContext.Provider>
      <div className="wizard-view__buttons d-flex flex-row-reverse align-items-center w-100 h-100">
        <ButtonListWizard onClick={(button) => buttonCallback(
          Children.count(arrayChildren),
          button,
          currentPage?.props.onChangeToNextPage,
          currentPage?.props.onChangeToPreviousPage,
        )}
        />
      </div>
    </div>
  );
};

export default WizardView;
