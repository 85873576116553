/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

import { createDecorator } from '../../instantiation/instantiation';
import { IService } from '../Service';

export const PROP__DEVICE_INSTANCE_ID = 'deviceInstanceId';
export const PROP__DEVICE_INSTANCE_ID__DEFAULT = 'default';

export const PROP__DEVICE_UNIQUE_ID = 'deviceUniqueId';
export const PROP__DEVICE_UNIQUE_ID__DEFAULT = 'default';

export interface IDeviceModelPropetiesService extends IService {
    get: <T>(key: string) => T | undefined;
    set: <T>(key: string, value: T) => void;
}

// eslint-disable-next-line no-redeclare
export const IDeviceModelPropetiesServiceDec = createDecorator<IDeviceModelPropetiesService>('IDeviceModelPropetiesService');
