/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import UnsupportedControl from '../../UnsupportedControl/UnsupportedControl';
import { useTypedSelector } from '../../../store';
import PreviewValueVariableControl from '../PreviewValueVariableControl/PreviewValueVariableControl';
import PreviewParameterGroupControl from '../PreviewParameterGroupControl/PreviewParameterGroupControl';
import PreviewLinearizationControl from '../PreviewLinearizationControl/PreviewLinearizationControl';
import { DatasetContext } from '../../../views/DatasetContext';
import PreviewDipSwitchControl from '../PreviewDipSwitchControl/PreviewDipSwitchControl';
import PreviewRangeWithSpanControl from '../PreviewRangeWithSpan/PreviewRangeWithSpan';
import { deviceDescriptorSelector } from '../../../store/deviceInstances/store/deviceDataset/selector';

export interface PreviewValueGroupControlProps {
  identRef: IdentRef;
  deviceInstanceId: string;
}

const compareDescriptorList = (
  left?: DeviceModelStatus.DeviceModelDescriptor[],
  right?: DeviceModelStatus.DeviceModelDescriptor[],
): boolean => {
  if (left?.length !== right?.length) {
    return false;
  }

  const foundObject = left?.find((oldObj, index) => {
    const newObj = right !== undefined ? right[index] : undefined;
    return oldObj !== newObj;
  });

  return foundObject === undefined;
};

const PreviewValueGroupControl: React.FC<PreviewValueGroupControlProps> = (props: PreviewValueGroupControlProps)
: React.ReactElement => {
  const {
    identRef,
    deviceInstanceId,
  } = props;
  const { targetDataset } = useContext(DatasetContext);

  const { t } = useTranslation();
  const descriptor = useTypedSelector((state) => deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  const elementDescriptors = useTypedSelector((state) => {
    const desc = deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef);
    if (desc?.type !== DeviceModelStatus.StatusType.StructureDescriptor) {
      return [];
    }

    return desc.elements
      .reduce((acc, element) => {
        const edesc = deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, targetDataset, element.identRef);
        return edesc === undefined ? acc : [...acc, edesc];
      }, [] as DeviceModelStatus.DeviceModelDescriptor[]);
  }, (left, right) => compareDescriptorList(left, right));

  if (descriptor === undefined || elementDescriptors === undefined) {
    return <UnsupportedControl text={`PreviewValueGroupControl: Undeifined object ${identRef}`} />;
  }

  if (descriptor.type !== DeviceModelStatus.StatusType.StructureDescriptor) {
    return <UnsupportedControl text={`PreviewValueGroupControl: Unsupported type ${descriptor.type}`} />;
  }

  const controls = elementDescriptors.map((element) => {
    if (element.type === DeviceModelStatus.StatusType.ControlDescriptor) {
      if (element.controlType.type === DeviceModelStatus.UI.ControlType.CTLLINEARIZATION) {
        return (
          <PreviewLinearizationControl
            key={`group-litem--${descriptor.identRef}--${element.identRef}`}
            deviceInstanceId={deviceInstanceId}
            identRef={element.identRef}
            linControl={element.controlType}
          />
        );
      }
      if (element.controlType.type === DeviceModelStatus.UI.ControlType.CTLDIPSWITCHBLOCKONLINE
        || element.controlType.type === DeviceModelStatus.UI.ControlType.CTLDIPSWITCHBLOCKOFFLINE) {
        const control = (
          <PreviewDipSwitchControl
            key={`group-litem--${descriptor.identRef}--${element.identRef}`}
            deviceInstanceId={deviceInstanceId}
            identRef={element.identRef}
            onStateChanged={() => 0}
          />
        );
        return control;
      }
      if (element.controlType.type === DeviceModelStatus.UI.ControlType.CTLRANGEWITHSPAN) {
        const control = (
          <PreviewRangeWithSpanControl
            key={`group-litem--${descriptor.identRef}--${element.identRef}`}
            identRef={element.identRef}
            deviceInstanceId={deviceInstanceId}
            onStateChanged={() => 0}
          />
        );
        return control;
      }
      if (element.controlType.type === DeviceModelStatus.UI.ControlType.CTLCHECKBOX) {
        const control = (
          <PreviewValueVariableControl deviceInstanceId={deviceInstanceId} identRef={element.controlType.variable} />
        );
        return control;
      }
    }

    return (
      <div className="py-1" key={`group-vitem--${descriptor.identRef}--${element.identRef}`}>
        <PreviewValueVariableControl deviceInstanceId={deviceInstanceId} identRef={element.identRef} />
      </div>
    );
  });

  return (
    <div className="py-2">
      <PreviewParameterGroupControl label={t(descriptor.label)}>
        {controls}
      </PreviewParameterGroupControl>
    </div>
  );
};

export default PreviewValueGroupControl;
