/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import React from 'react';
import { ModalDIPSwitchReport } from '../../../modal/ModalDIPSwitchReport/ModalDIPSwitchReport';
import { ModalDIPSwitchSummary } from '../../../modal/ModalDIPSwitchSummary/ModalDIPSwitchSummary';
import { ModalDownloadProgressDialog } from '../../../modal/ModalDownloadProgress/DownloadProgressDialog';
import { useTypedSelector } from '../../../store';
import { deviceStatusValueSelector } from '../../../store/deviceInstances/store/deviceDataset/selector';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { MethodStageExecutionStatus } from '../../../store/deviceInstances/store/deviceMethod/types';
import useReportMenu from './hooks/useReportMenu';
import useDownloadMethodExecutionStatus from './hooks/useDownloadMethodExecutionStatus';

// eslint-disable-next-line no-unused-vars
export enum DialogMode {
  // eslint-disable-next-line no-unused-vars
  hideDialog = 'hideDialog',
  // eslint-disable-next-line no-unused-vars
  downloadDialog = 'downloadDialog',
  // eslint-disable-next-line no-unused-vars
  dipSwitchSummaryDialog = 'dipSwitchSummaryDialog',
  // eslint-disable-next-line no-unused-vars
  dipSwitchReportDialog = 'dipSwitchReportDialog',
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface DeviceViewDialogProps {
  dialogMode: DialogMode;
  activeDeviceInstanceId: string;
  // eslint-disable-next-line no-unused-vars
  setDialogMode: (mode: DialogMode) => void;
}

const DeviceViewDialog: React.FC<DeviceViewDialogProps> = (props: DeviceViewDialogProps): React.ReactElement => {
  const { dialogMode, setDialogMode, activeDeviceInstanceId } = props;

  const modeValue = useTypedSelector(
    (state) => deviceStatusValueSelector(state.deviceInstances, activeDeviceInstanceId, DatasetType.user, 'loc_DipSwitch_Parameter')?.value ?? 0,
  );

  const reportMenu = useReportMenu(activeDeviceInstanceId);
  const uploadMethodState = useDownloadMethodExecutionStatus(activeDeviceInstanceId);

  let modalDialog: React.ReactElement | undefined;
  switch (dialogMode) {
    case DialogMode.dipSwitchSummaryDialog:
      modalDialog = (
        <ModalDIPSwitchSummary
          deviceInstanceId={activeDeviceInstanceId}
          onClickOk={async (): Promise<void> => {
            setDialogMode(DialogMode.dipSwitchReportDialog);
          }}
        />
      );
      break;
    case DialogMode.dipSwitchReportDialog:
      switch (uploadMethodState) {
        case MethodStageExecutionStatus.Initialize:
        case MethodStageExecutionStatus.InProgress:
          modalDialog = (
            <ModalDIPSwitchReport
              deviceInstanceId={activeDeviceInstanceId}
              reportMenu={reportMenu}
              buttonDisabled
              reportButtonsDisabled
              onClose={async (): Promise<void> => {
                setDialogMode(DialogMode.hideDialog);
              }}
            />
          );
          break;
        case MethodStageExecutionStatus.DoneSuccess:
          modalDialog = (
            <ModalDIPSwitchReport
              deviceInstanceId={activeDeviceInstanceId}
              reportMenu={reportMenu}
              onClose={async (): Promise<void> => {
                setDialogMode(DialogMode.hideDialog);
              }}
            />
          );
          break;
        case MethodStageExecutionStatus.DoneFailed:
          modalDialog = (
            <ModalDIPSwitchReport
              deviceInstanceId={activeDeviceInstanceId}
              reportMenu={reportMenu}
              reportButtonsDisabled
              onClose={async (): Promise<void> => {
                setDialogMode(DialogMode.hideDialog);
              }}
            />
          );
          break;
        default:
      }
      break;
    case DialogMode.downloadDialog:
      modalDialog = (
        <ModalDownloadProgressDialog
          reportMenu={reportMenu}
          deviceInstanceId={activeDeviceInstanceId}
          closeButtonText={modeValue === 1 ? 'STANDARD_BUTTON__NEXT' : 'STANDARD_BUTTON__CLOSE'}
          onClose={async (): Promise<void> => {
            const mode = modeValue === 1 ? DialogMode.dipSwitchSummaryDialog : DialogMode.hideDialog;
            setDialogMode(mode);
          }}
        />
      );
      break;
    default:
      modalDialog = undefined;
  }

  return (
    <>{modalDialog}</>
  );
};

export default DeviceViewDialog;
