/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useCallback } from 'react';
import { Services } from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { ReduxControlProps } from '../../ReduxControlProps';
import {
  varIoutCharacteristicsPowerMode,
  varIoutCharacteristicsDynamicBoost,
  varIoutCharacteristicsSFB,
  varIoutCharacteristicsStaticBoost,
  varFusemodeCurrent,
  varFusemodeTime,
  varFusemodeVoltage,
} from './quint4IoLinkTypes';
import VariableReduxEditorControl from '../../../components/ParameterEditor/VariableEditorControl/VariableEditorReduxControl';
import { writeActiveDeviceVariableValues } from '../../../store';
import { useContextDatasetStatusValue } from '../../../hooks/useContextDataset';
import Quint4CheckboxControl from '../Quint4CheckboxControl/Quint4CheckboxControl';
import Quint4CharacteristicImageSelector from './CharacteristicImageSelector/Quint4CharacteristicImageSelector';

const POWER_MODE__UI_ADVACED_MODE = 1;
const POWER_MODE__FUSE_MODE = 2;
const POWER_MODE__SECURE_SHUTDOWN_MODE = 3;
const POWER_MODE__SMART_HICCUP = 4;

const Quint4IoLinkImageCharacteristics: React.FC<ReduxControlProps> = (props: ReduxControlProps)
  : React.ReactElement => {
  const { deviceInstanceId, onStateChanged } = props;
  const dispatch = useDispatch();

  const ioutPowerModeValue = useContextDatasetStatusValue(deviceInstanceId, varIoutCharacteristicsPowerMode);
  const ioutStaticBoostValue = useContextDatasetStatusValue(deviceInstanceId, varIoutCharacteristicsStaticBoost);
  const ioutDynamicBoostValue = useContextDatasetStatusValue(deviceInstanceId, varIoutCharacteristicsDynamicBoost);
  const ioutSFBValue = useContextDatasetStatusValue(deviceInstanceId, varIoutCharacteristicsSFB);

  const createIoutCharacteristics = useCallback((staticboost: boolean, dynamicBoost: boolean, sfb: boolean): Services.DeviceModel.StatusValueRef[] => {
    let result: Services.DeviceModel.StatusValueRef[] = [];
    result = ioutStaticBoostValue === undefined ? result : [
      ...result,
      {
        identRef: ioutStaticBoostValue.identRef,
        value: staticboost ? 1 : 0,
        backupValue: ioutStaticBoostValue?.backupValue,
      },
    ];
    result = ioutDynamicBoostValue === undefined ? result : [
      ...result,
      {
        identRef: ioutDynamicBoostValue.identRef,
        value: dynamicBoost ? 1 : 0,
        backupValue: ioutDynamicBoostValue?.backupValue,
      },
    ];
    result = ioutSFBValue === undefined ? result : [
      ...result,
      {
        identRef: ioutSFBValue.identRef,
        value: sfb ? 1 : 0,
        backupValue: ioutSFBValue?.backupValue,
      },
    ];
    return result;
  }, [ioutStaticBoostValue, ioutDynamicBoostValue, ioutSFBValue]);

  return (
    <div>
      <Quint4CharacteristicImageSelector
        deviceInstanceId={deviceInstanceId}
        statusValue={ioutPowerModeValue}
        showContextMessageDef
        onStateChanged={onStateChanged}
        onValueChange={(value) => {
          let variableList: Services.DeviceModel.StatusValueRef[] = [];
          switch (value) {
            case POWER_MODE__UI_ADVACED_MODE:
              variableList = createIoutCharacteristics(true, true, true);
              break;
            case POWER_MODE__FUSE_MODE:
              variableList = createIoutCharacteristics(true, false, false);
              break;
            case POWER_MODE__SECURE_SHUTDOWN_MODE:
              variableList = createIoutCharacteristics(true, false, false);
              break;
            case POWER_MODE__SMART_HICCUP:
              variableList = createIoutCharacteristics(true, true, false);
              break;
            default:
          }
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [
            ...variableList,
            {
              identRef: varIoutCharacteristicsPowerMode,
              value,
              backupValue: ioutPowerModeValue?.backupValue,
            },
          ]));
        }}
      />
      <Quint4CheckboxControl
        deviceInstanceId={deviceInstanceId}
        identRef={varIoutCharacteristicsStaticBoost}
        onStateChanged={onStateChanged}
        onCustomValueChange={(value) => {
          const variableList = value
            ? [{
              identRef: varIoutCharacteristicsStaticBoost,
              value: 1,
              backupValue: ioutStaticBoostValue?.backupValue,
            }]
            : createIoutCharacteristics(false, false, false);
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, variableList));
        }}
      />
      <Quint4CheckboxControl
        deviceInstanceId={deviceInstanceId}
        identRef={varIoutCharacteristicsDynamicBoost}
        onStateChanged={onStateChanged}
        onCustomValueChange={(value) => {
          const boostValue = value ? 1 : 0;
          const variableList = [{
            identRef: varIoutCharacteristicsStaticBoost,
            value: boostValue,
            backupValue: ioutStaticBoostValue?.backupValue,
          },
          {
            identRef: varIoutCharacteristicsDynamicBoost,
            value: boostValue,
            backupValue: ioutStaticBoostValue?.backupValue,
          }];
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, variableList));
        }}
      />
      <Quint4CheckboxControl
        deviceInstanceId={deviceInstanceId}
        identRef={varIoutCharacteristicsSFB}
        onStateChanged={onStateChanged}
        onCustomValueChange={(value) => {
          const variableList = [{
            identRef: varIoutCharacteristicsSFB,
            value: value ? 1 : 0,
            backupValue: ioutSFBValue?.backupValue,
          }];
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, variableList));
        }}
      />
      <VariableReduxEditorControl
        deviceInstanceId={deviceInstanceId}
        identRef={varFusemodeCurrent}
        onStateChanged={onStateChanged}
      />
      <VariableReduxEditorControl
        deviceInstanceId={deviceInstanceId}
        identRef={varFusemodeTime}
        onStateChanged={onStateChanged}
      />
      <VariableReduxEditorControl
        deviceInstanceId={deviceInstanceId}
        identRef={varFusemodeVoltage}
        onStateChanged={onStateChanged}
      />
    </div>
  );
};

export default Quint4IoLinkImageCharacteristics;
