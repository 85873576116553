/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ExecutionState } from '../../common';
import { setSystemAppInitialized } from '../../systemInformation/actions';
import {
  DeviceCatalogActionTypes,
  REQUEST_DEVICE_CATALOG_DEVICE_LIST,
} from '../types';
import { setDiscoveryDeviceCatalogState, updateDiscoveryDeviceCatalog } from '../../discoveryServiceState/actions';
import { infrastructureService } from '../../../services/InfrastructureService/InfrastructureService';

const deviceCatalogMiddleware = ()
  : Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends DeviceCatalogActionTypes>(action: A): Promise<A> => {
  if (action.type === REQUEST_DEVICE_CATALOG_DEVICE_LIST) {
    api.dispatch(setDiscoveryDeviceCatalogState(ExecutionState.pending));
    try {
      const response = await infrastructureService.getDeviceCatalog();
      const catalog = response ?? {};
      const deviceList = Object.keys(catalog).map((key) => catalog[key].model);
      api.dispatch(updateDiscoveryDeviceCatalog(deviceList));
      api.dispatch(setDiscoveryDeviceCatalogState(ExecutionState.success));
      setTimeout(() => {
        api.dispatch(setSystemAppInitialized());
      }, 2000);
    } catch (err) {
      api.dispatch(setDiscoveryDeviceCatalogState(ExecutionState.failed));
      api.dispatch(setSystemAppInitialized());
    }
  }
  const result = next(action);
  return result;
};

export default deviceCatalogMiddleware;
