/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import { IdentRef } from '../services/common';
import { LocString, StatusType } from './deviceModelCommons';
import { StatusDescriptorValueType } from './typeinfo/types';
import { StatusDescriptorValueTypeTable } from './typeinfo/table';
import { StatusDescriptorValueTypeTableRecord } from './typeinfo/tablerecord';
import { StatusDescriptorValueTypeBlob } from './typeinfo/blob';
import { StatusDescriptorValueTypeIPV4Address } from './typeinfo/ipv4address';
import { StatusDescriptorValueTypeMACAddress } from './typeinfo/macaddress';
import { StatusDescriptorValueTypeRecord } from './typeinfo/record';
import { StatusDescriptorValueTypeJsonValue } from './typeinfo';

export type UnitRef = string;

export enum StatusDescriptorHandling {
    READ_ONLY,
    WRITE_ONLY,
    READ_WRITE,
}

export interface StatusDescriptorValueRange {
    minValue?: number;
    maxValue?: number;
}

export interface StatusDescriptorRange {
  rangeAsText?: string;
  regions: StatusDescriptorValueRange[];
}

export interface StatusDescriptorDefaultValue {
    value: number;
    label: LocString;
}

export interface StatusDescriptorDefaultValuesList {
    values: StatusDescriptorDefaultValue[]
}

export interface StatusDescriptorValueTypeFloat {
    type: typeof StatusDescriptorValueType.FLOAT;
    range?: StatusDescriptorRange;
    defaultsList?: StatusDescriptorDefaultValuesList;
    initialValue: number;
    stepSize?: number;
    precision?: number;
    displayFormat?: string;
    scaleFactor?: number;
    resolution?: number;
}

// export interface StatusDescriptorValueTypeDouble {
//     type: typeof StatusDescriptorValueType.DOUBLE;
//     range?: StatusDescriptorValueRange[];
//     defaultsList?: StatusDescriptorDefaultValuesList;
//     initialValue: number;
//     stepSize?: number;
//     resolution?: number;
//     displayFormat?: string;
// }

export interface StatusDescriptorValueTypeInteger {
    type: typeof StatusDescriptorValueType.INTEGER;
    sizeInBits: number;
    range?: StatusDescriptorRange;
    defaultsList?: StatusDescriptorDefaultValuesList;
    initialValue: number;
    stepSize?: number;
    displayFormat?: string;
}

export interface StatusDescriptorValueTypeUnsignedInteger {
    type: typeof StatusDescriptorValueType.UNSIGNED_INTEGER;
    sizeInBits: number;
    range?: StatusDescriptorRange;
    defaultsList?: StatusDescriptorDefaultValuesList;
    initialValue: number;
    stepSize?: number;
    displayFormat?: string;
}

export interface StatusDescriptorValueTypeString {
    type: typeof StatusDescriptorValueType.STRING;
    length: number;
    initialValue: string;
    stringType: StatusDescriptorValueTypeStringType;
}

export enum StatusDescriptorValueTypeStringType {
    UTF8 = 0,
    ASCII = 1
}

export interface StatusDescriptorValueTypeEnumeratorItem {
    label: string;
    value: number;
    description?: string,
}

export interface StatusDescriptorValueTypeEnumerator {
    type: typeof StatusDescriptorValueType.ENUMERATOR;
    initialValue: number;
    sizeInBits: number;
    enumerator: StatusDescriptorValueTypeEnumeratorItem[];
}

// export interface StatusDescriptorValueTypeBitEnumerator {
//     type: typeof StatusDescriptorValueType.BITENUMERATOR;
//     initialValue: number;
//     sizeInBits: number;
//     enumerator: StatusDescriptorValueTypeEnumeratorItem[];
// }

export interface StatusDescriptorValueTypeDateTime {
    type: typeof StatusDescriptorValueType.DATETIME;
    initialValue: string;
    displayFormat?: string;
}

export interface StatusDescriptorValueTypeDate {
    type: typeof StatusDescriptorValueType.DATE;
    initialValue: string;
    displayFormat?: string;
}

export interface StatusDescriptor {
    type: typeof StatusType.StatusDescriptor;
    identRef: IdentRef,
    label: LocString;
    help: LocString;
    description: LocString;
    handling: StatusDescriptorHandling;
    validity: boolean;
    visibility: boolean;
    valueType: StatusDescriptorValueKind;
    unit?: UnitRef;
    unitid?: number;
    onValueChanged?: IdentRef[];
    postReadAction?: IdentRef;
    preWriteAction?: IdentRef;
    preWriteDatasetAction?: IdentRef;
    onValueValidation?: IdentRef;
}

export type StatusDescriptorValueKind =
| StatusDescriptorValueTypeFloat
// | StatusDescriptorValueTypeDouble
| StatusDescriptorValueTypeInteger
| StatusDescriptorValueTypeUnsignedInteger
| StatusDescriptorValueTypeString
| StatusDescriptorValueTypeEnumerator
// | StatusDescriptorValueTypeBitEnumerator
| StatusDescriptorValueTypeDateTime
| StatusDescriptorValueTypeDate
| StatusDescriptorValueTypeTable
| StatusDescriptorValueTypeTableRecord
| StatusDescriptorValueTypeBlob
| StatusDescriptorValueTypeIPV4Address
| StatusDescriptorValueTypeMACAddress
| StatusDescriptorValueTypeRecord
| StatusDescriptorValueTypeJsonValue;
