import { DownloadMethodType } from './methodDownloadType';
import { DynamicReadMethodType } from './methodDynamicReadType';
import { UploadMethodType } from './methodUploadType';
import { CustomMethodType } from './methodCustomType';
import {
  MethodType,
  MethodBaseType,
  METHOD_DOWNLOAD__IDENTREF,
  METHOD_DYNAMICREAD__IDENTREF,
  METHOD_UPLOAD__IDENTREF,
} from './methodTypes';

export type MethodDescriptorType = DownloadMethodType
 | UploadMethodType
 | DynamicReadMethodType
 | CustomMethodType;

export {
  MethodType,
  METHOD_DOWNLOAD__IDENTREF,
  METHOD_DYNAMICREAD__IDENTREF,
  METHOD_UPLOAD__IDENTREF,
};

export type {
  MethodBaseType,
  DownloadMethodType,
  UploadMethodType,
  DynamicReadMethodType,
  CustomMethodType,
};
