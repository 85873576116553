/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  Services,
} from '@gpt/commons';
import AppSystemService from '../../../services/AppService/AppSystemService';
import { infrastructureService } from '../../../services/InfrastructureService/InfrastructureService';

/**
 * Retrieves the current application zoom level.
 */
export const getApplicationZoomLevel = async ():
Promise<number> => AppSystemService.GetZoomLevel();

/**
 * Changes the current application zoom factor.
 */
export const setApplicationZoomLevel = async (value: number):
Promise<number> => AppSystemService.MainViewZoom('set', value);

export const getSystemInformation = async ()
: Promise<Services.DeviceModelServer.SystemInformationResponse> => infrastructureService.getSystemInfo();

/**
 * Filters an array, returning only distinct elements, according to a given criteria.
 */
// eslint-disable-next-line no-unused-vars
export function distinct<T>(array: T[], criteria: (value: T) => unknown): T[] {
  const mapped = array.map(criteria);
  return array.filter((val, idx) => mapped.indexOf(criteria(val)) === idx);
}

export const getAppTextDocument = async (fileName: string)
: Promise<string> => infrastructureService.getTextDocument(fileName);
