import { DeviceModelDescriptor } from '../../../devicemodel';
import { StatusValue } from '../../../devicemodel/deviceModelStatusVector';
import { IdentRef } from '../../common';
import { DeviceInformation } from '../../DeviceInformation';
import { DatasetIdent } from './common';

export interface ExternalDatasetValues {
  [key: string]: StatusValue;
}

export interface ExternalDatasetDescriptors {
  [key: string]: DeviceModelDescriptor;
}

export enum ExternalDatasetType {
  COMPLETE = 0,
  FIRMWARE = 1,
  HARDWARE_SWITCH = 2,
}

export interface ExternalDatasetHeader {
  caption: string;
  datasetId: DatasetIdent;
  device: DeviceInformation;
  previewMenu: IdentRef;
  userComment: string;
  saveDateTime: Date;
  datasetType?: ExternalDatasetType;
}

export const EXTERNAL_DATASET__VERSION = 2;

export interface ExternalDataset {
  // Note: Version undefined - 1st version,
  // next defined version - 2
  version: number;
  header: ExternalDatasetHeader;
  values: ExternalDatasetValues;
  descriptors: ExternalDatasetDescriptors;
}

export const createDatasetIdent = (prefix: string): DatasetIdent => {
  const date = new Date();
  return `${prefix}-${date.toISOString()}`;
};
