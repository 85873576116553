/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import './Quint4ImageSelectorItem.scss';

export interface Quint4ImageSelectorItemProps {
  title: string
  image: string;
  value: boolean;
  onChange: () => void;
  customClassName?: string;
  onFocus: () => void;
  onBlur: () => void;
}

// eslint-disable-next-line max-len
export const Quint4ImageSelectorItem: React.FC<Quint4ImageSelectorItemProps> = (props: Quint4ImageSelectorItemProps): React.ReactElement => {
  const {
    title, image, onChange, value,
    customClassName,
    onFocus, onBlur,
  } = props;

  const activeClassName = value ? 'quint4-image-selector-item-container--active' : '';

  const className = customClassName === undefined
    ? `quint4-image-selector-item-container ${activeClassName}`
    : `quint4-image-selector-item-container ${activeClassName} ${customClassName}`;
  return (
    <div className={`${className}`} data-testid="quint4-image-selector-item" role="button" onClick={onChange} onFocus={onFocus} onBlur={onBlur} tabIndex={0} onKeyUp={() => 0}>
      <div className="image-selector-item-container__label_container">
        <div className="image-selector-item-container__label_container__label">{title}</div>
      </div>
      <div className="image-selector-item-container__image_container">
        <img className="image-selector-item-container__image_container__image" src={image} alt="" />
      </div>
    </div>
  );
};
