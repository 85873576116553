/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
export const varIoutCharacteristicsPowerMode = 'var_V_PS_Setting_Output_05_0_4_TN_PS_OutputCharacteristic';
export const varIoutCharacteristicsStaticBoost = 'var_V_PS_Setting_Output_05_5_1_TN_PS_OutputCharacteristic_StaticBoost';
export const varIoutCharacteristicsDynamicBoost = 'var_V_PS_Setting_Output_05_6_1_TN_PS_OutputCharacteristic_DynamicBoost';
export const varIoutCharacteristicsSFB = 'var_V_PS_Setting_Output_05_7_1_TN_PS_OutputCharacteristic_SFB';

export const varFusemodeCurrent = 'var_V_PS_Setting_Output_06_TN_PS_FusemodeCurrent';
export const varFusemodeTime = 'var_V_PS_Setting_Output_07_TN_PS_FusemodeTime';
export const varFusemodeVoltage = 'var_V_PS_Setting_Output_08_TN_PS_FusemodeVoltage';
