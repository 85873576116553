/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import { StationItemInformation } from '../services/DeviceInformation';
import { WebDeviceEventBase } from './types';

export const WEBDEVICE__UPDATE_STATION__EVENT = 'WEBDEVICE__UPDATE_STATION_EVENT';
export const WEBDEVICE__UPDATE_STATION__ADD_DISCOVERY__EVENT = 'WEBDEVICE__UPDATE_STATION__ADD_DISCOVERY__EVENT';
export const WEBDEVICE__UPDATE_STATION__REMOVE_DISCOVERY__EVENT = 'WEBDEVICE__UPDATE_STATION__REMOVE_DISCOVERY__EVENT';
export const WEBDEVICE__UPDATE_STATION__INFORMATION__EVENT = 'WEBDEVICE__UPDATE_STATION__INFORMATION__EVENT';

export interface WebDeviceStationAddDiscovery {
    kind: typeof WEBDEVICE__UPDATE_STATION__ADD_DISCOVERY__EVENT;
    items: StationItemInformation[];
}

export interface WebDeviceStationRemoveDiscovery {
    kind: typeof WEBDEVICE__UPDATE_STATION__REMOVE_DISCOVERY__EVENT;
    items: string[];
}

export interface WebDeviceStationInformation {
    kind: typeof WEBDEVICE__UPDATE_STATION__INFORMATION__EVENT;
    title: string;
    subtitle: string;
    deviceInstanceId: string;
}

export type WebDeviceStationPayload = WebDeviceStationAddDiscovery
| WebDeviceStationRemoveDiscovery
| WebDeviceStationInformation;

export interface WebDeviceStationEvent extends WebDeviceEventBase {
    kind: typeof WEBDEVICE__UPDATE_STATION__EVENT;
    payload: WebDeviceStationPayload;
}
