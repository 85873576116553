/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { openBackstage } from '../Backstage';
import IconGear from '../../assets/icons/icon-gear.svg';
import './SettingsButton.scss';

const SettingsButton: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div
      className="settingsbutton d-flex align-items-center noWrap"
      role="button"
      tabIndex={0}
      onClick={() => { openBackstage(); }}
      onKeyDown={() => 0}
    >
      <div>
        <img src={IconGear} alt="settings" />
      </div>
      <div style={{ marginLeft: '16px' }}>
        <p className="navbutton-title-format p-0 m-0">{t('BACKSTAGE_PAGE_TITLE__SETTINGS')}</p>
        <p className="navbutton-subtitle-format m-0 p-0">{t('DEVICE_VIEW_SETTINGS_SUBTITLE')}</p>
      </div>
    </div>
  );
};

export default SettingsButton;
