/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import { StatusType } from '../deviceModelCommons';
import { DeviceModelDescriptor } from '../deviceModelDescriptorCollection';
import { StatusDescriptorRange } from '../deviceModelStatusDescriptor';
import { StatusDescriptorValueType } from '../typeinfo/types';

// eslint-disable-next-line import/prefer-default-export
export const GetDescriptorRange = (descriptor: DeviceModelDescriptor)
  : StatusDescriptorRange | undefined => {
  if (descriptor?.type !== StatusType.StatusDescriptor) {
    return undefined;
  }

  if (descriptor.valueType.type === StatusDescriptorValueType.INTEGER
     || descriptor.valueType.type === StatusDescriptorValueType.UNSIGNED_INTEGER
     || descriptor.valueType.type === StatusDescriptorValueType.FLOAT) {
    return descriptor.valueType.range;
  }

  return undefined;
};

export const GetDescriptorStringMaxLength = (descriptor: DeviceModelDescriptor): number => {
  if (descriptor === undefined) {
    return 0;
  }
  if (descriptor.type !== StatusType.StatusDescriptor) {
    return 0;
  }
  if (descriptor.valueType.type !== StatusDescriptorValueType.STRING) {
    return 0;
  }
  return descriptor.valueType.length;
};
