import { Resource } from 'i18next';

import i18nEn from './MACX_MCR_AP_en.json';
import i18nDe from './MACX_MCR_AP_de.json';

const MACX_MCR_AP: Resource = {
  en: {
    MACX_MCR_AP: i18nEn,
  },
  de: {
    MACX_MCR_AP: i18nDe,
  },
};

export default MACX_MCR_AP;
