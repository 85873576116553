import { ConnectionStatus } from '../../DeviceModelServer/ConnectionService';

/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 *************************************************************************** */
export const CONNECTION_EVENT = 'connection.state';

export interface ConnectionNotification {
  type: typeof CONNECTION_EVENT;
  status: ConnectionStatus;
}

// eslint-disable-next-line max-len
export const createConnectionNotification = (status: ConnectionStatus)
: ConnectionNotification => ({
  type: CONNECTION_EVENT,
  status,
});
