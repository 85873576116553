/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import { WebDeviceResponseBase } from './types';

export const WEBDEVICE__ERROR_RESPONSE = 'WEBDEVICE__ERROR_RESPONSE';

export interface WebDeviceErrorResponse extends WebDeviceResponseBase {
    kind: typeof WEBDEVICE__ERROR_RESPONSE;
    message: string;
}
