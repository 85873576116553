/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import toFixedValue from './toFixedValue';

export {
  // eslint-disable-next-line import/prefer-default-export
  toFixedValue,
};
