/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

import * as Service from './Service';
import { ServiceIdentifier } from '../instantiation/instantiation';
import ServiceCollection from '../instantiation/serviceCollection';

export interface IServiceProvider {
  get: <T extends Service.IService>(ident: ServiceIdentifier<T>) => T;
  set: <T extends Service.IService>(ident: ServiceIdentifier<T>, instance: T) => void;
  delete: <T extends Service.IService>(ident: ServiceIdentifier<T>) => void;
  count: () => number;
  dispose: () => Promise<void>;
}

export class ServiceProvider implements IServiceProvider {
  private services: ServiceCollection;

  constructor() {
    this.services = new ServiceCollection();
  }

  get = <T extends Service.IService>(ident: ServiceIdentifier<T>): T => this.services.get(ident);

  set = <T extends Service.IService>(ident: ServiceIdentifier<T>, instance: T): void => {
    if (this.services.get(ident) !== undefined) {
      throw new Error(`service ${ident.id} is already defined`);
    }
    this.services.set(ident, instance);
  };

  delete = <T extends Service.IService>(ident: ServiceIdentifier<T>): void => {
    if (this.services.get(ident) === undefined) {
      throw new Error(`service ${ident.id} is not defined`);
    }
    this.services.delete(ident);
  };

  dispose = async (): Promise<void> => {
    const services = this.services.services();

    const disposable = services.reduce((acc, service) => {
      const srv = service as unknown as any;
      if (srv?.Dispose === undefined) {
        return acc;
      }
      return [
        ...acc,
        srv as Service.IServiceDisposable,
      ];
    }, [] as Service.IServiceDisposable[]);

    await disposable
      .reduce(async (promise: Promise<void>, service: Service.IServiceDisposable) => {
        try {
          await promise;
        } catch { /* empty */ }
        return service.Dispose();
      }, Promise.resolve());
  };

  count = (): number => this.services.count();
}
