/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { DeviceModelStatus, Services } from '@gpt/commons';
import { ParameterControl } from '../../../components/ParameterEditor';
import { UnsupportedControl } from '../../../components/UnsupportedControl';
import { useContextDatasetDescriptor } from '../../../hooks/useContextDataset';
import { showApplicationMessage } from '../../../store/applicationMessage/actions';
import { cleanDeviceMethodExecutionState } from '../../../store/deviceInstances/store/deviceMethod/actions';
import { ioLinkSetSystemCommandState } from '../../../store/deviceInstances/store/ioLinkSystemCommand/actions';
import { IoLinkSystemCommandStage } from '../../../store/deviceInstances/store/ioLinkSystemCommand/types';
import { ReduxControlProps } from '../../ReduxControlProps';
import withControlVisibility from '../../hoc/withControlVisibility';
import { ModalIoLinkSystemCommandDialog } from './dialog/IoLinkSystemCommandDialog';
import { useDeviceConnectionStatus } from '../../../store/deviceInstances/hooks/StandardViewHooks';

const { METHOD_UPLOAD__IDENTREF } = DeviceModelStatus.Methods;

export interface IoLinkSystemCommandControlProps extends ReduxControlProps {
  noParamControl?: boolean;
}

const IoLinkSystemCommandControl: React.FC<IoLinkSystemCommandControlProps> = (props: IoLinkSystemCommandControlProps):
  React.ReactElement => {
  const {
    identRef,
    deviceInstanceId,
    noParamControl,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);

  const connectionStatus = useDeviceConnectionStatus(deviceInstanceId);
  const controlDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);

  if (controlDescriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text="Only ControlDescriptor is supported" />;
  }
  const { controlType } = controlDescriptor;
  if (controlType.type !== DeviceModelStatus.UI.ControlType.CTLIOLINKSYSTEMCOMMAND) {
    return <UnsupportedControl text="Only CTLIOLINKSYSTEMCOMMAND is supported" />;
  }

  const blockMode = noParamControl === true || controlType.block === true;

  const disabled = connectionStatus === Services.DeviceModelServer.ConnectionService.ConnectionStatus.disconnected;
  const actionLabel = t<string>(controlType.label);

  const buttonControl = (
    <Button
      className="button-text w-100"
      variant="secondary"
      block
      disabled={disabled}
      onClick={() => {
        dispatch(ioLinkSetSystemCommandState(deviceInstanceId, {
          stage: IoLinkSystemCommandStage.stageInitial, targetInstance: deviceInstanceId,
        }));
        dispatch(cleanDeviceMethodExecutionState(deviceInstanceId, METHOD_UPLOAD__IDENTREF));
        setShowDialog(true);
      }}
    >
      {actionLabel}
    </Button>
  );

  return (
    <>
      {blockMode
        ? buttonControl : (
          <ParameterControl label="">
            {buttonControl}
          </ParameterControl>
        )}
      {showDialog && (
        <ModalIoLinkSystemCommandDialog
          title={t<string>('MODAL_DIALOG__ACKNOWLEDGE_ACTION__TITLE')}
          deviceInstanceId={deviceInstanceId}
          actionLabel={actionLabel}
          command={controlType.command}
          onClose={(result: boolean) => {
            if (result) {
              const doneSuccessMessage = t<string>('MODAL_DIALOG__ACKNOWLEDGE_ACTION__DONE_SUCCESS', { ACTION_NAME: actionLabel });
              dispatch(showApplicationMessage('success', doneSuccessMessage));
            } else {
              const doneFailedMessage = t<string>('MODAL_DIALOG__ACKNOWLEDGE_ACTION__DONE_FAILED', { ACTION_NAME: actionLabel });
              dispatch(showApplicationMessage('warning', doneFailedMessage));
            }
            setShowDialog(false);
          }}
        />
      )}
    </>
  );
};

export default withControlVisibility(IoLinkSystemCommandControl);
