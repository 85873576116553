/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { createDecorator } from '../../instantiation/instantiation';
import { IService } from '../Service';

export const IDeviceModelResourcesService = createDecorator<IDeviceModelResourcesService>('IDeviceModelResourcesService');

export interface DeviceI18nDictionary {
  [key: string]: string | number | DeviceI18nDictionary;
}

export interface DeviceI18nResource {
  deviceId: string;
  dictFamily: string;
  dictionaries: DeviceI18nDictionary;
}

export interface DeviceImageResources {
  [key: string]: string;
}

// eslint-disable-next-line no-redeclare
export interface IDeviceModelResourcesService extends IService {
  GetTranslation: () => Promise<DeviceI18nResource>;
  GetImageResources: () => Promise<DeviceImageResources>;
}
