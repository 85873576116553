/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

import { DeviceModelStatus, IdentRef, Services } from '@gpt/commons';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import {
  initLinearizationDataset,
  setLinearizationMessage,
  setLinearizationSplineMessage,
  setLinearizationState, setSplineCalculationStatus, showLinearizationSplineMessage, updateLinearizationDataset,
} from '../actions';
import {
  LinearizationDatasetState,
  LinearizationSplineData,
  LinearizationTable,
  LinearizationTableItem,
  LINEARIZATION_DATASET__INIT_LINEARIZATION_CONTROL,
  LINEARIZATION_DATASET__MAX_REGRESSION_ERROR, LINEARIZATION_DATASET__SAVE_TO_DATASET,
  LINEARIZATION_DATASET__SET_POINT_COUNTER, LINEARIZATION_DATASET__SET_POINT_X,
  LINEARIZATION_DATASET__SET_POINT_Y, LINEARIZATION_DATASET__SET_USER_POINTS, LINEARIZATION_DATASET__SHOW_LINEARIZATION_MESSAGE, LINEARIZATION_DATASET__SHOW_SPLINE_MESSAGE, LinTableMessage,
  LinTableValue, SetLinearizationUserPointsAction, SetLinMaxRegressionErrorAction, SetPointXAction, SetPointYAction,
  ShowLinTableLinearizationMessageAction,
  ShowLinTableSplineMessageAction,
  SplineCalculationStatus, typeLinearizationDatasetActionTypes,
} from '../types';
// eslint-disable-next-line import/no-cycle
import { ExecutionState } from '../../common';
import { selectLinTableVariableDisplayFormat, selectLinTableVariableValue, selectLinUserPoints } from './selectors';
// eslint-disable-next-line import/no-cycle
import loggerFactory from '../../../services/UILogger';
import {
  calculatePointDeviation,
  recalcDeviationFunctions,
  updateLinearizationTableValidity,
} from './common';
import { SplineFn } from './deviation';
import { decodeSplineBlob, encodeSplineBlob } from './splineBlob';
import { DatasetState } from '../../deviceInstances/store/deviceDataset/types';
import { deviceInstancesStoreSelector, linearizationDatasetStoreSelector } from '../../reduxStoreSelector';
import { writeActiveDeviceVariableValues } from '../../deviceInstances/middleware/activeDevice/actions';

const logger = loggerFactory.create('Linearization');

const createStatusValueRef = (deviceDataset: DatasetState, identRef: IdentRef, value: number|string): Services.DeviceModel.StatusValueRef => ({
  identRef,
  value,
  backupValue: deviceDataset.values[identRef].backupValue,
});

const GetUserPoints = (deviceDataset: DatasetState, linDataset: LinearizationDatasetState, userLinData: IdentRef, pointsCount: number): Services.DeviceModel.StatusValueRef[] => {
  const tableDescription = deviceDataset.descriptors[userLinData];
  if (tableDescription === undefined || tableDescription.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return [];
  }
  if (tableDescription.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.TABLE) {
    return [];
  }

  // The table definition must be correct
  const { table } = linDataset;
  const recValues: Services.DeviceModel.StatusValueRef[] = [];
  tableDescription.valueType.records
    .forEach((rec, index) => {
      const recDef = deviceDataset.descriptors[rec] as DeviceModelStatus.StatusDescriptor;
      const record = recDef.valueType as DeviceModelStatus.StatusDescriptorValueTypeTableRecord;
      const xValue = createStatusValueRef(deviceDataset, record.members[0], index >= pointsCount ? 0 : table.linearizationData[index].xValue);
      const yValue = createStatusValueRef(deviceDataset, record.members[1], index >= pointsCount ? 0 : table.linearizationData[index].yValue);
      recValues.push(xValue, yValue);
    });
  return recValues;
};

const GetUserMinY = (deviceDataset: DatasetState, linDataset: LinearizationDatasetState, identRef: IdentRef): Services.DeviceModel.StatusValueRef => {
  const { table } = linDataset;
  const value = table.linearizationData
    .slice(0, linDataset.pointsCounter.value)
    .reduce((acc, item) => (item.xValue < acc ? item.xValue : acc), table.linearizationData[0].xValue);
  return {
    identRef,
    value,
    backupValue: deviceDataset.values[identRef].backupValue,
  };
};

const GetUserMaxY = (deviceDataset: DatasetState, linDataset: LinearizationDatasetState, identRef: IdentRef): Services.DeviceModel.StatusValueRef => {
  const { table } = linDataset;
  const value = table.linearizationData
    .slice(0, linDataset.pointsCounter.value)
    .reduce((acc, item) => (item.xValue > acc ? item.xValue : acc), table.linearizationData[0].xValue);
  return {
    identRef,
    value,
    backupValue: deviceDataset.values[identRef].backupValue,
  };
};

const splineFn2SplineData = (splineFn: SplineFn[]): LinearizationSplineData[] => splineFn.map((fn) => ({
  Max: Math.max(...fn.points.map((pt) => pt[0])),
  A0: fn.equation[0],
  A1: fn.equation[1],
  A2: fn.equation[2],
}));

const calculateTableDeviation = (table: LinearizationTable, splineFn: SplineFn[], pointsCounter: number): LinearizationTable => {
  const pointsDeviation = calculatePointDeviation(table.linearizationData, splineFn, pointsCounter);
  return {
    ...table,
    linearizationData: pointsDeviation.map((deviationValue, index) => ({
      ...table.linearizationData[index],
      deviationValue,
    })),
  };
};

interface CalculationResult {
  pointsCounter: number;
  maxRegressionError: number;
  table: LinearizationTable;
  spline?: LinearizationSplineData[];
  splineCalculationState: SplineCalculationStatus;
  message?: LinTableMessage;
}

const calculateTableDeviationLineAndSpline = async (
  table: LinearizationTable,
  pointsCounter: number,
  maxRegressionError: number,
): Promise<CalculationResult> => new Promise<CalculationResult>((resolve) => {
  // Calculate spline
  const splineFn = recalcDeviationFunctions(table.linearizationData, pointsCounter, maxRegressionError);
  if (splineFn.length > 7) {
    resolve({
      pointsCounter,
      maxRegressionError,
      table,
      splineCalculationState: SplineCalculationStatus.error,
      message: {
        type: 'warning',
        text: 'LINTABLE__ERROR__MORE_THAN_7_SPLINE_FOUND',
      },
    });
    return;
  }

  const devtable = calculateTableDeviation(table, splineFn, pointsCounter);
  const spline = splineFn2SplineData(splineFn);

  resolve({
    pointsCounter,
    maxRegressionError,
    table: devtable,
    spline,
    splineCalculationState: SplineCalculationStatus.done,
  });
});

const recalculateTable = async (
  xtable: LinearizationTable,
  pointsCounter: number,
  maxRegressionError: number,
): Promise<CalculationResult> => {
  let result: CalculationResult;

  if (pointsCounter < 2) {
    return {
      table: xtable,
      pointsCounter,
      maxRegressionError,
      splineCalculationState: SplineCalculationStatus.error,
      message: {
        type: 'warning',
        text: 'LINTABLE__ERROR__POINTS_COUNTER_LESS_2',
      },
    };
  }

  if (maxRegressionError === 0) {
    return {
      table: xtable,
      pointsCounter,
      maxRegressionError,
      splineCalculationState: SplineCalculationStatus.error,
      message: {
        type: 'warning',
        text: 'LINTABLE__ERROR__MAX_REGRESSION_ERROR_ZERO',
      },
    };
  }

  const table = updateLinearizationTableValidity(xtable, pointsCounter);

  if (!table.isValid) {
    return {
      pointsCounter,
      maxRegressionError,
      table,
      splineCalculationState: SplineCalculationStatus.error,
      message: {
        type: 'warning',
        text: 'LINTABLE__ERROR__TABLE_NOT_VALID',
      },
    };
  }

  try {
    result = await calculateTableDeviationLineAndSpline(
      table,
      pointsCounter,
      maxRegressionError,
    );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    result = {
      pointsCounter,
      maxRegressionError,
      table,
      splineCalculationState: SplineCalculationStatus.error,
      message: {
        type: 'error',
        text: err.message,
      },
    };
  }
  return result;
};

export const setMessageTimeout = (api: MiddlewareAPI, action : typeLinearizationDatasetActionTypes, payload?: LinTableMessage) => {
  if (payload && payload.type !== 'error') {
    const timeout = payload.type === 'success' ? 5000 : 10000;

    setTimeout(() => {
      api.dispatch(action);
    }, timeout);
  }
};

export const linearizationDatasetMiddleware = (): Middleware => (api: MiddlewareAPI) => (next: Dispatch<typeLinearizationDatasetActionTypes>) => async <A extends typeLinearizationDatasetActionTypes>(action: A): Promise<A> => {
  switch (action.type) {
    case LINEARIZATION_DATASET__INIT_LINEARIZATION_CONTROL: {
      const { deviceInstanceId, controlIdent } = action.payload;
      logger.debug('Initialize linearization control', controlIdent);

      api.dispatch(setLinearizationState(ExecutionState.pending));

      const deviceInstances = deviceInstancesStoreSelector(api.getState());
      // TODO: Safe check
      const datasetState = deviceInstances.instances[deviceInstanceId]?.deviceDataset.user;
      const controlDescription: DeviceModelStatus.DeviceModelDescriptor = datasetState?.descriptors[controlIdent];

      if (datasetState === undefined || controlDescription === undefined
        || controlDescription.type !== DeviceModelStatus.StatusType.ControlDescriptor
        || controlDescription.controlType.type !== DeviceModelStatus.UI.ControlType.CTLLINEARIZATION) {
        api.dispatch(setLinearizationState(ExecutionState.failed));
        break;
      }

      const userPoints = selectLinUserPoints(datasetState, controlDescription.controlType);
      const minDeviationValue = selectLinTableVariableValue(datasetState, controlDescription.controlType, 'minDeviation') ?? 0;
      const minDeviationDisFormat = selectLinTableVariableDisplayFormat(datasetState, controlDescription.controlType, 'minDeviation') ?? '%.2f';

      const pointsCounterValue = selectLinTableVariableValue(datasetState, controlDescription.controlType, 'numberOfPoints') ?? 0;
      const pointsCounterDisFormat = selectLinTableVariableDisplayFormat(datasetState, controlDescription.controlType, 'numberOfPoints') ?? '%d';

      const coldJunctionComp0Value = selectLinTableVariableValue(datasetState, controlDescription.controlType, 'coldJunctionComp0');
      const coldJunctionComp0DisFormat = selectLinTableVariableDisplayFormat(datasetState, controlDescription.controlType, 'coldJunctionComp0') ?? '%.2f';

      const coldJunctionComp80Value = selectLinTableVariableValue(datasetState, controlDescription.controlType, 'coldJunctionComp80');
      const coldJunctionComp80DisFormat = selectLinTableVariableDisplayFormat(datasetState, controlDescription.controlType, 'coldJunctionComp80') ?? '%.2f';

      const sensorType = (coldJunctionComp0Value === undefined && coldJunctionComp80Value === undefined)
        ? 'RTD' : 'TC';

      const linTableItems: LinearizationTableItem[] = userPoints.map((pt) => ({
        xValue: pt.x.value,
        yValue: pt.y.value,
        xValueMin: pt.x.minValue ?? undefined,
        xValueMax: pt.x.maxValue ?? undefined,
        yValueMin: pt.y.minValue ?? undefined,
        yValueMax: pt.y.maxValue ?? undefined,
        deviationValue: 0,
        isXValid: true,
        isYValid: true,
      }));

      const tableRedux: LinearizationTable = {
        label: controlDescription.controlType.label,
        header: {
          header1: userPoints[0]?.x.unit,
          header2: userPoints[0]?.y.unit,
        },
        linearizationData: linTableItems,
        isValid: true,
      };

      const splineValueBase64 = datasetState.values[controlDescription.controlType.splineData].value as string;
      const splineData = decodeSplineBlob(splineValueBase64);

      const coldJunctionComp0: LinTableValue | undefined = coldJunctionComp0Value ? {
        value: coldJunctionComp0Value,
        displayFormat: coldJunctionComp0DisFormat,
      } : undefined;

      const coldJunctionComp80: LinTableValue | undefined = coldJunctionComp80Value ? {
        value: coldJunctionComp80Value,
        displayFormat: coldJunctionComp80DisFormat,
      } : undefined;

      api.dispatch(initLinearizationDataset({
        sensorType,
        coldJunctionComp0,
        coldJunctionComp80,
        pointsCounter: {
          value: pointsCounterValue,
          displayFormat: pointsCounterDisFormat,
        },
        maxRegressionError: {
          value: minDeviationValue === 0 ? 0.001 : minDeviationValue,
          displayFormat: minDeviationDisFormat,
        },
        table: tableRedux,
        spline: splineData,
      }));
      api.dispatch(showLinearizationSplineMessage());
    }
      break;
    case LINEARIZATION_DATASET__SAVE_TO_DATASET: {
      const { controlIdent, deviceInstanceId } = action.payload;
      logger.debug('Initialize linearization control', controlIdent);

      api.dispatch(setLinearizationState(ExecutionState.pending));

      const deviceInstances = deviceInstancesStoreSelector(api.getState());
      const deviceDataset = deviceInstances.instances[deviceInstanceId]?.deviceDataset.user as DatasetState;
      const linearizationDataset = linearizationDatasetStoreSelector(api.getState());
      const controlDescription: DeviceModelStatus.DeviceModelDescriptor = deviceDataset.descriptors[controlIdent];

      if (controlDescription === undefined
        || controlDescription.type !== DeviceModelStatus.StatusType.ControlDescriptor
        || controlDescription.controlType.type !== DeviceModelStatus.UI.ControlType.CTLLINEARIZATION) {
        api.dispatch(setLinearizationState(ExecutionState.failed));
        break;
      }

      let linValues: Services.DeviceModel.StatusValueRef[] = [
        createStatusValueRef(deviceDataset, controlDescription.controlType.numberOfPoints, linearizationDataset.pointsCounter.value),
        createStatusValueRef(deviceDataset, controlDescription.controlType.minDeviation, linearizationDataset.maxRegressionError.value),
      ];

      if (controlDescription.controlType.coldJunctionComp0 && linearizationDataset.coldJunctionComp0) {
        linValues = [
          ...linValues,
          createStatusValueRef(deviceDataset, controlDescription.controlType.coldJunctionComp0, linearizationDataset.coldJunctionComp0.value),
        ];
      }

      if (controlDescription.controlType.coldJunctionComp80 && linearizationDataset.coldJunctionComp80) {
        linValues = [
          ...linValues,
          createStatusValueRef(deviceDataset, controlDescription.controlType.coldJunctionComp80, linearizationDataset.coldJunctionComp80.value),
        ];

        if (controlDescription.controlType.coldLineEquationB && controlDescription.controlType.coldLineEquationM) {
          const m = linearizationDataset.coldJunctionComp80.value / 80;

          linValues = [
            ...linValues,
            createStatusValueRef(deviceDataset, controlDescription.controlType.coldLineEquationM, m),
            createStatusValueRef(deviceDataset, controlDescription.controlType.coldLineEquationB, 0),
          ];
        }
      }

      // Collect user points
      const userPointValues = GetUserPoints(deviceDataset, linearizationDataset, controlDescription.controlType.userLinData, linearizationDataset.pointsCounter.value);

      // Calculate UserCharMinX and UserCharMaxX
      const minXValue = GetUserMinY(deviceDataset, linearizationDataset, controlDescription.controlType.userCharMinX);
      const maxXValue = GetUserMaxY(deviceDataset, linearizationDataset, controlDescription.controlType.userCharMaxX);

      const splineLength = ((
        deviceDataset.descriptors[controlDescription.controlType.splineData] as DeviceModelStatus.StatusDescriptor)
        .valueType as DeviceModelStatus.StatusDescriptorValueTypeBlob).length;
      const splineData = linearizationDataset.spline;
      const splineValueBase64 = encodeSplineBlob(splineData, splineLength);

      const data = [
        ...linValues,
        ...userPointValues,
        minXValue,
        maxXValue,
        createStatusValueRef(deviceDataset, controlDescription.controlType.splineData, splineValueBase64),
      ];
      logger.debug('writeActiveDeviceVariableValues', data);
      api.dispatch(writeActiveDeviceVariableValues(deviceInstanceId, data));
    }
      break;
    case LINEARIZATION_DATASET__SET_POINT_COUNTER: {
      const pointsCounter = action.payload as number;
      const linearizationDataset = linearizationDatasetStoreSelector(api.getState());
      const { table, maxRegressionError } = linearizationDataset;
      api.dispatch(setSplineCalculationStatus(SplineCalculationStatus.inprogress));

      const result = await recalculateTable(
        table,
        pointsCounter,
        maxRegressionError.value,
      );
      api.dispatch(updateLinearizationDataset(result));
      api.dispatch(showLinearizationSplineMessage(result.message));
      logger.debug('set point counter');
    }
      break;
    case LINEARIZATION_DATASET__SET_POINT_X: {
      const { payload } = action as SetPointXAction;
      const linearizationDatasetState = linearizationDatasetStoreSelector(api.getState());
      api.dispatch(setSplineCalculationStatus(SplineCalculationStatus.inprogress));

      // Update x-value
      const linearizationData = linearizationDatasetState.table.linearizationData
        .map((point, idx) => ((idx === payload.index) ? ({
          ...point,
          xValue: payload.value,
        }) : point));

      const result = await recalculateTable(
        {
          ...linearizationDatasetState.table,
          linearizationData,
        },
        linearizationDatasetState.pointsCounter.value,
        linearizationDatasetState.maxRegressionError.value,
      );
      api.dispatch(updateLinearizationDataset(result));
      api.dispatch(showLinearizationSplineMessage(result.message));
    }
      break;
    case LINEARIZATION_DATASET__SET_POINT_Y: {
      const { payload } = action as SetPointYAction;
      const linearizationDatasetState = linearizationDatasetStoreSelector(api.getState());
      api.dispatch(setSplineCalculationStatus(SplineCalculationStatus.inprogress));

      // Update y-value
      const linearizationData = linearizationDatasetState.table.linearizationData
        .map((point, idx) => ((idx === payload.index) ? ({
          ...point,
          yValue: payload.value,
        }) : point));

      const result = await recalculateTable(
        {
          ...linearizationDatasetState.table,
          linearizationData,
        },
        linearizationDatasetState.pointsCounter.value,
        linearizationDatasetState.maxRegressionError.value,
      );
      api.dispatch(updateLinearizationDataset(result));
      api.dispatch(showLinearizationSplineMessage(result.message));
    }
      break;
    case LINEARIZATION_DATASET__SET_USER_POINTS: {
      const { payload } = action as SetLinearizationUserPointsAction;
      logger.debug('LINEARIZATION_DATASET__SET_USER_POINTS', payload);
      const pointsCounter = payload.length;
      const linearizationDatasetState = linearizationDatasetStoreSelector(api.getState());
      const { table, maxRegressionError } = linearizationDatasetState;
      api.dispatch(setSplineCalculationStatus(SplineCalculationStatus.inprogress));

      const linearizationData = table.linearizationData
        .map((point, idx) => ((idx < pointsCounter) ? ({
          ...point,
          xValue: payload[idx].xValue,
          yValue: payload[idx].yValue,
        }) : point));

      const result = await recalculateTable(
        {
          ...table,
          linearizationData,
        },
        pointsCounter,
        maxRegressionError.value,
      );
      api.dispatch(updateLinearizationDataset(result));
      if (result.message) {
        api.dispatch(showLinearizationSplineMessage(result.message));
      }
    }
      break;
    case LINEARIZATION_DATASET__MAX_REGRESSION_ERROR: {
      const { payload } = action as SetLinMaxRegressionErrorAction;
      const linearizationDatasetState = linearizationDatasetStoreSelector(api.getState());
      const { table, pointsCounter } = linearizationDatasetState;
      const maxRegressionError = payload;
      api.dispatch(setSplineCalculationStatus(SplineCalculationStatus.inprogress));

      const result = await recalculateTable(
        table,
        pointsCounter.value,
        maxRegressionError,
      );
      api.dispatch(updateLinearizationDataset({
        ...result,
        maxRegressionError,
      }));
      api.dispatch(showLinearizationSplineMessage(result.message));
    }
      break;
    case LINEARIZATION_DATASET__SHOW_SPLINE_MESSAGE: {
      const { payload } = action as ShowLinTableSplineMessageAction;
      api.dispatch(setLinearizationSplineMessage(payload));

      setMessageTimeout(api, setLinearizationSplineMessage(), payload);
    }
      break;
    case LINEARIZATION_DATASET__SHOW_LINEARIZATION_MESSAGE: {
      const { payload } = action as ShowLinTableLinearizationMessageAction;
      api.dispatch(setLinearizationMessage(payload));

      setMessageTimeout(api, setLinearizationMessage(), payload);
    }
      break;
    default:
  }
  const result = next(action);
  return result;
};
