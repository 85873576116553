/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { LoadingSpinner } from '../../components/LoadingControl/LoadingSpinner';
import DownloadLinkButton from './DownloadLinkButton/DownloadLinkButton';
import { LocalBackendVersionStatus } from '../hook/useBackendVersion';
import useAppTranslation from './hooks/useAppTranslation';
import './LoadingSplashScreen.css';

export interface LoadingSplashScreenProps {
  status: LocalBackendVersionStatus;
  showDownloadButton: boolean;
}

const LoadingSplashScreen: React.FC<LoadingSplashScreenProps> = (props: LoadingSplashScreenProps): React.ReactElement => {
  const appTranslation = useAppTranslation();
  const { status, showDownloadButton } = props;

  const downloadLinkButton = showDownloadButton
    ? (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <DownloadLinkButton status={status} />
      </div>
    )
    : <></>;

  return (
    <div className="loading-splash-screen w-100 h-100 d-flex flex-column align-items-center justify-content-center">
      <div className="loading-splash-screen__header">
        {appTranslation('hdr_connection_to_server')}
      </div>
      <div className="loading-splash-screen__spinner d-flex flex-column align-items-center justify-content-center">
        <LoadingSpinner size="splash" />
      </div>
      <div className="loading-splash-screen__text">
        {appTranslation('txt_connecting_to_cxe')}
      </div>
      {downloadLinkButton}
    </div>
  );
};

export default LoadingSplashScreen;
