/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useState, useEffect } from 'react';
import AppSystemService from '../../services/AppService/AppSystemService';
import { compensateZooming } from '../../helpers/functions';
import { setApplicationZoomLevel } from '../Backstage/BackstagePages/BackstagePageServices';
import './ZoomControl.scss';

const MIN_ZOOM = 30;
const MAX_ZOOM = 250;
const STEP = 10;
const DEFAULT_ZOOM = 100;

const ZoomControl: React.FC = () => {
  const [zoomValue, setZoomValue] = useState(DEFAULT_ZOOM);

  useEffect(() => AppSystemService.onZoomChange((zoom) => setZoomValue(Math.round(zoom))), []);

  return (
    <div className="zoom-control">
      <button
        data-testid="zoom-minus-button"
        type="button"
        className="zoom-control-button"
        onClick={() => {
          const newValue = (zoomValue - STEP) < MIN_ZOOM ? MIN_ZOOM : zoomValue - STEP;
          setApplicationZoomLevel(newValue / 100)
            .then(() => compensateZooming(newValue / 100));
        }}
      >
        -
      </button>
      <span data-testid="zoom-percentage" className="zoom-control-percentage">
        {zoomValue}
        {' '}
        %
      </span>
      <button
        data-testid="zoom-plus-button"
        type="button"
        className="zoom-control-button"
        onClick={() => {
          const newValue = (zoomValue + STEP) > MAX_ZOOM ? MAX_ZOOM : zoomValue + STEP;
          setApplicationZoomLevel(newValue / 100)
            .then(() => compensateZooming(newValue / 100));
        }}
      >
        +
      </button>
    </div>
  );
};

export default ZoomControl;
