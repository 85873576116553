/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus } from '@gpt/commons';
import { useContextDatasetStatusDescriptor } from '../../../../hooks/useContextDataset';
import useStatusValueStateUpdate from '../../hooks/useStatusValueStateUpdate';
import { Quint4CharacteristicSelectorProps } from '../CharacteristicSelector/Quint4CharacteristicSelector';
import { Quint4ImageSelectorItem } from './Quint4ImageSelectorItem/Quint4ImageSelectorItem';
import UiAdvancedImage from './assets/image_Quint4_Mode_Ui_Advanced.svg';
import smartHiccupImage from './assets/image_Quint4_Mode_Hiccup.svg';
import fuseModeImage from './assets/image_Quint4_Mode_Fuse.svg';
import secureShutdownImage from './assets/secureShutdown.png';
import { MenuItemRowControl, MenuItemRowItem } from '../../../../components/MenuItemRowControl';
import { setContextParameterHelp } from '../../../../store/contexthelp';

const modeImageMap = {
  1: UiAdvancedImage,
  2: fuseModeImage,
  4: smartHiccupImage,
  3: secureShutdownImage,
};

interface ImageSelectorRowItem {
  value: number;
  label: string
  image: string
  description?: string
}

interface ImageSelectorRow {
  item1: ImageSelectorRowItem;
  item2: ImageSelectorRowItem;
}

const Quint4CharacteristicImageSelector2
  :React.FC<Quint4CharacteristicSelectorProps> = (props: Quint4CharacteristicSelectorProps):
    React.ReactElement => {
    const {
      deviceInstanceId,
      statusValue,
      onStateChanged,
      onValueChange,
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const statusDescriptor = useContextDatasetStatusDescriptor(deviceInstanceId, statusValue?.identRef ?? '-');
    const visibility = useStatusValueStateUpdate(statusValue?.identRef ?? '-', deviceInstanceId, undefined, onStateChanged);

    if (statusValue === undefined) {
      return <></>;
    }

    if (visibility === false) {
      return <></>;
    }

    if (statusDescriptor?.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR) {
      return <></>;
    }

    const defaultRowItem: ImageSelectorRowItem = {
      value: 0,
      label: '',
      image: '',
    };

    const { valueType } = statusDescriptor;
    const imageRows = valueType.enumerator.reduce((acc, enumItem, index) => {
      const rowNo = Math.floor(index / 2);
      const image = modeImageMap[enumItem.value] ?? UiAdvancedImage;

      const rowItem: ImageSelectorRowItem = {
        value: enumItem.value,
        description: enumItem.description === undefined ? undefined : (t(enumItem.description) ?? undefined),
        label: t(enumItem.label),
        image,
      };

      if (index % 2 === 0) {
        return [
          ...acc,
          {
            item1: rowItem,
            item2: defaultRowItem,
          },
        ];
      }

      return acc.map((item, idx) => ((idx === rowNo)
        ? {
          ...item,
          item2: rowItem,
        } : item));
    }, [] as ImageSelectorRow[]);

    const statusHelp = statusDescriptor.help === undefined ? '' : t(statusDescriptor.help);
    const options = imageRows.map((rowItem) => {
      const createMenuItemRowElement = (item: ImageSelectorRowItem, customClassName: string) => (
        <Quint4ImageSelectorItem
          value={statusValue.value === item.value}
          title={item.label}
          image={item.image}
          onChange={() => onValueChange(item.value)}
          customClassName={customClassName}
          onFocus={() => {
            dispatch(setContextParameterHelp({
              title: item.label === undefined ? '' : t(item.label),
              text: statusHelp,
            }));
          }}
          onBlur={() => {
            dispatch(setContextParameterHelp({
              title: '',
              text: '',
            }));
          }}
        />
      );

      const item1 = createMenuItemRowElement(rowItem.item1, 'd-flex flex-column align-items-center justify-content-end mb-4');
      const item2 = createMenuItemRowElement(rowItem.item2, 'd-flex flex-column align-items-center justify-content-start mb-4');
      return (
        <MenuItemRowControl key={`quint4--${rowItem.item1.value}-${rowItem.item2.value}--${statusValue.identRef}`}>
          <MenuItemRowItem width={6} customClassName="d-flex align-items-center justify-content-end">
            {item1}
          </MenuItemRowItem>
          <MenuItemRowItem width={6} customClassName="d-flex align-items-center justify-content-start">
            {item2}
          </MenuItemRowItem>
        </MenuItemRowControl>
      );
    });

    return (
      <>{options}</>
    );
  };

export default Quint4CharacteristicImageSelector2;
