/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
**************************************************************************** */
import {
  DatasetIdent,
} from './common';

import {
  DatasetFilter,
  DatasetProviderConfiguration,
  ServiceFullname, ServiceName, ServiceVersion,
} from './DatasetProviderService';

import {
  EXTERNAL_DATASET__VERSION,
  ExternalDataset, ExternalDatasetValues,
  ExternalDatasetDescriptors, ExternalDatasetHeader,
  createDatasetIdent,
  ExternalDatasetType,
} from './externalDataset';

import {
  GetDatasetListRequest, GetDatasetListResponse,
  DatasetProviderRequestType,
  DPS_GET_DATASETLIST_REQUEST, DPS_GET_DATASETLIST_RESPONSE,
  DPS_GET_DATASET_REQUEST, DPS_GET_DATASET_RESPONSE,
  GetDatasetRequest, GetDatasetResponse,
} from './types';

import {
  CreateGetDatasetListRequest,
  CreateGetDatasetRequest,
} from './requests';

export type {
  DatasetIdent,
  DatasetFilter,
  DatasetProviderConfiguration,
  GetDatasetListRequest, GetDatasetListResponse,
  DatasetProviderRequestType,
  GetDatasetRequest, GetDatasetResponse,
  ExternalDataset, ExternalDatasetValues,
  ExternalDatasetDescriptors, ExternalDatasetHeader,
};

export {
  ServiceFullname, ServiceName, ServiceVersion,
  DPS_GET_DATASETLIST_REQUEST, DPS_GET_DATASETLIST_RESPONSE,
  DPS_GET_DATASET_REQUEST, DPS_GET_DATASET_RESPONSE,
  EXTERNAL_DATASET__VERSION,
  ExternalDatasetType,
  CreateGetDatasetListRequest,
  CreateGetDatasetRequest,
  createDatasetIdent,
};
