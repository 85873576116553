/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import * as DeviceModelStatus from './devicemodel';
import * as Services from './services/index.services';
import * as WebDevice from './webdevice';

import ServiceCollection from './instantiation/serviceCollection';

export * from './instantiation/instantiation';
export * from './services';
export * from './helpers';

// UI <-> Electron
export * from './ui/UIDeviceScoutService';
export * from './reports/report';

export {
  // Services
  DeviceModelStatus,
  Services,
  ServiceCollection,
  WebDevice,
};
