/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import { DeviceModelStatus } from '@gpt/commons';
import { DatasetState } from '../../deviceInstances/store/deviceDataset/types';

export interface RecordValueType {
  value: number;
  minValue?: number;
  maxValue?: number;
  unit: string;
}

export interface RecordPointType {
  x: RecordValueType;
  y: RecordValueType;
}

export const selectLinTableDescription = (
  datasetState: DatasetState,
  lincontrol: DeviceModelStatus.UI.LinearizationControlType,
): DeviceModelStatus.StatusDescriptor | undefined => {
  const tableDesc = datasetState.descriptors[lincontrol.userLinData];
  if (tableDesc?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }

  return tableDesc.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.TABLE ? undefined : tableDesc;
};

export const selectLinTableVariableType = (
  datasetState: DatasetState,
  lincontrol: DeviceModelStatus.UI.LinearizationControlType,
): DeviceModelStatus.StatusDescriptorValueTypeTable | undefined => {
  const tableDesc = datasetState.descriptors[lincontrol.userLinData];
  if (tableDesc?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }

  return tableDesc.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.TABLE ? undefined : tableDesc.valueType;
};

const defaultRecordPoint: RecordValueType = {
  value: 0, minValue: 0, maxValue: 1000, unit: '',
};

export const selectLinUserPoints = (
  datasetState: DatasetState,
  lincontrol: DeviceModelStatus.UI.LinearizationControlType,
): RecordPointType[] => {
  const columnValue = (varRef: string) : RecordValueType => {
    const desc = datasetState.descriptors[varRef];
    if (desc.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return defaultRecordPoint;
    }
    const { valueType } = desc;
    if (valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT) {
      return defaultRecordPoint;
    }
    return {
      value: datasetState.values[varRef].value as number,
      minValue: valueType.range?.regions[0].minValue,
      maxValue: valueType.range?.regions[0].maxValue,
      unit: desc.unit ?? '',
    };
  };

  const table = selectLinTableDescription(datasetState, lincontrol);

  const recordsData = (table?.valueType as DeviceModelStatus.StatusDescriptorValueTypeTable).records ?? [];

  return recordsData
    .map((item) => {
      const desc = datasetState.descriptors[item];
      if (desc?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
        return { x: defaultRecordPoint, y: defaultRecordPoint };
      }

      if (desc.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.TABLERECORD) {
        return { x: defaultRecordPoint, y: defaultRecordPoint };
      }
      const record = desc.valueType as DeviceModelStatus.StatusDescriptorValueTypeTableRecord;
      const xValue = columnValue(record.members[0]);
      const yValue = columnValue(record.members[1]);
      return { x: xValue, y: yValue };
    });
};

export const compareLinUserPoints = (prev: RecordPointType[], curr: RecordPointType[]): boolean => {
  const previousPointSum = prev.reduce((total, nextItem) => total + nextItem.x.value + nextItem.y.value, 0);
  const currentPointSum = curr.reduce((total, nextItem) => total + nextItem.x.value + nextItem.y.value, 0);
  return previousPointSum === currentPointSum;
};

export const selectLinTableVariableValue = (
  deviceDataset: DatasetState,
  lincontrol: DeviceModelStatus.UI.LinearizationControlType,
  member: 'minDeviation' | 'coldJunctionComp0' | 'coldJunctionComp80' | 'numberOfPoints' | 'coldLineEquationB' | 'coldLineEquationM',
): number | undefined => {
  const memIdent = lincontrol[member];
  if (memIdent === undefined) {
    return undefined;
  }
  const memDescriptor = deviceDataset.descriptors[memIdent];

  if (memDescriptor === undefined || memDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }

  if (memDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT
    && memDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.INTEGER
    && memDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER) {
    return undefined;
  }

  if (deviceDataset.values[memIdent] === undefined) {
    return undefined;
  }

  return deviceDataset.values[memIdent].value as number;
};

export const selectLinTableVariableDisplayFormat = (
  deviceDataset: DatasetState,
  lincontrol: DeviceModelStatus.UI.LinearizationControlType,
  member: 'minDeviation' | 'coldJunctionComp0' | 'coldJunctionComp80' | 'numberOfPoints' | 'coldLineEquationB' | 'coldLineEquationM',
): string | undefined => {
  const memIdent = lincontrol[member];
  if (memIdent === undefined) {
    return undefined;
  }
  const memDescriptor = deviceDataset.descriptors[memIdent];

  if (memDescriptor === undefined || memDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }

  if (memDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT
    && memDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.INTEGER
    && memDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER) {
    return undefined;
  }
  return memDescriptor.valueType.displayFormat;
};

export const selectLinTableVariableBlobVar = (
  deviceDataset: DatasetState,
  lincontrol: DeviceModelStatus.UI.LinearizationControlType,
): DeviceModelStatus.StatusDescriptorValueTypeBlob | undefined => {
  const memDescriptor = deviceDataset.descriptors[lincontrol.splineData];

  if (memDescriptor === undefined || memDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }

  if (memDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.BLOB) {
    return undefined;
  }
  return memDescriptor.valueType;
};
