/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

// eslint-disable-next-line import/prefer-default-export
export enum WizardType {
    CHANGEDEVICE='CHANGEDEVICE',
    INITIALDEVICESTARTUP='INITIALDEVICESTARTUP',
    CREATEPARAMETERFILE='CREATEPARAMETERFILE',
    TRANSFERPARAMETERFILE='TRANSFERPARAMETERFILE',
    CXEEDITPARAMETERFILE='CXEEDITPARAMETERFILE',
    RESTOREDEVICE='RESTOREDEVICE',
    DEVICESETTINGS='DEVICESETTINGS',
    CXE_FIRMWARE_PARAMETER='CXE_FIRMWARE_PARAMETER',
    CXE_HARDWARE_SWITCH = 'CXE_HARDWARE_SWITCH',
    CONTACTRON_STATION_COMMISSIONING = 'CONTACTRON_STATION_COMMISSIONING',
}
