/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext } from 'react';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { ReduxControlProps } from '../../ReduxControlProps';
import { useTypedSelector } from '../../../store';
import { DeviceInstancesState } from '../../../store/deviceInstances/types';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import controlTypeSelector from '../../selectors/controlTypeSelector';
import { DatasetContext } from '../../../views/DatasetContext';
import Quint4EventCheckbox from './Quint4EventCheckbox/Quint4EventCheckbox';
import './Quint4EventListControl.scss';

const customControlTypeSelector = (deviceInstances: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: IdentRef)
:DeviceModelStatus.UI.CustomControlControlType | undefined => {
  const dataset = deviceTargetDatasetSelector(deviceInstances, deviceInstanceId, targetDataset);
  const control = controlTypeSelector<DeviceModelStatus.UI.CustomControlControlType>(DeviceModelStatus.UI.ControlType.CTLCUSTOMCONTROL, identRef, dataset);
  return control;
};

const Quint4EventListControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
  : React.ReactElement => {
  const { deviceInstanceId, onStateChanged, identRef } = props;
  const { targetDataset } = useContext(DatasetContext);

  const controlType = useTypedSelector((state) => customControlTypeSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef));

  const eventList = Object.values(controlType?.settings ?? {}).map((param) => {
    const eventVariableIdentRef = param as string;
    return (
      <div className="quint4-event-list-control__item w-100" key={`quint4-event--${deviceInstanceId}--${eventVariableIdentRef}`}>
        <Quint4EventCheckbox
          identRef={eventVariableIdentRef}
          deviceInstanceId={deviceInstanceId}
          onShowContentHelp={() => 0}
          onHideContentHelp={() => 0}
          onStateChanged={onStateChanged}
        />
      </div>
    );
  });

  return (
    <div className="quint4-event-list-control d-flex flex-column align-items-start justify-content-start">
      {eventList}
    </div>
  );
};

export default Quint4EventListControl;
