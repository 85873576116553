/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

import { IService } from '../services/Service';
import { ServiceIdentifier } from './instantiation';

export default class ServiceCollection {
  private serviceMap = new Map<string, IService>();

  set = <T extends IService>(ident: ServiceIdentifier<T>, instance: T) => {
    this.serviceMap.set(ident.id, instance);
  };

  get = <T extends IService>(ident: ServiceIdentifier<T>):
    T => this.serviceMap.get(ident.id) as T;

  delete = <T extends IService>(ident: ServiceIdentifier<T>):
    boolean => this.serviceMap.delete(ident.id);

  clear = () => this.serviceMap.clear();

  services = (): IService[] => Array.from(this.serviceMap, ([key, value]) => value);

  count = (): number => this.serviceMap.size;

  get size(): number { return this.count(); }
}
