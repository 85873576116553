/* ********************************************************************************************** */
/* NATS STATION CONFIGURATION */

import { DeviceModelStatus, Services } from '@gpt/commons';
import { typeDeviceMethodStageMiddlewareActions } from '../../deviceInstances/middleware';
import { executeMethodStageInit } from '../../deviceInstances/middleware/deviceMethod/actions';
import { ContactronModuleInputOutput, ContactronStationWizardState } from '../../wizards/contactronStation';
import { DatasetState } from '../../deviceInstances/store/deviceDataset/types';
import { WRITE_CONFIGURATION_METHOD_IDENT } from '../../../wizards/Commissioning/Contactron/types';

/* ********************************************************************************************** */
export interface NatsStationInputOutputDataType
{
  name: string;
  address: number;
  profile: number;
  scale: number;
}

export interface NatsStationDeviceTagDataType
{
  value: string;
  address: number;
  length: number;
}

export interface NatsStationModule
{
  name: string;
  tag: NatsStationDeviceTagDataType;
  inputData: NatsStationInputOutputDataType[];
  outputData: NatsStationInputOutputDataType[];
  address: number;
}

export interface NatsContactronStation
{
  pinCode: number;
  station: NatsStationModule;
  modules: NatsStationModule[];
}

const createStationModuleInputOutputData = (
  address: number,
  moduleInputOutput: ContactronModuleInputOutput,
  dataList: string[],
  dataCollection: DeviceModelStatus.Wizards.ContactronStation.DeviceTagDataCollection,
): NatsStationInputOutputDataType[] => {
  const data = dataList.reduce((acc, key) => {
    const deviceData = dataCollection[key];
    if (deviceData === undefined) {
      return acc;
    }
    if (!deviceData.writable) {
      return acc;
    }
    const checkboxValue = moduleInputOutput.dataCheckbox[key] ?? deviceData.checked;
    if (checkboxValue === false) {
      return acc;
    }

    return [
      ...acc,
      {
        name: deviceData.name.replace('*', `${address}`),
        address: deviceData.address,
        profile: deviceData.profile,
        scale: deviceData.scale,
      },
    ];
  }, [] as NatsStationInputOutputDataType[]);
  return data;
};

const createStationModuleDescription = (
  address: number,
  wizardState: ContactronStationWizardState,
  wizardConfig: DeviceModelStatus.Wizards.ContactronStation.ContactronStationCommissioningType,
  tagValue?: string,
): NatsStationModule | undefined => {
  const moduleKey = wizardState
    .moduleList.find((mod) => wizardState.modules[mod].instance.address === address);
  if (moduleKey === undefined) {
    return undefined;
  }
  const module = wizardState.modules[moduleKey];
  const moduleDescription = wizardConfig.deviceList[module.moduleId];
  if (moduleDescription === undefined) {
    return undefined;
  }

  const inputData = createStationModuleInputOutputData(
    module.instance.address,
    module.input,
    moduleDescription.inputDataList,
    moduleDescription.inputDataDescription,
  );
  const outputData = createStationModuleInputOutputData(
    module.instance.address,
    module.output,
    moduleDescription.outputDataList,
    moduleDescription.outputDataDescription,
  );

  return {
    address,
    name: moduleDescription.deviceIdent.deviceName.replace('*', `${address}`),
    tag: {
      address: moduleDescription.deviceTag.address,
      length: moduleDescription.deviceTag.length,
      value: tagValue ?? moduleDescription.deviceTag.defaultTag,
    },
    inputData,
    outputData,
  };
};

export const createStationConfiguration = (
  pinCode: number,
  wizardState: ContactronStationWizardState,
  wizardConfig: DeviceModelStatus.Wizards.ContactronStation.ContactronStationCommissioningType,
  datasetState: DatasetState,
): NatsContactronStation | undefined => {
  const tagValue = datasetState.values.IFSM_ADDR_APPL_AKZ?.value;
  const station = createStationModuleDescription(0, wizardState, wizardConfig, tagValue);
  if (station === undefined) {
    return undefined;
  }
  const modules = wizardState.moduleList.reduce((acc, mod) => {
    const module = wizardState.modules[mod];
    if (module.instance.address === 0) {
      return acc;
    }

    const moduleTagName = `DEVICE${module.instance.address}_IFSM_ADDR_AKZ`;
    const moduleTagValue = datasetState.values[moduleTagName]?.value;
    const mod1 = createStationModuleDescription(
      module.instance.address,
      wizardState,
      wizardConfig,
      moduleTagValue,
    );
    return mod1 === undefined
      ? acc
      : [
        ...acc,
        mod1,
      ];
  }, [] as NatsStationModule[]);
  return {
    pinCode,
    station,
    modules,
  };
};

export const writeStationConfiguration = (
  stationInstanceId: string,
  methodStatusRef: string,
  stationConfiguration: NatsContactronStation,
  values: Services.DeviceModel.StatusValueRef[],
): typeDeviceMethodStageMiddlewareActions => executeMethodStageInit(stationInstanceId, {
  methodIdent: WRITE_CONFIGURATION_METHOD_IDENT,
  methodStatusRef,
  values: [
    ...values,
    {
      identRef: 'JSON',
      value: JSON.stringify(stationConfiguration),
      backupValue: undefined,
    },
  ],
});
