/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import { WebDeviceRequestBase, WebDeviceResponseBase } from './types';

export const WEBDEVICE__UPDATE_CONNECTION_STATUS__REQUEST = 'WEBDEVICE__UPDATE_CONNECTION_STATUS__REQUEST';
export const WEBDEVICE__UPDATE_CONNECTION_STATUS__RESPONSE = 'WEBDEVICE__UPDATE_CONNECTION_STATUS__RESPONSE';

export interface WebDeviceUpdateConnectionRequest extends WebDeviceRequestBase {
  kind: typeof WEBDEVICE__UPDATE_CONNECTION_STATUS__REQUEST;
  connected: boolean;
}

export interface WebDeviceUpdateConnectionResponse extends WebDeviceResponseBase {
  kind: typeof WEBDEVICE__UPDATE_CONNECTION_STATUS__RESPONSE;
}
