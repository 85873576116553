/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { openBackstage } from '../../../../components/Backstage';
import IconGearBlue from '../../../../assets/icons/icon-gear-blue.svg';
import './GearButton.scss';

const GearButton: React.FC = () : React.ReactElement => (
  <div className="gearbutton">
    <button
      type="button"
      onClick={() => { openBackstage(); }}
    >
      <img
        src={IconGearBlue}
        alt="settings"
      />
    </button>
  </div>
);

export default GearButton;
