/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useContext } from 'react';
import isElectron from 'is-electron';
import App from '../App';
import LoadingSplashScreen from './LoadingSplashScreen/LoadingSplashScreen';
import { LocalBackendVersionStatus, useBackendVersion } from './hook/useBackendVersion';
import { ServiceProcessResponse, useCheckBackendConnection } from './hook/useCheckBackendConnection';
import { NatsConnectionContext, NatsServerConnectionState } from '../natsConnection';

const AppSplashController: React.FC = (): React.ReactElement => {
  let splashControl: React.ReactElement;
  const backendVersion = useBackendVersion();
  const backendConnection = useCheckBackendConnection(backendVersion);
  const { state } = useContext(NatsConnectionContext);

  if (state !== NatsServerConnectionState.Connected) {
    const shoDownloadButton = !isElectron();
    return <LoadingSplashScreen status={backendVersion} showDownloadButton={shoDownloadButton} />;
  }

  if (isElectron()) {
    splashControl = <App />;
  } else if (backendVersion === LocalBackendVersionStatus.Compatible) {
    if (backendConnection === ServiceProcessResponse.Ok) {
      splashControl = <App />;
    } else {
      splashControl = <LoadingSplashScreen status={backendVersion} showDownloadButton />;
    }
  } else if (backendVersion === LocalBackendVersionStatus.NotCompatible) {
    splashControl = <LoadingSplashScreen status={backendVersion} showDownloadButton />;
  } else {
    splashControl = <LoadingSplashScreen status={backendVersion} showDownloadButton />;
  }
  return <>{splashControl}</>;
};

export default AppSplashController;
