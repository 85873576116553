/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus } from '@gpt/commons';
import React, { useContext } from 'react';
import { ReduxControlProps } from '../ReduxControlProps';
import { useTypedSelector } from '../../store';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import { DatasetContext } from '../../views/DatasetContext';
import withControlVisibility from '../hoc/withControlVisibility';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import { ComponentFactory } from '../ComponentFactory/ComponentFactory';

const CustomDeviceControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement | null => {
  const {
    identRef, deviceInstanceId, onStateChanged, readonly,
  } = props;
  const { targetDataset } = useContext(DatasetContext);

  const ctrlType = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    if (dataset === undefined) {
      return undefined;
    }

    const desc = dataset.descriptors[identRef];
    if (desc?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
      return undefined;
    }
    const { controlType } = desc;
    return controlType?.type === DeviceModelStatus.UI.ControlType.CTLCUSTOMCONTROL
      ? controlType.controlType
      : undefined;
  });

  if (ctrlType === undefined) {
    return <UnsupportedControl text={`CustomDeviceControl [${identRef}]: undefined object`} />;
  }

  const component = ComponentFactory({
    identRef,
    deviceInstanceId,
    control: ctrlType,
    key: `custom--${ctrlType}--${identRef}`,
    onStateChanged,
    readonly,
  });
  return <>{component}</>;
};

export default withControlVisibility(CustomDeviceControl);
