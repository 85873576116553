/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useCallback, useEffect, useState } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setContextParameterHelp, setContextParameterWarning } from '../../../../store/contexthelp';
import { rangeAsText } from './common';

const useFloatMessageContextHelp = (
  statusDescriptor: DeviceModelStatus.StatusDescriptor,
  statusValue?: DeviceModelStatus.StatusValue,
  showContextMessageDef?: boolean,
  onShowContentHelp?: () => void,
  onHideContentHelp?: () => void,
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
  const [showContextMessage, setShowContextMessage] = useState<boolean>(showContextMessageDef ?? false);

  const statusValueLabel = t(statusDescriptor.label);
  const statusValueHelp = t(statusDescriptor.help);

  const hideContentHelp = useCallback((): void => {
    setShowContextMessage(false);
    dispatch(setContextParameterWarning(undefined));
    onHideContentHelp?.();
  }, [onHideContentHelp]);

  const showContentHelp = useCallback((): void => {
    setShowContextMessage(true);

    if (statusDescriptor !== undefined) {
      const range = DeviceModelStatus.Functions.GetDescriptorRange(statusDescriptor);
      let message = '';
      if (range) {
        const messageText = range.rangeAsText ?? rangeAsText(range.regions);
        message = t<string>('PARAMETER__VALID_VALUE_RANGE__HELP', {
          INTERVAL: messageText,
        });
      }

      dispatch(setContextParameterHelp({
        title: statusValueLabel,
        text: statusValueHelp,
        message,
      }));
    }
    onShowContentHelp?.();
  }, [statusDescriptor, statusValueLabel, statusValueHelp, onShowContentHelp]);

  const { value, valueValidityDescription, valueValidity } = statusValue ?? {
    value: undefined,
    valueValidityDescription: undefined,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
  };

  useEffect(() => {
    if (value === undefined) {
      return;
    }

    const updateMessage = (message?: string) => {
      if (showContextMessage) {
        dispatch(setContextParameterWarning(message));
      }
      setWarningMessage(message);
    };

    if (!Number.isNaN(value)) {
      const parsedValue = parseFloat(value);
      if (Number.isNaN(parsedValue)) {
        updateMessage(`${t<string>('PARAMETER_VALIDATION_ERROR__INVALID_NUMERIC_FORMAT')}`);
      } else if (valueValidity === DeviceModelStatus.StatusValueValidity.valid) {
        updateMessage(undefined);
      } else {
        updateMessage(`${t<string>('PARAMETER_VALIDATION_ERROR__OUT_OF_RANGE__INTERVALS', {
          INTERVAL: valueValidityDescription,
        })}`);
      }
    }
  }, [value, showContextMessage, valueValidityDescription, valueValidity]);
  return [warningMessage, showContentHelp, hideContentHelp] as const;
};

export default useFloatMessageContextHelp;
