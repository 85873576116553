(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("devicemodel-common", [], factory);
	else if(typeof exports === 'object')
		exports["devicemodel-common"] = factory();
	else
		root["devicemodel-common"] = factory();
})(this, () => {
return 