import { DeviceModelStatus } from '../../devicemodel';
import { createDecorator } from '../../instantiation/instantiation';
import { IService } from '../Service';
import { DeviceResponseValueRef, StatusValueRef } from './DeviceModelCoreService';

export const IDeviceModelStatusProviderService = createDecorator<IDeviceModelStatusProviderService>('DeviceModelStatusProviderService');

// eslint-disable-next-line no-redeclare
export interface IDeviceModelStatusProviderService extends IService {
  /** Write working value to the dataset with calculation */
  WriteValues: (statusValue: StatusValueRef[], actionBlackList: string[]) => Promise<void>;

  /** Write new data to the dataset without unit calculation */
  WriteUploadValues: (statusValues: DeviceResponseValueRef[]) => Promise<void>;

  /** Write device value to the dataset without calculation */
  WriteDeviceValues: (statusValues: DeviceResponseValueRef[]) => Promise<void>;

  /** Return actual values and descriptors */
  GetDeviceModelStatus: () => Promise<DeviceModelStatus>;

  /** Return value after pre-write action applied */
  GetDeviceModelDownloadStatus: () => Promise<DeviceModelStatus>;

  /** Reinitialize device dataset */
  ReinitializeDataset: () => Promise<void>;
}
