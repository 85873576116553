import { CustomObjectValue } from '../../devicemodel';
import { createDecorator } from '../../instantiation/instantiation';
import { DeviceModelUpdateEventData } from '../DeviceModelServer';
import { IService } from '../Service';
import { StatusValueRef } from './DeviceModelCoreService';
import { DeviceModelMethodProgressEvent } from './Notifications';
import { ConnectionNotification } from './Notifications/ConnectionNotification';
import { DeviceModelMethodNotification } from './Notifications/MethodNotification';

export const IDeviceModelEventProvider = createDecorator<IDeviceModelEventProvider>('DeviceModelEventProvider');

export type DeviceModelNotification = DeviceModelMethodNotification
 | ConnectionNotification
 | DeviceModelMethodProgressEvent;

// eslint-disable-next-line no-redeclare
export interface IDeviceModelEventProvider extends IService {
  ServiceName: () => string;

  emitWriteValuesEvent: (inputValues: StatusValueRef[], actionBlackList: string[]) => Promise<void>;

  emitDataChangeEvent: (inputValues: DeviceModelUpdateEventData) => Promise<void>;

  emitNotificationEvent: (notification: DeviceModelNotification) => Promise<void>;

  emitCustomObjectEvent: (object: CustomObjectValue) => Promise<void>;
}
