/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import { DeviceInstanceData } from '../services/DeviceModel/DeviceInstanceManager';
import { WebDeviceEventBase } from './types';

export const WEBDEVICE__CREATE_DEVICE__EVENT = 'WEBDEVICE__CREATE_DEVICE__EVENT';
export const WEBDEVICE__DELETE_DEVICE__EVENT = 'WEBDEVICE__DELETE_DEVICE__EVENT';
export const WEBDEVICE__SELECT_DEVICE__EVENT = 'WEBDEVICE__SELECT_DEVICE__EVENT';

export interface WebDeviceCreateDeviceEvent extends WebDeviceEventBase {
  kind: typeof WEBDEVICE__CREATE_DEVICE__EVENT;
  instance: DeviceInstanceData;
}

export interface WebDeviceDeleteDeviceEvent extends WebDeviceEventBase {
  kind: typeof WEBDEVICE__DELETE_DEVICE__EVENT;
}

export interface WebDeviceSelectDeviceEvent {
  kind: typeof WEBDEVICE__SELECT_DEVICE__EVENT;
  requestId: string;
  deviceInstanceId?: string;
}
