/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import { CustomObjectValue } from '../deviceModelCustomObject';
import { StatusDescriptorHandling, StatusDescriptorValueTypeEnumeratorItem } from '../deviceModelStatusDescriptor';
import { StatusDescriptorValueTypeRecordMembers } from '../typeinfo/record';

export enum ModificatorActionType {
  Value = 'value',
  Description = 'description',
  Usage = 'usage',
  UsageVisibility = 'usageVisibility',
  Unit = 'unit',
  Enumerator = 'enumerator',
  Range = 'range',
  RangeRegionMin = 'rangeRegionMin',
  RangeRegionMax = 'rangeRegionMax',
  RecordMembers = 'recordMembers',
  Precision = 'precision',
  CustomObject = 'customObject'
}

export interface ModificatorRangeValue {
  minValue?: number;
  maxValue?: number;
}

export interface ModificatorUnit {
  scale: number;
  offset: number;
}

export interface ModificatorUnitTable {
  [index: number]: ModificatorUnit;
}

export interface ModifyValueAction {
  type: typeof ModificatorActionType.Value,
  value: any;
  originMethod: string,
}

export interface ModifyDescriptionAction {
  type: typeof ModificatorActionType.Description,
  label: string;
  description: string;
  help: string
}

export interface ModifyUsageAction {
  type: typeof ModificatorActionType.Usage,
  validity: boolean;
  visibility: boolean;
  handling: StatusDescriptorHandling
}

export interface ModifyUsageVisibilityAction {
  type: typeof ModificatorActionType.UsageVisibility,
  visibility: boolean;
}

export interface ModifyRangeRegionMinAction {
  type: typeof ModificatorActionType.RangeRegionMin,
  index: number;
  value: number;
}

export interface ModifyRangeRegionMaxAction {
  type: typeof ModificatorActionType.RangeRegionMax,
  index: number;
  value: number;
}

export interface ModifyUnitAction {
  type: typeof ModificatorActionType.Unit,
  unit: string;
  unitid?: number;
}

export interface ModifyEnumeratorAction {
  type: typeof ModificatorActionType.Enumerator,
  items: StatusDescriptorValueTypeEnumeratorItem[]
}

export interface ModifyRangeAction {
  type: typeof ModificatorActionType.Range,
  index: number;
  value: number;
}

export interface ModifyRecordMembersAction {
  type: typeof ModificatorActionType.RecordMembers,
  members: StatusDescriptorValueTypeRecordMembers;
}

export interface ModifyPrecisionAction {
  type: typeof ModificatorActionType.Precision,
  value: number;
}

export interface ModifyCustomObjectValueAction {
  type: typeof ModificatorActionType.CustomObject,
  object: CustomObjectValue;
}

export type ModificatorProviderActions = ModifyValueAction
  | ModifyDescriptionAction
  | ModifyUsageAction
  | ModifyUsageVisibilityAction
  | ModifyRangeRegionMinAction
  | ModifyRangeRegionMaxAction
  | ModifyUnitAction
  | ModifyEnumeratorAction
  | ModifyRangeAction
  | ModifyRecordMembersAction
  | ModifyPrecisionAction
  | ModifyCustomObjectValueAction;
