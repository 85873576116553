/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 *************************************************************************** */
import { MethodIdent } from '../../../devicemodel';

export const METHOD_EVENT = 'method.state';

export enum MethodStepExecutionState {
  init = 0,
  running = 1,
  done = 2,
  failed = 3
}

export interface MethodExecutionStep {
  title: string;
  message?: string;
  state: MethodStepExecutionState;
}

export interface DeviceModelMethodNotification {
  type: typeof METHOD_EVENT;
  event: string;
  methodIdent: MethodIdent;
  deviceInstanceId: string;
  steps: MethodExecutionStep[];
}

// eslint-disable-next-line max-len
export const createMethodNotification = (deviceInstanceId: string, methodIdent: MethodIdent, steps: MethodExecutionStep[])
: DeviceModelMethodNotification => ({
  type: METHOD_EVENT,
  event: METHOD_EVENT,
  methodIdent,
  deviceInstanceId,
  steps,
});
