/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import { IdentRef } from '../services/common';
import { StatusType } from './deviceModelCommons';

export enum CustomPropertyType {
    Number = 0,
    String = 1,
}

export interface CustomPropertyNumberType {
    type: typeof CustomPropertyType.Number;
    value: number;
}

export interface CustomPropertyStringType {
    type: typeof CustomPropertyType.String;
    value: string;
}

export type CustomPropertyParameterType = CustomPropertyNumberType | CustomPropertyStringType;

export interface CustomPropertyDescriptor {
  type: typeof StatusType.PropertyDescriptor;
  identRef: IdentRef;
  value?: CustomPropertyParameterType;
}
