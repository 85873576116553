/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import { IdentRef } from '../../services/common';

// eslint-disable-next-line import/prefer-default-export
export enum MethodType {
    METHOD_UPLOAD = 'METHOD_UPLOAD',
    METHOD_DOWNLOAD = 'METHOD_DOWNLOAD',
    METHOD_DYNAMICREAD = 'METHOD_DYNAMICREAD',
    METHOD_CUSTOM = 'METHOD_CUSTOM',
}

export interface MethodBaseType {
    transferBlockRef: string[];
    manualTransferVariable?: IdentRef[];
}

export const METHOD_DYNAMICREAD__IDENTREF = 'readDynamicData';
export const METHOD_UPLOAD__IDENTREF = 'uploadDataFromDevice';
export const METHOD_DOWNLOAD__IDENTREF = 'downloadDataToDevice';
