/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
import { MethodIdent } from '../devicemodel';
import {
  DeviceModelNotification, MethodHeader, MethodRequestPayload, MethodResponsePayload,
} from '../services/DeviceModel';
import { WebDeviceEventBase, WebDeviceRequestBase, WebDeviceResponseBase } from './types';

export const WEBDEVICE__EXECUTE_METHOD__REQUEST = 'WEBDEVICE__EXECUTE_METHOD__REQUEST';
export const WEBDEVICE__EXECUTE_METHOD__RESPONSE = 'WEBDEVICE__EXECUTE_METHOD__RESPONSE';

export const WEBDEVICE__NOTIFICATION_EVENT = 'WEBDEVICE__NOTIFICATION_EVENT';

export interface WebDeviceNotificationEvent extends WebDeviceEventBase {
    kind: typeof WEBDEVICE__NOTIFICATION_EVENT;
    notification: DeviceModelNotification;
}

export interface WebDeviceExecuteMethodRequest extends WebDeviceRequestBase {
    kind: typeof WEBDEVICE__EXECUTE_METHOD__REQUEST;
    methodIdent: MethodIdent;
    actionId?: string;
    request: MethodRequestPayload;
    header: MethodHeader;
}

export interface WebDeviceExecuteMethodResponse extends WebDeviceResponseBase {
    kind: typeof WEBDEVICE__EXECUTE_METHOD__RESPONSE;
    methodIdent: MethodIdent;
    actionId?: string;
    response: MethodResponsePayload;
    header: MethodHeader;
}
