/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

/* eslint-disable max-len */
import React from 'react';
import { v4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setContextParameterHelp } from '../../../store/contexthelp';
import './CheckboxEditorControl.css';

export interface CheckboxEditorControlProps {
    label: string;
    value: boolean;
    help: string;
    id: string;
    readonly: boolean;
    showLabel?:boolean;
    // eslint-disable-next-line no-unused-vars
    onChange: (v: boolean) => void;
    onShowContentHelp?: () => void;
    onHideContentHelp?: () => void;
    edited?: boolean;
    customClassName?: string;
}

const CheckboxEditorControl: React.FC<CheckboxEditorControlProps> = (props: CheckboxEditorControlProps): React.ReactElement => {
  const {
    label, value, id, readonly, help, showLabel,
    onChange, onShowContentHelp, onHideContentHelp,
    edited, customClassName,
  } = props;
  const channelId = `${id}-${v4()}`;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const labelWidth = showLabel ? 100 : 0;
  const classNameEditedText = (edited ?? false) ? 'checkbox-control__container--bkc' : '';
  const controlLabel = label === undefined ? '' : t(label);
  const labelControl = showLabel ? <>{controlLabel}</> : <></>;

  return (
    <div className="checkbox-control">
      <div className={`checkbox-control__container ${classNameEditedText} ${customClassName ?? 'col-6'}`}>
        <input
          className="checkbox-control__container__input "
          id={channelId}
          disabled={readonly}
          type="checkbox"
          checked={value}
          onChange={() => onChange(!value)}
          onFocus={() => {
            dispatch(setContextParameterHelp({
              title: controlLabel,
              text: t(help),
            }));
            onShowContentHelp?.();
          }}
          onBlur={() => {
            dispatch(setContextParameterHelp({
              title: '',
              text: '',
            }));
            onHideContentHelp?.();
          }}
          data-testid="checkbox-editor-control-input"
        />
        <label htmlFor={channelId} style={{ width: `${labelWidth}%` }}>
          {labelControl}
        </label>
      </div>
    </div>
  );
};

export default CheckboxEditorControl;
