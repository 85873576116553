/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useEffect } from 'react';
import { DeviceInformation } from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { RoutePaths } from '../../../RoutePaths';
import DeviceCardList from '../../../components/DeviceCardList/DeviceCardList';
import { useTypedSelector, instantiateDevice } from '../../../store';
import useShowDriverMessage from '../hooks/useShowDriverMessage';
import StartupViewDriverHint from '../installDriver/DriverHint/DriverHint';
import InstallDriverButton from '../installDriver/InstallDriverButton/InstallDriverButton';
import discoveryListSelector from '../selectors/discoveryListSelector';
import { loadDeviceSimulationConfig } from '../../../store/deviceSimulation/actions';
import './StartupViewDeviceList.scss';

const StartupViewDeviceList: React.FC = () : React.ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deviceList = useTypedSelector((state) => discoveryListSelector(state.discoveryService, state.deviceSimulation));
  const noDeviceDetected = useShowDriverMessage(deviceList);

  useEffect(() => {
    // load simulation config file
    dispatch(loadDeviceSimulationConfig());
  }, []);

  const className = `startup-view-content startup-view-content--${noDeviceDetected ? 'no-device' : 'device'} startup-view__content`;

  const driverHint = noDeviceDetected
    ? (
      <>
        <div className="startup-view-content__driver_message__text">
          <StartupViewDriverHint />
        </div>
        <div className="startup-view-content__driver_message__button startup-view__driver_message__button">
          <div className="startup-view-content__driver_message__button__button">
            <InstallDriverButton />
          </div>
        </div>
      </>
    )
    : undefined;

  return (
    <div className={className}>
      <div className="startup-view-content__device-list">
        <DeviceCardList
          deviceList={deviceList}
          onRowClick={(device: DeviceInformation) => {
            if (device.catalog.deviceDriverId) {
              dispatch(instantiateDevice({ deviceInfo: device, wizardMode: false }));
              navigate(RoutePaths.DeviceViewNavDefault);
            }
          }}
        />
      </div>
      <div className="startup-view-content__driver_message">
        {driverHint}
      </div>
    </div>
  );
};

export default StartupViewDeviceList;
