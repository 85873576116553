/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
/* eslint-disable max-len */
import { DeviceModelStatus } from '../devicemodel';
import { DeviceI18nDictionary, DeviceResponseValueRef, StatusValueRef } from '../services/DeviceModel';
import { WebDeviceRequestBase, WebDeviceResponseBase } from './types';

export const WEBDEVICE__LOAD_EXTERNAL_DATASET__REQUEST = 'WEBDEVICE__LOAD_EXTERNAL_DATASET__REQUEST';
export const WEBDEVICE__LOAD_EXTERNAL_DATASET__RESPONSE = 'WEBDEVICE__LOAD_EXTERNAL_DATASET__RESPONSE';

export const WEBDEVICE__WRITE_VARIABLE_VALUES__REQUEST = 'WEBDEVICE__WRITE_VARIABLE_VALUES__REQUEST';
export const WEBDEVICE__WRITE_VARIABLE_VALUES__RESPONSE = 'WEBDEVICE__WRITE_VARIABLE_VALUES__RESPONSE';

export const WEBDEVICE__WRITE_VARIABLE_DEVICE_VALUES__REQUEST = 'WEBDEVICE__WRITE_VARIABLE_DEVICE_VALUES__REQUEST';
export const WEBDEVICE__WRITE_VARIABLE_DEVICE_VALUES__RESPONSE = 'WEBDEVICE__WRITE_VARIABLE_DEVICE_VALUES__RESPONSE';

export const WEBDEVICE__WRITE_UPLOAD_VALUES__REQUEST = 'WEBDEVICE__WRITE_UPLOAD_VALUES__REQUEST';
export const WEBDEVICE__WRITE_UPLOAD_VALUES__RESPONSE = 'WEBDEVICE__WRITE_UPLOAD_VALUES__RESPONSE';

export const WEBDEVICE__RESOURCE__REQUEST = 'WEBDEVICE__RESOURCE__REQUEST';
export const WEBDEVICE__RESOURCE__RESPONSE = 'WEBDEVICE__RESOURCE__RESPONSE';

export const WEBDEVICE__TRANSLATION__REQUEST = 'WEBDEVICE__TRANSLATION__REQUEST';
export const WEBDEVICE__TRANSLATION__RESPONSE = 'WEBDEVICE__TRANSLATION__RESPONSE';

export const WEBDEVICE__REINITIALIZE_DATASET__REQUEST = 'WEBDEVICE__REINITIALIZE_DATASET__REQUEST';
export const WEBDEVICE__REINITIALIZE_DATASET__RESPONSE = 'WEBDEVICE__REINITIALIZE_DATASET__RESPONSE';

export const WEBDEVICE__UPDATE_DATASET__REQUEST = 'WEBDEVICE__UPDATE_DATASET__REQUEST';
export const WEBDEVICE__UPDATE_DATASET__RESPONSE = 'WEBDEVICE__UPDATE_DATASET__RESPONSE';

export const WEBDEVICE__WRITE_DATASET__REQUEST = 'WEBDEVICE__WRITE_DATASET__REQUEST';
export const WEBDEVICE__WRITE_DATASET__RESPONSE = 'WEBDEVICE__WRITE_DATASET__RESPONSE';

export const WEBDEVICE__CREATE_EXTERNAL_DEVICEMODEL_STATUS__REQUEST = 'WEBDEVICE__CREATE_EXTERNAL_DEVICEMODEL_STATUS__REQUEST';
export const WEBDEVICE__CREATE_EXTERNAL_DEVICEMODEL_STATUS__RESPONSE = 'WEBDEVICE__CREATE_EXTERNAL_DEVICEMODEL_STATUS__RESPONSE';

/**
 * loadExternalDatasetValues
 */
export interface LoadExternalDatasetRequest extends WebDeviceRequestBase {
    kind: typeof WEBDEVICE__LOAD_EXTERNAL_DATASET__REQUEST;
    values: DeviceResponseValueRef[];
}

export interface LoadExternalDatasetResponse extends WebDeviceResponseBase {
    kind: typeof WEBDEVICE__LOAD_EXTERNAL_DATASET__RESPONSE;
    result: boolean;
}

/** Write working value to the dataset */
// writeValuesEvent: (statusValue: Services.DeviceModel.StatusValueRef[])
// => datasetService.WriteValues(statusValue),
export interface WriteVariableValuesRequest extends WebDeviceRequestBase {
    kind: typeof WEBDEVICE__WRITE_VARIABLE_VALUES__REQUEST;
    statusValue: StatusValueRef[];
}

export interface WriteVariableValuesResponse extends WebDeviceResponseBase {
    kind: typeof WEBDEVICE__WRITE_VARIABLE_VALUES__RESPONSE;
}

// /** Write device value to the dataset with executing post-read actions */
// writeDeviceValuesEvent: (values: Services.DeviceModel.DeviceResponseValueRef[])
// => datasetService.WriteDeviceValues(values),
export interface WriteVariableDeviceValuesRequest extends WebDeviceRequestBase {
    kind: typeof WEBDEVICE__WRITE_VARIABLE_DEVICE_VALUES__REQUEST;
    values: DeviceResponseValueRef[];
}

export interface WriteVariableDeviceValuesResponse extends WebDeviceResponseBase {
    kind: typeof WEBDEVICE__WRITE_VARIABLE_DEVICE_VALUES__RESPONSE;
}

// /** Write device value to the dataset with executing post-read actions */
// writeUploadValuesEvent: async (values: Services.DeviceModel.DeviceResponseValueRef[])
// : Promise<void> => {
export interface WriteUploadValuesRequest extends WebDeviceRequestBase {
    kind: typeof WEBDEVICE__WRITE_UPLOAD_VALUES__REQUEST;
    values: DeviceResponseValueRef[];
}

export interface WriteUploadValuesResponse extends WebDeviceResponseBase {
    kind: typeof WEBDEVICE__WRITE_UPLOAD_VALUES__RESPONSE;
}

export interface DeviceModelTranslationRequest extends WebDeviceRequestBase {
    kind: typeof WEBDEVICE__TRANSLATION__REQUEST;
    language: string;
}

export interface DeviceModelTranslationResponse extends WebDeviceResponseBase {
    kind: typeof WEBDEVICE__TRANSLATION__RESPONSE;
    dictionary: DeviceI18nDictionary;
}

export interface DeviceModelResourceRequest extends WebDeviceRequestBase {
    kind: typeof WEBDEVICE__RESOURCE__REQUEST;
    resourceId: string;
}

export interface DeviceModelResourceResponse extends WebDeviceResponseBase {
    kind: typeof WEBDEVICE__RESOURCE__RESPONSE;
    resource: string;
}

export interface DeviceModelReinitDatasetRequest extends WebDeviceRequestBase {
    kind: typeof WEBDEVICE__REINITIALIZE_DATASET__REQUEST;
}

export interface DeviceModelReinitDatasetResponse extends WebDeviceResponseBase {
    kind: typeof WEBDEVICE__REINITIALIZE_DATASET__RESPONSE;
}

export interface UpdateDatasetRequest extends WebDeviceRequestBase {
    kind: typeof WEBDEVICE__UPDATE_DATASET__REQUEST;
    values: DeviceResponseValueRef[];
}

export interface UpdateDatasetResponse extends WebDeviceResponseBase {
    kind: typeof WEBDEVICE__UPDATE_DATASET__RESPONSE;
}

export interface WriteDatasetRequest extends WebDeviceRequestBase {
    kind: typeof WEBDEVICE__WRITE_DATASET__REQUEST;
    values: DeviceResponseValueRef[];
}

export interface WriteDatasetResponse extends WebDeviceResponseBase {
    kind: typeof WEBDEVICE__WRITE_DATASET__RESPONSE;
}

export interface CreateExternalDeviceModelStatusRequest extends WebDeviceRequestBase {
    kind: typeof WEBDEVICE__CREATE_EXTERNAL_DEVICEMODEL_STATUS__REQUEST;
    version: number;
}

export interface CreateExternalDeviceModelStatusResponse extends WebDeviceResponseBase {
    kind: typeof WEBDEVICE__CREATE_EXTERNAL_DEVICEMODEL_STATUS__RESPONSE;
    version: number;
    status: DeviceModelStatus;
}
