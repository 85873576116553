/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: Interfaces used by Electron and React Application to show
 * the list of detected devices
 *
 **************************************************************************** */

import { createDecorator } from '../instantiation/instantiation';
import { DeviceScoutServiceUpdate } from '../services/DeviceModelServer/DeviceScoutService';
import { IService } from '../services/Service';

// eslint-disable-next-line no-redeclare
export const IUIDeviceScoutService = createDecorator<IUIDeviceScoutService>('IUIDeviceScoutService');

export type UpdateDeviceListCallback =
    (events:any, requestId: string, deviceScoutServiceUpdate: DeviceScoutServiceUpdate[]) => void;

// eslint-disable-next-line no-redeclare
export interface IUIDeviceScoutService extends IService {
    Request: (requestId: string) => Promise<void>;
    Subscribe:
        (listener: UpdateDeviceListCallback) => void;
    Unsubscribe:
        (listener: UpdateDeviceListCallback) => void;
}
