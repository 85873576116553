/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */

import { IdentRef } from '../services/common';
import { StatusType } from './deviceModelCommons';

export enum ModificatorParamType {
    CParam = 0,
    VParam = 1,
}

export interface ModificatorCParam {
    type: typeof ModificatorParamType.CParam;
    value: any;
    name: string;
}

export interface ModificatorVParam {
    type: typeof ModificatorParamType.VParam;
    variable: IdentRef;
}

export type ModificatorParam = ModificatorCParam | ModificatorVParam;

// Description of modificator with addition parameters
export interface ModificatorDescriptor {
    type: typeof StatusType.ModificatorDescriptor;
    identRef: IdentRef;
    args: ModificatorParam[];
}
