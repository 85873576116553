import { DatasetIdent } from './common';
import { DatasetFilter } from './DatasetProviderService';
import {
  DatasetProviderRequestType,
  DPS_GET_DATASETLIST_REQUEST,
  DPS_GET_DATASET_REQUEST,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const CreateGetDatasetListRequest = (
  deviceInstanceId: string,
  requestId: string,
  filter: DatasetFilter,
): DatasetProviderRequestType => ({
  type: DPS_GET_DATASETLIST_REQUEST,
  deviceInstanceId,
  requestId,
  filter,
});

export const CreateGetDatasetRequest = (
  deviceInstanceId: string,
  requestId: string,
  datasetId: DatasetIdent,
): DatasetProviderRequestType => ({
  type: DPS_GET_DATASET_REQUEST,
  deviceInstanceId,
  requestId,
  datasetId,
});
