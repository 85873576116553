/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: Grafisches Parametriertool
 * Component: common type definitions
 *
 **************************************************************************** */
// eslint-disable-next-line import/prefer-default-export
export enum StatusDescriptorValueType {
    INTEGER = 0,
    UNSIGNED_INTEGER = 1,
    FLOAT = 2,
    // DOUBLE = 3,
    STRING = 4,
    ENUMERATOR = 5,
    // BITENUMERATOR = 6,
    DATETIME = 7,
    DATE = 8,
    TABLE = 9,
    TABLERECORD = 10,
    BLOB = 11,
    IPV4_ADDRESS = 12,
    MAC_ADDRESS = 13,
    RECORD = 14,
    JSONVALUE = 15,
}
