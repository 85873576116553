/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import { DatasetState, DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { DeviceInstancesState } from '../../../store/deviceInstances/types';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';

export const getGaugeControlType = (identRef: string, state?: DatasetState): DeviceModelStatus.UI.DynVariableControlType | undefined => {
  const desc = state?.descriptors[identRef];
  if (desc?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  return desc.controlType.type === DeviceModelStatus.UI.ControlType.CTLDYNVARIABLE ? desc.controlType : undefined;
};

export const getGaugeVarDescriptor = (deviceInstances: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: string): DeviceModelStatus.StatusDescriptor | undefined => {
  const state = deviceTargetDatasetSelector(deviceInstances, deviceInstanceId, targetDataset);
  const controlType = getGaugeControlType(identRef, state);
  if (controlType === undefined) {
    return undefined;
  }

  const descriptor = state?.descriptors[controlType.variable];
  return descriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor ? descriptor : undefined;
};

export const getGaugeDeviceValue = (deviceInstances: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: string): number => {
  const state = deviceTargetDatasetSelector(deviceInstances, deviceInstanceId, targetDataset);
  const controlType = getGaugeControlType(identRef, state);
  if (controlType === undefined) {
    return 0;
  }
  const value = state?.values[controlType.variable];
  if (value?.value !== undefined) {
    return value.value as number;
  }

  const descriptor = state?.descriptors[controlType.variable];
  if (descriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor) {
    if (descriptor.valueType.type === DeviceModelStatus.StatusDescriptorValueType.FLOAT) {
      return descriptor.valueType.initialValue as number;
    }
  }
  return 0;
};

export const getGaugeSubtitle = (deviceInstances: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: string): string => {
  const state = deviceTargetDatasetSelector(deviceInstances, deviceInstanceId, targetDataset);
  const controlType = getGaugeControlType(identRef, state);
  if (controlType === undefined) {
    return '';
  }
  if (controlType.VSUBTITLE !== undefined) {
    const varIdentRef = controlType.VSUBTITLE as string;
    const value = state?.values[varIdentRef]?.value;
    return value ? value as string : '';
  }
  return controlType.CSUBTITLE ?? '';
};

export const getGaugeTitle = (state: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: string): string => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  const controlType = getGaugeControlType(identRef, dataset);
  if (controlType === undefined) {
    return '';
  }
  if (controlType.CTITLE !== undefined) {
    return controlType.CTITLE;
  }
  if (controlType.VTITLE !== undefined) {
    const varIdentRef = controlType.VTITLE as string;
    const value = dataset?.values[varIdentRef]?.value;
    return value ? value as string : '';
  }

  const descriptor = dataset?.descriptors[controlType.variable];
  return descriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor ? descriptor.label : '';
};

export const getGaugeSegmentStops = (state: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: string): number[] => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  const controlType = getGaugeControlType(identRef, dataset);
  if (controlType === undefined) {
    return [];
  }

  const configurationValueSelector = (attribute: string): number | undefined => {
    const cAttrName = `C${attribute}`;
    const vAttrName = `V${attribute}`;

    if (controlType[cAttrName] === undefined) {
      const varIdentRef = controlType[vAttrName] !== undefined ? controlType[vAttrName] as string : undefined;
      return varIdentRef === undefined ? undefined : dataset?.values[varIdentRef].value as number;
    }
    return controlType[cAttrName] as number;
  };

  // Get VMIN / CMIN from the configuration
  const segmentName = ['MIN', 'LOLO', 'LO', 'HI', 'HIHI', 'MAX'];
  const segments = segmentName.reduce((acc, name) => {
    const itemValue = configurationValueSelector(name);
    return itemValue !== undefined
      ? [...acc, itemValue]
      : acc;
  }, [] as number[]);
  return segments;
};
