import { DeviceDriverId } from '../../common';
import { ExternalDataset } from './externalDataset';

/**
 * Type containing information about a connected device,
 * as detected by scanning communication interface topology
 * and optionally extended with information of device driver
 * (when matching is available, i.e. product URL and image).
 */
export interface DatasetFilter {
  datasetId?: string;
  /**
   * Unique device driver identifier, derived from device type identification information.
   * {vendorID:X2}-{groupID:X2}-{familyID:X2}-{deviceID:X2}-{cfgVersion:X4}
   */
  deviceDriverId?: DeviceDriverId;

  profileName?: string;
}

export interface DatasetProviderConfiguration {
  datasetList: ExternalDataset[];
}

export const ServiceName = 'DatasetProvider';

export const ServiceVersion = 1;

export const ServiceFullname = `v${ServiceVersion}.${ServiceName}`;
