/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */
import React from 'react';
import { toFixedValue } from '@gpt/commons';
import { ChartSegment, ChartScaleMark } from '../../Elements/types';
import { HBarElement } from '../../Elements/HBarElement/HBarElement';
import './PowerBarChart.scss';

export interface PowerBarChartProps {
  value: number;
  dragMinValue: number;
  dragMaxValue: number;
  showDragValues: boolean;
  unit: string;
  chartSegments: ChartSegment[];
  chartMarks: ChartScaleMark[];
  displayFormat: string;
}

const calculateMinValue = (segments: ChartSegment[])
  : number => (segments.length === 0 ? 0 : segments[0].minValue);

const calculateMaxValue = (segments: ChartSegment[])
  : number => (segments.length === 0 ? 10 : segments[segments.length - 1].maxValue);

export const PowerBarChart: React.FC<PowerBarChartProps> = (props: PowerBarChartProps)
: React.ReactElement => {
  const {
    unit,
    value,
    dragMaxValue,
    dragMinValue,
    chartSegments,
    chartMarks,
    showDragValues,
  } = props;

  const minValue = calculateMinValue(chartSegments);
  const maxValue = calculateMaxValue(chartSegments);

  return (
    <div className="powerbar-chart">
      <div className="powerbar-chart__value">
        {`${toFixedValue(value, 2)} ${unit}`}
      </div>
      <div className="powerbar-chart__chart">
        <HBarElement
          currentValue={value}
          dragMinValue={showDragValues ? dragMinValue : undefined}
          dragMaxValue={showDragValues ? dragMaxValue : undefined}
          minValue={minValue}
          maxValue={maxValue}
          segments={chartSegments}
          marks={chartMarks}
        />
      </div>
    </div>
  );
};
